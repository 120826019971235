import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

let productionApi = {
    addBundleToCarousel: async (carouselID, sellableBundleID) => {
        let url = `api/marketplace/v1/carousels/${carouselID}/bundles/${sellableBundleID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    addItemToCarousel: async (carouselID, sellableItemID) => {
        let url = `api/marketplace/v1/carousels/${carouselID}/items/${sellableItemID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    removeBundleFromCarousel: async (carouselID, sellableBundleID) => {
        let url = `api/marketplace/v1/carousels/${carouselID}/bundles/${sellableBundleID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    removeItemFromCarousel: async (carouselID, sellableItemID) => {
        let url = `api/marketplace/v1/carousels/${carouselID}/items/${sellableItemID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    updateSellableItemCarousel: async (sendParams) => {
        let url = `api/marketplace/v1/carousels/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    deleteCarousel: async (carouselID) => {
        let url = `api/marketplace/v1/carousels/${carouselID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    updateCarousel: async (carouselID, sendParams) => {
        let formData = new FormData();

        Object.keys(sendParams).forEach((key) => {
            formData.append(key, sendParams[key]);
        });

        let url = `api/marketplace/v1/carousels/${carouselID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    getCarousels: async (packGroupID) => {
        let url = `api/marketplace/v1/carousels?packGroupID=${packGroupID}`;
        let res = await mmFetchWithResponse(url);
        return res;
    },
    createCarousel: async (data) => {
        let formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        let url = `api/marketplace/v1/carousels`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    getPricing: async () => {
        let url = `api/customize/v1/pricing`;
        let res = await mmFetchWithResponse(url);
        return res;
    },
    downloadLineSlotMapCSV: async (packGroupID, dummyData = false) => {
        let filename = `packGroup-${packGroupID}-sellableItems.csv`;
        let url = `api/warehouse/v1/sellableItems/lsm/csv?packGroupID=${packGroupID}`;
        if (dummyData) {
            url += `&dummySlotted=true`;
        }
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadSellableItemCSV] error', e.toString());
        }
    },
    downloadSellableItemTotalInventoryCSV: async (packGroupID) => {
        let filename = `packGroup-${packGroupID}-sellableItems.csv`;
        let url = `api/warehouse/v1/sellableItems/inventory/csv?packGroupID=${packGroupID}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadSellableItemCSV] error', e.toString());
        }
    },
    getActiveLots: async () => {
        let url = `api/warehouse/v1/activeLots`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getAutoPricingStatus: async (packGroupID) => {
        let url = `api/customize/v1/autoPrice/${packGroupID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackGroups: async (queryParams) => {
        // {facilityID, week, year}
        let url = `api/warehouse/v1/packGroups`;

        if (queryParams && Object.keys(queryParams).length) {
            let queryString = Object.keys(queryParams)
                .filter((key) => !!queryParams[key])
                .map((key) => `${key}=${queryParams[key]}`)
                .join('&');
            url += `?${queryString}`;
        }

        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackGroupsRecent: async (facilityID, excludeFbm = 'false') => {
        let url = `api/warehouse/v1/packGroups/recent`;
        if (facilityID) {
            url += `?facilityID=${facilityID}&excludeFbm=${excludeFbm}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackGroupsCurrentNext: async () => {
        let url = 'api/warehouse/v1/packGroups/currentNext';
        return await mmFetchWithResponse(url);
    },
    getActivePackGroups: async (facilityID) => {
        let url = `api/warehouse/v1/packGroups?active=true`;
        if (facilityID) {
            url += `&facility=${facilityID}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackGroupAssignments: async (week, year) => {
        let url = `api/warehouse/v1/packGroups/assignments`;
        if (week && year) {
            url += `?week=${week}&year=${year}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPlacedPOs: async () => {
        let url = `api/procurement/v1/purchaseOrder/items`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getSellableBundles: async (packGroupID) => {
        let url = `api/inventory/v1/sellableBundles?packGroupID=${packGroupID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getSellableItems: async (packGroupID) => {
        let url = `api/warehouse/v1/sellableItems?packGroupID=${packGroupID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getSellableItemsTotalInventory: async (packGroupID) => {
        let url = `api/warehouse/v1/sellableItemInventory?packGroupID=${packGroupID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getSellableItemMisfitsPlusDiscounts: async ({ week = '', year = '' }) => {
        let url = `api/membership/v1/item-discounts`;
        if (!!week && !!year) {
            url += `?week=${week}&year=${year}`;
        }
        return mmFetchWithResponse(url);
    },
    getSellableItemDiscountCSV: async ({ week, year, packGroupID }) => {
        let url = `api/membership/v1/item-discounts?week=${week}&year=${year}&packGroupID=${packGroupID}&csv=true`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = `item_discounts_week_${week}_year_${year}_packGroup_${packGroupID}.csv`;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadSellableItemCSV] error', e.toString());
        }
    },
    updateMisfitsPlusDiscount: async (sellableItemID, plusMemberDiscount) => {
        let url = `api/membership/v1/item-discounts/${sellableItemID}`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ plusMemberDiscount }),
        });
    },
    uploadSellableItemMisfitsPlusDiscounts: async (file, packGroupID) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('packGroupID', packGroupID);

        let url = `api/membership/v1/item-discounts/upload`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
    },
    confirmSellableItemMisfitsPlusDiscounts: async (key) => {
        const url = 'api/membership/v1/item-discounts/confirm';
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
    },
    markPackGroupReadyForPricing: async (packGroupID) => {
        let url = 'api/customize/v1/autoPrice';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ packGroupID: packGroupID }),
        });
        return response;
    },
    makePackGroup: async (item) => {
        let url = `api/warehouse/v1/packGroups`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    removeLotFromSellableItem: async (sellableItemID, lotID) => {
        let url = `api/warehouse/v1/sellableItems/${sellableItemID}/lots/${lotID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    removePOFromSellableItem: async (sellableItemID, poMapID) => {
        let url = `api/warehouse/v1/sellableItems/${sellableItemID}/poMap/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    removeSellableItems: async (ids) => {
        let url = `api/warehouse/v1/sellableItems`;
        try {
            let response = await mmFetchWithResponse(url, {
                method: 'DELETE',
                body: JSON.stringify({ sellableItems: ids }),
            });
            return response;
        } catch (e) {
            console.log('[makeSellableItem] error', e.toString());
            return { status: false, data: null };
        }
    },
    setPackGroupInventory: async (packGroupID, inventory) => {
        let url = `api/warehouse/v1/packGroups/${packGroupID}/inventory`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ inventory }),
        });
        return response;
    },
    setLineSlotMap: async (key) => {
        let url = 'api/warehouse/v1/sellableItems/lsm/csv';
        let response = await mmFetch(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    setSellableItems: async (key) => {
        let url = 'api/warehouse/v1/sellableItems/bulk/csv';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    updatePackGroupAssignment: async ({ arrivalDay, facilityID, packGroupID }) => {
        console.log(arrivalDay, facilityID, packGroupID);
        let url = `api/warehouse/v1/packGroups/assignment`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ arrivalDay, facilityID, packGroupID }),
        });
        return response;
    },
    updatePricing: async (id, field, value) => {
        let url = 'api/customize/v1/pricing/' + id;
        let sendParams = { [field]: value };
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    markItemSoldOut: async (id, reason, newValue) => {
        let url = 'api/warehouse/v1/sellableItems/soldOut';
        let sendParams = {
            sellableItemID: id,
            soldOut: newValue,
            reason: reason,
        };
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updateSellableItem: async (id, field, newValue) => {
        let url = 'api/warehouse/v1/sellableItems/' + id;
        let sendParams = { [field]: newValue };
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    uploadLineSlotMap: async (packGroupID, data) => {
        let formData = new FormData();
        formData.append('file', data.file);
        formData.append('packGroupID', packGroupID);

        let url = 'api/warehouse/v1/sellableItems/lsm/csv';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadSellableItems: async (packGroupID, data) => {
        let formData = new FormData();
        formData.append('file', data.file);
        formData.append('packGroupID', packGroupID);

        let url = 'api/warehouse/v1/sellableItems/bulk/csv';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadSellableItemTotalInventoryCSV: async ({ file, packGroupID }) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('packGroupID', packGroupID);

        let url = 'api/warehouse/v1/sellableItems/inventory/csv';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    setSellableItemInventoryAmounts: async (key) => {
        let url = 'api/warehouse/v1/sellableItems/inventory/csv';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    confirmCarouselUpload: async (key) => {
        let url = 'api/production/v2/carousel/bulk';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    uploadCarousels: async (data) => {
        let formData = new FormData();
        formData.append('itemFile', data.file);

        let url = 'api/production/v2/carousel/bulk';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    getAisleBannerByTaxonomyID: async (taxonomyID) => {
        let url = `api/marketplace/v1/aisles/banner/${taxonomyID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getAisleBanners: async () => {
        let url = `api/marketplace/v1/aisles/banners`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getAisles: async () => {
        let url = `api/marketplace/v1/aisles`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    createAisleBanner: async (sendParams) => {
        let formData = new FormData();
        Object.keys(sendParams).forEach((key) => {
            formData.append(key, sendParams[key]);
        });

        return mmFetchWithResponse(`api/marketplace/v1/aisles/banner`, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
    },
    updateAisleBanner: async (sendParams) => {
        let id = sendParams.id;
        let formData = new FormData();
        Object.keys(sendParams).forEach((key) => {
            formData.append(key, sendParams[key]);
        });
        let url = `api/marketplace/v1/aisles/banner/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    deleteAisleBanner: async (id) => {
        let url = `api/marketplace/v1/aisles/banner/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    getCountriesOfOriginForSellableItems: async () => {
        let url = `api/warehouse/v1/sellableItems/countryOfOriginMap`;
        let res = await mmFetchWithResponse(url);
        return res;
    },
    createSellableItemCountryOfOriginMap: async (sellableItemID, field, values) => {
        let url = `api/warehouse/v1/sellableItems/${sellableItemID}/countryOfOriginMap`;
        let sendParams = { [field]: values };
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    removeCountryOfOriginFromSellableItem: async (sellableItemID, countryID) => {
        let url = `api/warehouse/v1/sellableItems/${sellableItemID}/countryOfOriginMap/${countryID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    downloadPrePopulatedCsv: async (sellableItemIds) => {
        let url = 'api/warehouse/v1/sellableItems/export/downloadPrePopulatedCsv';

        try {
            let res = await mmFetch(url, {
                method: 'POST',
                body: JSON.stringify(sellableItemIds),
            });
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'sellable-item-upload-template.csv';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(d);
                }, 5000);

                document.body.removeChild(link);
            }
            return res;
        } catch (e) {
            console.log('[downloadSellableItemCSV] error', e.toString());
        }
    },
};

export { productionApi };
