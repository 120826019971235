import React, { useState, useEffect, useContext } from 'react';
import '../createList.css';
import { FAMAlert } from '../../../shared';
import { Dialog, DialogContent } from '@material-ui/core';
import { partnershipsApi } from '../../../../api/partnerships';
import { DataTable } from '../../../../shared';
import { UserContext } from '../../../../contexts/UserContext';

const SkusRemovedFromList = ({ listID, skusRemoved = [], listName, onClose }) => {
    const { famAccount } = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [remainingSkusRemoved, setRemainingSkusRemoved] = useState([]);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setRemainingSkusRemoved(skusRemoved);
    }, [skusRemoved]);

    if (!listID) {
        return null;
    }

    const handleAddItemToExclusionList = async (extSku) => {
        setDisabled(true);
        const res = await partnershipsApi.addToSkuExclusionList(listID, extSku, famAccount.partnershipSource);
        if (!res.status) {
            setErrorMessage('Failed to add item to exclusion list');
            setDisabled(false);
            return;
        }

        const countRemovals = res.data.countRemovals;
        let msg = `Item has been added to excluded skus for ${listName}.`;
        if (countRemovals > 0) {
            msg += ` ${countRemovals} additional slots removed from current list`;
        }

        // The sku has been removed, now we want to remove it from the list of items displayed
        const listRes = await partnershipsApi.getSkuExclusionList(listID, famAccount.partnershipSource);
        if (listRes.status) {
            const excluded = listRes?.data?.excludedSkus.map((item) => item.externalSku) || [];
            setRemainingSkusRemoved(skusRemoved.filter((item) => !excluded.includes(item.externalSku)));
        }

        setSuccessMessage(msg);
        setDisabled(false);
    };

    const skuCols = [
        {
            Header: 'Item',
            accessor: 'name',
        },
        {
            Header: 'Remove',
            Cell: ({ row }) => {
                return (
                    <button
                        style={{ margin: '10px' }}
                        className="fam-button fam-button-red grotesk-bold"
                        onClick={() => {
                            handleAddItemToExclusionList(row.original.externalSku);
                        }}
                        disabled={disabled}
                    >
                        Block item from list
                    </button>
                );
            },
        },
    ];

    return (
        <Dialog open={skusRemoved.length > 0} onClose={onClose} maxWidth="lg" fullWidth={true}>
            <DialogContent>
                <FAMAlert severity="success" message={successMessage} />
                <FAMAlert severity="error" message={errorMessage} />

                <h3>The following items were just removed from the slot</h3>
                <h4>
                    Would you like to remove these items from all other slots on this list, and prevent adding to all
                    future versions of {listName}?
                </h4>
                <DataTable
                    data={remainingSkusRemoved.map((item) => {
                        item.id = item.externalSku;
                        return item;
                    })}
                    columns={skuCols}
                />
            </DialogContent>
        </Dialog>
    );
};

export { SkusRemovedFromList };
