import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { inboundTruckApi as api } from '../../../api/inboundTrucks';
import { Button, TextField } from '@material-ui/core';

let CreateInboundCarrier = () => {
    let [notification, setNotification] = useState({ text: null, level: null });

    let formFields = [
        { name: 'name', inputElement: 'textField', label: 'Name', gridValue: 4, inputProps: { required: true } },
        { name: 'email', inputElement: 'textField', label: 'Email', gridValue: 4 },
        { name: 'cell', inputElement: 'textField', label: 'Cell', gridValue: 4, inputProps: { maxLength: 10 } },
        { name: 'office', inputElement: 'textField', label: 'Office', gridValue: 6 },
        { name: 'rep', inputElement: 'textField', label: 'Rep', gridValue: 6 },
    ];

    let createCarrier = async (formData, resetForm) => {
        let response = await api.createInboundCarrier(formData);
        if (response.status === true) {
            setNotification({ text: 'Carrier created!', level: 'success' });
            resetForm();
        } else {
            let message = 'Error creating carrier';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
    };

    return (
        <div>
            <h1>Create Inbound Carrier</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        createCarrier(formData, resetForm);
                    }}
                />
            </div>
        </div>
    );
};

export { CreateInboundCarrier };
