import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Notifications, DataTable, TabPanel, FormComponent } from '../../../shared';
import { productionApi as api } from '../../../api/production.js';

const SellItemCell = ({ row, column }) => {
    const green = '#ecfdf0';
    const red = '#fbe9eb';
    if (row.original.opType === 'create') {
        const prevWeekValue = row.original.prevWeekValues[column.id];
        if (prevWeekValue && prevWeekValue !== row.original[column.id]) {
            return (
                <div>
                    <div style={{ backgroundColor: red }}>
                    <s>{prevWeekValue}</s>
                    </div>
                    <div style={{ backgroundColor: green }}>{row.original[column.id]}</div>
                </div>
            );
        }
        if (!prevWeekValue) {
            return (
                <div>
                    <div style={{ backgroundColor: green }}>{row.original[column.id]}</div>
                </div>
            );
        }
        return row.original[column.id];
    } else if (row.original.opType === 'remove') {
        return (
            <div style={{ backgroundColor: red }}>
                <s>{row.original[column.id]}</s>
            </div>
        );
    } else { //its an update
        const prevValue = row?.original?.updatedFields?.[column.id]?.oldVal ?? false;
        const newValue = row?.original?.updatedFields?.[column.id]?.newVal ?? false;
    
        if (prevValue !== null && prevValue !== undefined && prevValue !== newValue) {
            return (
                <div>
                    <div style={{ backgroundColor: red }}>
                        <s>{prevValue}</s>
                    </div>
                    <div style={{ backgroundColor: green }}>{newValue}</div>
                </div>
            );
        }
        return row?.original?.[column.id] ?? null;
    }
};

const SellableItemUpload = ({ packGroupID, onSuccess }) => {
    const [uploadResults, setUploadResults] = useState(null);
    const [toolbarActions, setToolbarActions] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [confirming, setConfirming] = useState(false);
    const [loading, setLoading] = useState(false);

    const formFields = [
        { name: 'file', inputElement: 'file', accept: ['.csv'], label: 'Sellable Item File', gridValue: 12 },
    ];
    const columns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'External sku', accessor: 'externalSku', Cell: SellItemCell },
        { Header: 'Name', id: 'name', Cell: SellItemCell },
        { Header: 'Portion Name', id: 'portionName', Cell: SellItemCell },
        { Header: 'Price', id: 'price', Cell: SellItemCell },
        { Header: 'New', id: 'newItem', Cell: SellItemCell },
        { Header: 'Sample', id: 'sampleItem', Cell: SellItemCell },
        { Header: 'Gift', id: 'giftItem', Cell: SellItemCell },
        { Header: 'Exit Sku', id: 'exitSku', Cell: SellItemCell },
        { Header: 'Best Seller', id: 'bestSeller', Cell: SellItemCell },
        { Header: 'On Sale', id: 'onSale', Cell: SellItemCell },
        { Header: 'Mischief Pick', id: 'pickMischief', Cell: SellItemCell },
        { Header: 'Max Quantity', id: 'maxQuantity', Cell: SellItemCell },
        { Header: 'Sourcing Reason', id: 'sourcingReason', Cell: SellItemCell },
        { Header: 'Country of Origin 1', id: 'countryOfOrigin1', Cell: SellItemCell },
        { Header: 'Country of Origin 2', id: 'countryOfOrigin2', Cell: SellItemCell },
        { Header: 'Country of Origin 3', id: 'countryOfOrigin3', Cell: SellItemCell },
        { Header: 'L0 Rank', accessor: 'level0AisleRank', Cell: SellItemCell },
        { Header: 'L1 Rank', accessor: 'level1AisleRank', Cell: SellItemCell },
        { Header: 'L2 Rank', accessor: 'level2AisleRank', Cell: SellItemCell },
        { Header: 'Hide Sku', id: 'hideSku', Cell: SellItemCell },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    const errorColumns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'External sku', accessor: 'externalSku' },
        { Header: 'Errors', accessor: 'errors' },
    ];

    const confirmUpload = async (key) => {
        setLoading(true);
        setConfirming(true);
        if (!key) {
            setNotification({ text: 'No key provided for sellable item upload', level: 'error' });
            return;
        }

        const res = await api.setSellableItems(key);
        setConfirming(false);
        if (!res.status) {
            setNotification({ text: 'Failed to create sellable items', level: 'error' });
            setLoading(false);
            return;
        }

        setNotification({ text: 'Sellable items confirmed!', level: 'success' });
        setUploadResults(null);
        setToolbarActions([]);
        if (onSuccess instanceof Function) {
            onSuccess();
        }
        setLoading(false);
    };

    const uploadSellableItems = async (formData) => {
        setLoading(true);
        const res = await api.uploadSellableItems(packGroupID, formData);
        if (!res.status) {
            setNotification({ text: 'Failed to get results from sellable item upload. ' + res.msg, level: 'error' });
            setLoading(false);
            return;
        }
        const rowsWithErrors = [];
        const data = res.data;
        const createResults = (res.data.createResults ?? []).map((row) => {
            if (row.warnings && row.warnings.length === 0) {
                row.warnings = row.warnings.join('. ');
            }
            if (row.countryOfOrigin && row.countryOfOrigin.length > 0) {
                row.countryOfOrigin.forEach((country, index) => {
                    if (index === 0) row.countryOfOrigin1 = country;
                    if (index === 1) row.countryOfOrigin2 = country;
                    if (index === 2) row.countryOfOrigin3 = country;
                });
            }
            row.opType = "create";
            return row;
        });
        const removeResults = (res.data.removeResults ?? []).map((row) => {
            if (row.warnings && row.warnings.length === 0) {
                row.warnings = row.warnings.join('. ');
            }
            if (row.countryOfOrigin && row.countryOfOrigin.length > 0) {
                row.countryOfOrigin.forEach((country, index) => {
                    if (index === 0) row.countryOfOrigin1 = country;
                    if (index === 1) row.countryOfOrigin2 = country;
                    if (index === 2) row.countryOfOrigin3 = country;
                });
            }
            row.opType = "remove";
            return row;
        });
        const updateResults = (res.data.updateResults ?? []).map((row) => {
            if (row.warnings && row.warnings.length === 0) {
                row.warnings = row.warnings.join('. ');
            }
            if (row.countryOfOrigin && row.countryOfOrigin.length > 0) {
                row.countryOfOrigin.forEach((country, index) => {
                    if (index === 0) row.countryOfOrigin1 = country;
                    if (index === 1) row.countryOfOrigin2 = country;
                    if (index === 2) row.countryOfOrigin3 = country;
                });
            }
            row.opType = "update";
            return row;
        });
        const createFailures = (res.data.createFailures ?? []).map((row) => {
            if (row.errors && row.errors.length === 0) {
                row.errors = row.errors.join('. ');
            }
            row.opType = "create";
            return row;
        });
        const removeFailures = (res.data.removeFailures ?? []).map((row) => {
            if (row.errors && row.errors.length === 0) {
                row.errors = row.errors.join('. ');
            }
            row.opType = "remove";
            return row;
        });
        const updateFailures = (res.data.updateFailures ?? []).map((row) => {
            if (row.errors && row.errors.length === 0) {
                row.errors = row.errors.join('. ');
            }
            row.opType = "update";
            return row;
        });
        data.results = [...createResults, ...removeResults, ...updateResults].sort((a, b) => a.rowNumber - b.rowNumber);
        data.failures = [...createFailures, ...removeFailures, ...updateFailures].sort((a, b) => a.rowNumber - b.rowNumber);
        data.rowsWithErrors = rowsWithErrors;

        const actions = [
            {
                name: 'Clear File',
                action: () => {
                    setToolbarActions([]);
                    setUploadResults(null);
                },
            },
        ];
        actions.push({
            name: 'Confirm Changes',
            action: () => {
                const key = data.key;
                confirmUpload(key);
            },
            disabled: confirming,
        });

        setUploadResults(data);
        setToolbarActions(actions);
        setLoading(false);
    };

    const downloadTemplate = () => {
        const csvContent =
            'data:text/csv;charset=utf-8,External Sku,Remove (Y/N),Name,Portion Name,Price,New (Y/N),'
            + 'Sample (Y/N),On Sale(Y/N),Mischief Pick,Max Quantity,'
            + 'Level 0 Aisle Rank,Level 1 Aisle Rank,Level 2 Aisle Rank'
            + ',Gift (Y/N),Exit Sku (Y/N),Best Seller (Y/N),Sourcing Reason'
            + ',Country of Origin 1*,Country of Origin 2,Country of Origin 3,Hide Sku (Y/N)';
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'sellable_item_upload_template.csv');
    document.body.appendChild(link);

    link.click();
    };

    return (
        <div>
            <Notifications options={notification} />
            <h3>Upload Sellable Items</h3>
            {!uploadResults && (
                <>
                    <Button
                        onClick={downloadTemplate}
                    >
                        Download Template
                    </Button>
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData, resetForm) => {
                            await uploadSellableItems(formData);
                        }}
                    />
                </>
            )}
            {loading ? (
                <CircularProgress />
            ) : (!!uploadResults && (
                <>
                    {uploadResults.error ? (
                        <>
                            <Alert severity="warning">
                                There are errors in the uploaded file. Please review and upload corrected csv.
                            </Alert>
                            <h3>Identified Errors</h3>
                            <DataTable
                                columns={errorColumns}
                                data={ uploadResults?.failures ?? [] }
                                csvExport={true}
                                csvExportHeadersUseAccessor={true}
                                csvExportFileName={`sellable_item_upload_errors_pg-${packGroupID}`}
                            />
                        </>
                    ) : (
                        <Alert severity="success">Please review and confirm changes.</Alert>
                    )}
                    <DataTable
                        columns={columns}
                        toolbarActions={toolbarActions}
                        data={ uploadResults?.results ?? [] }
                        rowStyle={(row) => {
                            if (row.errors.length) {
                                return { color: 'red', border: '3px solid red' };
                            } else if (row.warnings && row.warnings.length) {
                                return { color: '#FFB300', border: '3px solid #FFB300' };
                            }
                            return {};
                        }}
                    />
                </>
            ))}
        </div>
    );
};

export { SellableItemUpload };
