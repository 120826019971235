import React, { useEffect, useState, useContext, useRef } from 'react';
import { PartnershipTabs, Nav, Input, PartnershipsPagination, FiltersWithModal } from '../../shared';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { partnershipsApi as api } from '../../../api/partnerships';
import { UserContext } from '../../../contexts/UserContext';
import { Dialog, DialogContent, LinearProgress } from '@material-ui/core';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import { Alert } from '@material-ui/lab';
import { OrderInfoCard } from './components/orderInfoCard.js';
import { PartnershipImports } from './components/imports.js';
import './orders.css';

const Orders = () => {
    const { famAccount, roles } = useContext(UserContext);
    const [uploadOrdersDialogOpen, setUploadOrdersDialogOpen] = useState(false);
    const [uploadOrdersSuccess, setUploadOrdersSuccess] = useState('');
    const [canCreateOrders, setCanCreateOrders] = useState(false);

    useEffect(() => {
        setCanCreateOrders(roles.map((role) => role.name).includes('IMPORT_ORDERS'));
    }, [roles]);

    useEffect(() => {
        if (!uploadOrdersSuccess) {
            return;
        }
        setTimeout(() => {
            setUploadOrdersSuccess('');
        }, 5000);
    }, [uploadOrdersSuccess]);

    const downloadOrderTemplate = () => {
        let csvContent =
            'data:text/csv;charset=utf-8,First Name,Last Name,Line One,Line Two,' +
            'City,State,Zip,Email (For Order Confirmation),Phone,List Name,Allergens,Day,Notes,' +
            'Member ID,Order ID,Slot,Sku,Quantity';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'order_import_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    const handleUploadOrdersConfirm = async (key) => {
        const res = await api.confirmOrderUpload(key);
        return res;
    };

    const handleUploadOrders = async (formData) => {
        formData.source = famAccount.partnershipSource;
        const res = await api.uploadOrders(formData);
        return res;
    };

    const downloadActiveCatalog = () => {
        api.getItemsWithTaxonomyCSV(famAccount.partnershipSource);
    };

    const tabs = [
        {
            name: 'My Orders',
            component: <OrdersTab partnershipSource={famAccount.partnershipSource} canCancelOrders={canCreateOrders} />,
        },
        {
            name: 'Imports',
            component: <PartnershipImports partnershipSource={famAccount.partnershipSource} />,
        },
    ];

    if (!famAccount?.partnershipSource) {
        return (
            <div className="page-container">
                <Nav />
                <div className="content-container">
                    <div className="width-100">
                        <div className="fam-header-container">
                            <div className="fam-header-item-one">
                                <div className="grotesk-bold header-text">Please Select a Partner</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="page-container">
            <Nav />
            <Dialog
                open={uploadOrdersDialogOpen}
                maxWidth="xl"
                fullWidth={true}
                onClose={() => {
                    setUploadOrdersDialogOpen(false);
                }}
            >
                <DialogContent>
                    {!!uploadOrdersSuccess && <Alert severity="success">{uploadOrdersSuccess}</Alert>}
                    <UploadCSVAndReviewComponent
                        fileLabel="Orders CSV"
                        title="Upload Orders"
                        upload={handleUploadOrders}
                        confirm={handleUploadOrdersConfirm}
                        columns={[
                            { Header: 'Row', accessor: 'rowNumber' },
                            { Header: 'First Name', accessor: 'firstName' },
                            { Header: 'Last Name', accessor: 'lastName' },
                            { Header: 'Address Line One', accessor: 'lineOne' },
                            { Header: 'Address Line Two', accessor: 'lineTwo' },
                            { Header: 'City', accessor: 'city' },
                            { Header: 'State', accessor: 'state' },
                            { Header: 'Zip', accessor: 'zip' },
                            { Header: 'Email', accessor: 'email' },
                            { Header: 'Phone', accessor: 'phone' },
                            { Header: 'List Name', accessor: 'curatedListName' },
                            { Header: 'Allergens', accessor: 'allergens' },
                            {
                                Header: 'Skus',
                                width: '250px',
                                Cell: ({ row }) => {
                                    return (
                                        <div>
                                            <ul>
                                                {row.original.skus.map((sku) => (
                                                    <li>
                                                        Slot {sku.slot}:
                                                        <span style={{ fontWeight: 'bold' }}>{sku.sku} </span>-{' '}
                                                        {sku.name} (Qty: {sku.quantity})
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    );
                                },
                            },
                            { Header: 'Day', accessor: 'day' },
                            { Header: 'Notes', accessor: 'notes' },
                            { Header: 'Member ID', accessor: 'memberID' },
                            { Header: 'Order ID', accessor: 'orderID' },
                            {
                                Header: 'Errors',
                                accessor: 'errors',
                                width: '150px',
                                Cell: ({ row }) => {
                                    return (
                                        <div>
                                            <ul>
                                                {row.original?.errorsArr?.map((err) => (
                                                    <li>{err}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        onSuccess={() => {
                            setUploadOrdersSuccess(
                                'Items uploaded! You will receive results by email when all orders have been processed'
                            );
                        }}
                    />
                </DialogContent>
            </Dialog>
            <div className="content-container">
                <div className="width-100">
                    <div className="fam-header-container">
                        <div className="fam-header-item-one">
                            <div className="grotesk-bold header-text">My Orders</div>
                        </div>
                        <div className="fam-header-item-two">
                            {canCreateOrders && (
                                <>
                                    <button
                                        onClick={() => {
                                            downloadActiveCatalog();
                                        }}
                                        className="fam-button fam-button-white grotesk-bold"
                                    >
                                        Download Inventory
                                    </button>
                                    <button
                                        onClick={() => {
                                            downloadOrderTemplate();
                                        }}
                                        className="fam-button fam-button-white grotesk-bold"
                                    >
                                        Download Spreadsheet Template
                                    </button>
                                    <button
                                        onClick={() => {
                                            setUploadOrdersDialogOpen(true);
                                        }}
                                        className="fam-button fam-button-yellow grotesk-bold"
                                    >
                                        Upload Orders
                                    </button>
                                </>
                            )}
                        </div>
                    </div>

                    <PartnershipTabs tabs={tabs} />
                </div>
            </div>
        </div>
    );
};

const OrdersTab = ({ partnershipSource, canCancelOrders }) => {
    const [orderNumFilter, setOrderNumFilter] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [filterableFields, setFilterableFields] = useState([]);
    const [filters, setFilters] = useState({});
    const [orders, setOrders] = useState([]);
    const [orderCount, setOrderCount] = useState(0);

    const [page, setPage] = useState(0);
    const [newPage, setNewPage] = useState(0);
    const [sortDescending, setSortDescending] = useState(true);
    const [newSortDescending, setNewSortDescending] = useState(true);

    const [loading, setLoading] = useState(false);

    const loadNewDataAndSetState = async (attribute) => {
        const res = await getOrders({});
        if (!res) {
            return;
        }

        if (attribute === 'newPage') {
            setPage(newPage);
        } else if (attribute === 'newSortDescending') {
            setSortDescending(newSortDescending);
        }
    };

    useEffect(() => {
        loadNewDataAndSetState('newPage');
    }, [newPage]);
    useEffect(() => {
        loadNewDataAndSetState('newSortDescending');
    }, [newSortDescending]);

    const getOrders = async ({ newIDSearch, newFilters }) => {
        setLoading(true);
        let pageNum = newPage;
        let search = orderNumFilter;
        let filterBy = filters;

        if (newIDSearch !== undefined) {
            search = newIDSearch;
        }
        if (!!newFilters) {
            filterBy = newFilters;
        }

        let orderBy = newSortDescending ? 'DESC' : 'ASC';

        const res = await api.getPartnershipOrders(partnershipSource, pageNum, search, filterBy, orderBy);
        if (!res.status || !res.data) {
            setLoading(false);
            return false;
        }

        setOrders(res.data.rows);
        setOrderCount(res.data.count);
        setFilterableFields(res.data.filters);
        setLoading(false);
        return true;
    };

    useEffect(() => {
        if (!partnershipSource) {
            return;
        }
        getOrders({});
    }, [partnershipSource]);

    const pageSize = 20;

    const searchTimeoutRef = useRef(null);

    const handleChangePage = async (newPage) => {
        setNewPage(newPage);
    };

    const handleChangeSortOrder = async () => {
        setNewSortDescending(!sortDescending);
    };

    const handleOrderNumSearch = async (value) => {
        setOrderNumFilter(value);
        clearTimeout(searchTimeoutRef.current);

        searchTimeoutRef.current = setTimeout(() => {
            getOrders({ newIDSearch: value });
        }, 500);
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        getOrders({ newFilters });
    };

    useEffect(() => {
        if (!successMessage) {
            return;
        }
        setTimeout(() => {
            setSuccessMessage('');
        }, 5000);
    }, [successMessage]);

    return (
        <div>
            <div className="fam-success-alert">
                {!!successMessage && <Alert severity="success">{successMessage}</Alert>}
            </div>
            <div className="orders-filter-container">
                <div>
                    <Input label="Order Number or Member ID" value={orderNumFilter} onChange={handleOrderNumSearch} />
                </div>
                <FiltersWithModal
                    filterableFields={filterableFields}
                    filters={filters}
                    setFilters={handleFilterChange}
                />
                <div>
                    <span className="grotesk-bold">Sort By Imported Date</span>
                    &nbsp;&nbsp;
                    <span className="clickable" onClick={handleChangeSortOrder}>
                        {sortDescending ? (
                            <KeyboardArrowDownRoundedIcon className="partnerships-icon-30px" />
                        ) : (
                            <KeyboardArrowUpRoundedIcon className="partnerships-icon-30px" />
                        )}
                    </span>
                </div>
            </div>

            <div className="fam-header-container">
                <div className="fam-header-item-one"></div>
                <div className="fam-header-item-two">
                    <PartnershipsPagination
                        count={orderCount}
                        rowsPerPage={pageSize}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                </div>
            </div>
            {<div className="fam-loading-container">{loading && <LinearProgress color="secondary" />}</div>}
            {orders.map((order) => (
                <OrderInfoCard
                    order={order}
                    key={order.mmOrderNumber}
                    filters={orderNumFilter}
                    getOrders={getOrders}
                    setSuccessMessage={setSuccessMessage}
                    canCancelOrders={canCancelOrders}
                />
            ))}
        </div>
    );
};

export { Orders };
