import React from 'react';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { logisticsApi as api } from '../../../api/logistics';

let FBMZipMapUpload = () => {
    let columns = [
        { Header: 'Zip Code', accessor: 'zipCode' },
        { Header: 'Line Haul ID', accessor: 'lineHaulID' },
        { Header: 'Delivery Day', accessor: 'deliveryDay' },
        { Header: 'FBM Account Name', accessor: 'fbmAccountName' },
        { Header: 'Transit Days', accessor: 'transitDays' },
        { Header: 'Delivery Type', accessor: 'deliveryType' },
        { Header: 'Order Type', accessor: 'orderType' },
        { Header: 'Extra Params', accessor: 'extraParameters' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.confirmFBMZipUpload(key);
        return res;
    };

    let uploadZipMap = async (formData) => {
        let res = await api.uploadFBMZipMap(formData);
        return res;
    };

    let downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,zipCode,lineHaulID,deliveryDay,fbmAccountName,transitDays';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'fbm_zip_map.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <UploadCSVAndReviewComponent
                title="Upload FBM Zip Map"
                fileLabel="Zip File"
                columns={columns}
                upload={uploadZipMap}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
            />
        </div>
    );
};

export { FBMZipMapUpload };
