import React, { useState, useEffect } from 'react';
import {
    Divider,
    Grid,
    InputLabel,
    Select,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormComponent, DataTable } from '../../../shared';
import { customerApi as api } from '../../../api/customer';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { ApplyCredit } from './ApplyCredit';
import { Donations } from './Donations';
import { FormWithDisplay } from './FormWithDisplay';
import { Notifications } from './Notifications';
import { Orders } from './Orders/Orders';
import { DrinksOrders } from './DrinksOrders';
import { UpdateDeliveryFrequency } from './UpdateDeliveryFrequency';
import { CartExperience } from './CartExperience';
import { PlusMembership } from './PlusMembership';

const EditAddress = ({ address, updateAddress }) => {
    const [editing, setEditing] = useState(false);
    const [resetFormCounter, setResetFormCounter] = useState(-1);

    useEffect(() => {
        if (!editing) {
            let tmp = resetFormCounter;
            tmp++;
            setResetFormCounter(tmp);
        }
    }, [editing]); // eslint-disable-line react-hooks/exhaustive-deps

    let formFields = [
        {
            name: 'firstName',
            initialValue: address?.firstName ? address.firstName : '',
            label: 'First Name',
            gridValue: 6,
        },
        { name: 'lastName', initialValue: address?.lastName ? address.lastName : '', label: 'Last Name', gridValue: 6 },
        { name: 'address1', initialValue: address?.address1 ? address.address1 : '', label: 'Address' },
        { name: 'address2', initialValue: address?.address2 ? address.address2 : '', label: 'Address 2' },
        { name: 'city', initialValue: address?.city ? address.city : '', label: 'City' },
        {
            name: 'province',
            inputElement: 'select',
            initialValue: address?.province ? address.province : '',
            gridValue: 6,
            label: 'State',
            inputProps: {
                opts: [
                    { value: 'AL', text: 'Alabama' },
                    { value: 'AR', text: 'Arkansas' },
                    { value: 'AZ', text: 'Arizona' },
                    { value: 'CA', text: 'California' },
                    { value: 'CT', text: 'Connecticut' },
                    { value: 'CO', text: 'Colorado' },
                    { value: 'DC', text: 'District of Columbia' },
                    { value: 'DE', text: 'Delaware' },
                    { value: 'FL', text: 'Florida' },
                    { value: 'GA', text: 'Georgia' },
                    { value: 'ID', text: 'Idaho' },
                    { value: 'IL', text: 'Illinois' },
                    { value: 'IN', text: 'Indiana' },
                    { value: 'IA', text: 'Iowa' },
                    { value: 'KS', text: 'Kansas' },
                    { value: 'KY', text: 'Kentucky' },
                    { value: 'LA', text: 'Louisiana' },
                    { value: 'ME', text: 'Maine' },
                    { value: 'MD', text: 'Maryland' },
                    { value: 'MA', text: 'Massachusetts' },
                    { value: 'MI', text: 'Michigan' },
                    { value: 'MS', text: 'Mississippi' },
                    { value: 'MN', text: 'Minnesota' },
                    { value: 'MO', text: 'Missouri' },
                    { value: 'MT', text: 'Montana' },
                    { value: 'NE', text: 'Nebraska' },
                    { value: 'NV', text: 'Nevada' },
                    { value: 'NH', text: 'New Hampshire' },
                    { value: 'NJ', text: 'New Jersey' },
                    { value: 'NM', text: 'New Mexico' },
                    { value: 'NY', text: 'New York' },
                    { value: 'NC', text: 'North Carolina' },
                    { value: 'ND', text: 'North Dakota' },
                    { value: 'OR', text: 'Oregon' },
                    { value: 'PA', text: 'Pennsylvania' },
                    { value: 'OH', text: 'Ohio' },
                    { value: 'OK', text: 'Oklahoma' },
                    { value: 'RI', text: 'Rhode Island' },
                    { value: 'SC', text: 'South Carolina' },
                    { value: 'SD', text: 'South Dakota' },
                    { value: 'TN', text: 'Tennessee' },
                    { value: 'TX', text: 'Texas' },
                    { value: 'UT', text: 'Utah' },
                    { value: 'VT', text: 'Vermont' },
                    { value: 'VA', text: 'Virginia' },
                    { value: 'WA', text: 'Washington' },
                    { value: 'WV', text: 'West Virginia' },
                    { value: 'WI', text: 'Wisconsin' },
                    { value: 'WY', text: 'Wyoming' },
                ],
            },
        },
        { name: 'zip', initialValue: address?.zip ? address.zip : '', gridValue: 6, label: 'Zip' },
        { name: 'phone', initialValue: address?.phone ? address.phone : '', gridValue: 6, label: 'Phone' },
        {
            name: 'phoneType',
            initialValue: address?.phoneType ? address.phoneType : '',
            gridValue: 6,
            label: 'Phone Type',
            inputProps: { readOnly: true },
        },
        {
            name: 'deliveryInstructions',
            initialValue: address?.deliveryInstructions ?? '',
            label: 'Delivery Instructions',
        },
        {
            name: 'internalDriverNotes',
            initialValue: address?.internalDriverNotes ?? '',
            label: 'Internal Driver Notes',
        },
    ];

    return (
        <FormWithDisplay
            formFields={formFields}
            title="Shipping Information"
            onSubmit={async (formData) => {
                let res = await updateAddress(formData);
                return res;
            }}
        />
    );
};

const Charges = ({ classes, subscription, skipCharge, unskipCharge }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [chargeRows, setChargeRows] = useState([]);

    let columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Scheduled For', accessor: 'scheduledFor' },
        { Header: 'Status', accessor: 'status' },
        {
            Header: 'Skip/Unskip',
            Cell: ({ row }) => {
                return (
                    <div>
                        <span
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => {
                                if (row.original.status === 'SKIPPED') {
                                    unskipCharge(row.original.id);
                                } else {
                                    skipCharge(row.original.id);
                                }
                            }}
                        >
                            {row.original.status === 'SKIPPED' ? 'UNSKIP' : 'SKIP'}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: 'Inspect',
            Cell: ({ row }) => {
                return (
                    <div>
                        <span
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={async () => {
                                setChargeRows([]);

                                let res = await api.inspectCharge(row.original.id);
                                if (!res.status) {
                                    return false;
                                }

                                setChargeRows(res.data.items);
                                setModalOpen(true);
                            }}
                        >
                            Inspect
                        </span>
                    </div>
                );
            },
        },
    ];
    let today = moment();
    let data = subscription.charges
        .filter((item) => {
            if (!item.scheduledAt) {
                return false;
            }
            let d = moment(item.scheduledAt.date);
            if (d.diff(today) < 0) {
                return false;
            }
            return true;
        })
        .map((item) => {
            item.scheduledFor = moment(item.scheduledAt.date).format('MM/DD/YYYY');
            return item;
        });
    return (
        <>
            {modalOpen && (
                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={modalOpen}
                    onClose={() => {
                        setModalOpen(false);
                    }}
                >
                    <DialogContent>
                        <DataTable
                            title={'Charge'}
                            data={chargeRows}
                            columns={[
                                { accessor: 'name', Header: 'Name' },
                                { accessor: 'price', Header: 'Price' },
                                { accessor: 'qty', Header: 'Amount' },
                            ]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setModalOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <InputLabel className={classes.inputLabel}>Upcoming Charges</InputLabel>
            <DataTable columns={columns} data={data} showPagination={false} />
        </>
    );
};

const CreditsInformation = ({
    subscription,
    creditsAvailable,
    nextBoxCredit,
    getCreditsBalance,
    classes,
    removeCredit,
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (modalOpen) {
            getCreditsBalance(subscription.id);
        }
    }, [modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <DialogContent>
                    <DataTable
                        title={'Credits'}
                        data={creditsAvailable}
                        columns={[
                            { accessor: 'creditModel.id', Header: 'ID' },
                            { accessor: 'creditModel.amount', Header: 'Amount' },
                            { accessor: 'creditModel.status', Header: 'Status' },
                            { accessor: 'creditModel.sourceType', Header: 'Type' },
                            { accessor: 'creditModel.expiresOn', Header: 'Expires On' },
                            {
                                accessor: 'delete',
                                Header: 'Remove',
                                Cell: ({ row }) => {
                                    return (
                                        <span
                                            onClick={async () => {
                                                let res = await removeCredit(row.original.creditModel.id);
                                                getCreditsBalance(subscription.id);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <CloseIcon />
                                        </span>
                                    );
                                },
                            },
                        ]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setModalOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <FormWithDisplay
                title="Loyalty and Credits Information"
                editable={false}
                formFields={[
                    {
                        name: 'numCreditsAvailable',
                        label: 'Credits Available',
                        initialValue: creditsAvailable.length || '0',
                        inputProps: { readOnly: true },
                        InputProps: { disableUnderline: true },
                    },
                    {
                        name: 'loyaltyPointsAvailable',
                        label: 'Loyalty Points Available',
                        initialValue: subscription.loyaltyPointsBalance || '0',
                        inputProps: { readOnly: true },
                        InputProps: { disableUnderline: true },
                    },
                    {
                        name: 'nextCredit',
                        label: 'Next Box',
                        initialValue: nextBoxCredit > 0 ? '$' + nextBoxCredit : 'No credits',
                        inputProps: { readOnly: true },
                        InputProps: { disableUnderline: true },
                    },
                ]}
            />
            <Button
                variant="outlined"
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                Details
            </Button>
        </>
    );
};

const GiftCodesInformation = ({ subscription, giftCodes, getGiftCodes, resendGiftCode }) => {
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (modalOpen) {
            getGiftCodes(subscription.id);
        }
    }, [modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <DialogContent>
                    <DataTable
                        title={'Gift Codes'}
                        data={giftCodes}
                        columns={[
                            { accessor: 'id', Header: 'ID' },
                            { accessor: 'code', Header: 'Code' },
                            { accessor: 'amount', Header: 'Amount' },
                            { accessor: 'status', Header: 'Status' },
                            { accessor: 'purchasedOn', Header: 'Purchased On' },
                            { accessor: 'expiresOn', Header: 'Expires On' },
                            {
                                accessor: 'resend',
                                Header: 'Resend email',
                                Cell: ({ row }) => {
                                    return row.original.status === 'AVAILABLE' ? (
                                        <Button
                                            onClick={() => {
                                                resendGiftCode(row.original.code);
                                            }}
                                        >
                                            Resend
                                        </Button>
                                    ) : null;
                                },
                            },
                        ]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setModalOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <FormWithDisplay
                title="Gift Codes Information"
                editable={false}
                formFields={[
                    {
                        name: 'purchasedGiftCodes',
                        label: 'Gift Codes Purchased',
                        initialValue: giftCodes.length || '0',
                        inputProps: { readOnly: true },
                        InputProps: { disableUnderline: true },
                    },
                ]}
            />
            <Button
                variant="outlined"
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                Details
            </Button>
        </>
    );
};

const PromoCodeInformation = ({ classes, subscription, promoCodes, getPromoCodes }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [terms, setTerms] = useState('Not specified');

    useEffect(() => {
        if (modalOpen) {
            getPromoCodes(subscription.id);
        }
    }, [modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    let columns = [
        { Header: 'Code', accessor: 'code' },
        { Header: 'Use By', accessor: 'useBy' },
        { Header: 'Claim By', accessor: 'claimBy' },
        { Header: 'Order Used', accessor: 'orderNum' },
        { Header: 'Source', accessor: 'source' },
        {
            accessor: 'terms',
            Header: 'Terms',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={() => {
                            setTerms(row.original.terms);
                            setModalOpen(true);
                        }}
                    >
                        Terms
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <DialogContent>
                    <span>{terms}</span>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setModalOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <InputLabel className={classes.inputLabel}>Promo Codes</InputLabel>
            <DataTable columns={columns} data={promoCodes} />
        </>
    );
};

const EditEmail = ({ subscription, updateEmail, accountInfo, updateTip }) => {
    let formFields = [
        {
            name: 'customerID',
            label: 'Customer ID',
            initialValue: subscription.customerID,
            gridValue: 4,
            inputProps: { readOnly: true },
            InputProps: { disableUnderline: true },
        },
        {
            name: 'subscriptionID',
            label: 'Subscription ID',
            gridValue: 4,
            initialValue: subscription.id,
            inputProps: { readOnly: true },
            InputProps: { disableUnderline: true },
        },
        {
            name: 'paymentStack',
            label: 'V2 Payments',
            gridValue: 4,
            initialValue: accountInfo?.featureFlags?.payv2,
            inputProps: { readOnly: true },
            InputProps: { disableUnderline: true },
        },
        {
            name: 'status',
            label: 'Status',
            gridValue: 5,
            initialValue: subscription.status,
            inputProps: { readOnly: true },
            InputProps: { disableUnderline: true },
        },
        {
            name: 'email',
            label: 'Email',
            gridValue: 7,
            initialValue: subscription.email,
        },
        {
            name: 'createdAt',
            label: 'Created At',
            gridValue: 5,
            initialValue: subscription.createdAt,
            inputProps: { readOnly: true },
            InputProps: { disableUnderline: true },
        },
        {
            name: 'brand',
            label: 'Brand',
            gridValue: 7,
            initialValue: subscription.brand,
            inputProps: { readOnly: true },
            InputProps: { disableUnderline: true },
        },
        {
            name: 'isFriendsAndFamily',
            label: 'Employee Box',
            gridValue: 5,
            initialValue: accountInfo?.isFriendsAndFamily,
            inputProps: { readOnly: true },
            InputProps: { disableUnderline: true },
        },
        {
            name: 'preferredTipVal',
            label: 'Preferred Tip Value',
            gridValue: 7,
            initialValue: '$' + accountInfo?.preferredTipVal,
        },
        {
            name: 'marketOpen',
            label: 'Marketplace Open',
            gridValue: 5,
            inputProps: { readOnly: true },
            initialValue: accountInfo?.marketOpenDate,
        },
        {
            name: 'marketClose',
            label: 'Marketplace Close',
            gridValue: 7,
            inputProps: { readOnly: true },
            initialValue: accountInfo?.marketCloseDate,
        },
        {
            name: 'lifetimeSpend',
            label: 'Lifetime Spend',
            gridValue: 4,
            inputProps: { readOnly: true },
            initialValue: '$' + (subscription?.lifetimeSpend || 0).toFixed(2),
        },
        {
            name: 'lifetimeRefund',
            label: 'Lifetime Refund/Credits',
            gridValue: 4,
            inputProps: { readOnly: true },
            initialValue: '$' + (subscription?.lifetimeRefunds || 0).toFixed(2),
        },
        {
            name: 'lifetimeRefundPerc',
            label: 'Lifetime Refund %',
            gridValue: 4,
            inputProps: { readOnly: true },
            initialValue: (subscription?.lifetimeRefundPercent || 0).toFixed(2) + '%',
        },
        {
            name: 'ninetySpend',
            label: '90 day Spend',
            gridValue: 4,
            inputProps: { readOnly: true },
            initialValue: '$' + (subscription?.ninetySpend || 0).toFixed(2),
        },
        {
            name: 'ninetyRefund',
            label: '90 day Refund/Credits',
            gridValue: 4,
            inputProps: { readOnly: true },
            initialValue: '$' + (subscription?.ninetyRefunds || 0).toFixed(2),
        },
        {
            name: 'ninetyRefundPerc',
            label: '90 day Refund %',
            gridValue: 4,
            inputProps: { readOnly: true },
            initialValue: (subscription?.ninetyRefundPercent || 0).toFixed(2) + '%',
        },
    ];

    return (
        <FormWithDisplay
            title="Administrative Information"
            formFields={formFields}
            onSubmit={async (formData) => {
                if (formData.email !== subscription.email) {
                    let res = await updateEmail(formData.email);
                    return res;
                } else {
                    let res = await updateTip(formData.preferredTipVal);
                    return res;
                }
            }}
        />
    );
};

const CustomerAccount = ({ customerID, setNotification, v2Plans }) => {
    const useStyles = makeStyles((theme) => ({
        section: {
            padding: '15px 10px',
            backgroundClip: 'padding-box',
        },
        card: {
            padding: '20px 15px',
            backgroundClip: 'padding-box',
            background: '#fff',
            borderRadius: '5px',
            boxShadow: '0px 0px 25px rgba(45, 45, 45, 0.1)',
            height: '100%',
        },
        inputElement: {
            width: '100%',
        },
        inputLabel: {
            paddingBottom: '10px',
            color: 'black',
            fontWeight: 'bold',
        },
        inputFormLabel: {
            position: 'relative!important',
            '& .MuiInputLabel-formControl': {
                position: 'relative!important',
            },
        },
    }));
    const classes = useStyles();

    let [subscription, setSubscription] = useState(null);
    let [accountInfo, setAccountInfo] = useState(null);
    let [orders, setOrders] = useState([]);
    let [drinksOrders, setDrinksOrders] = useState([]);
    let [preferences, setPreferences] = useState(null);
    let [changelog, setChangelog] = useState([]);
    let [donations, setDonations] = useState(false);
    let [creditsAvailable, setCreditsAvailable] = useState(0);
    let [nextBoxCredit, setNextBoxCredit] = useState(0);
    let [giftCodes, setGiftCodes] = useState([]);
    let [promoCodes, setPromoCodes] = useState([]);
    let [downgradeReasons, setDowngradeReasons] = useState([]);
    let [primaryReasons, setPrimaryReasons] = useState([]);
    let [secondaryReasons, setSecondaryReasons] = useState([]);
    let [customerComms, setCustomerComms] = useState([]);
    let [cognitoExists, setCognitoExists] = useState(false);
    let [cognitoTempPassword, setCognitoTempPassword] = useState(null);
    let [membership, setMembership] = useState({
        carrier: '',
        term: {
            isActive: 'f',
            typeOfMembership: 'NONE',
            startDate: '',
            endDate: '',
            isAutoRenew: 'f',
        },
        variant: {
            dbiShippingFee: 0,
            csShippingFeeDiscount: 0,
        },
        charge: {
            amountCharged: 0,
            amountRefunded: 0,
            chargeDate: '',
            refundCalculations: {
                fullRefundAmount: 0,
                proRatedRefundAmount: 0,
            },
        },
    });

    let handleRes = async (res, successMessage, errorMessage) => {
        if (!res.status) {
            setNotification({ text: errorMessage, level: 'error' });
            return false;
        }

        setNotification({ text: successMessage, level: 'success' });
        await getSubscription();
        return true;
    };

    let pauseSubscription = async (numMonths) => {
        let res = await api.pauseSubscription(subscription.id, numMonths);
        return handleRes(res, 'Subscription paused', `Failed to pause: ` + res.msg);
    };

    let resumeSubscription = async () => {
        let res = await api.resumeSubscription(subscription.id);
        return handleRes(res, 'Subscription resumed', `Failed to resume subscription`);
    };

    let activateSubscription = async () => {
        let res = await api.reactivateSubscription(subscription.id);
        return handleRes(res, 'Subscription activated', 'Failed to activate subscription');
    };

    let cancelSubscription = async () => {
        let res = await api.cancelSubscription(subscription.id);
        return handleRes(res, 'Subscription cancelled', 'Failed to cancel subscription');
    };

    let convertToAutoship = async () => {
        let res = await api.convertToAutoship(subscription.id);
        return handleRes(res, 'Subscription converted', 'Failed to convert subscription');
    };

    let convertToMembership = async (reason, secondaryReason) => {
        let res = await api.convertToMembership(subscription.id, reason, secondaryReason);
        return handleRes(res, 'Subscription converted', 'Failed to convert subscription');
    };

    let removeCredit = async (creditID) => {
        let res = await api.removeCredit(creditID);
        return handleRes(res, 'Credit removed', 'Failed to remove credit');
    };

    let applyCredit = async (sendParams) => {
        let res = await api.addCredit(subscription.id, sendParams);
        return handleRes(res, 'Credit Applied', 'Failed to apply credit');
    };

    let updateSubscription = async (sendParams) => {
        let res = await api.updateSubscription(subscription.id, sendParams);
        return handleRes(res, 'Subscription updated', 'Failed to update subscription');
    };

    let updateAddress = async (formData) => {
        formData.country = 'United States';
        let res = await api.updateShippingAddress(subscription.id, formData);
        return handleRes(res, 'Shipping address updated', 'Failed to update shipping address: ' + res.msg);
    };

    let updateEmail = async (email) => {
        let res = await api.updateEmail(subscription.id, email);
        return handleRes(res, 'Email address updated', 'Failed to update email address: ' + res.msg);
    };

    let updateTip = async (tip) => {
        let res = await api.updatePreferredTip(subscription.id, tip);
        return handleRes(res, 'Tip updated', 'Failed to update tip: ' + res.msg);
    };

    let removeBilling = async () => {
        let res = await api.removeBilling(subscription.id);
        return handleRes(res, 'Billing information removed', 'Unable to remove billing. Reason: ' + res.msg);
    };

    let skipCharge = async (chargeID) => {
        let res = await api.skipCharge(chargeID);
        return handleRes(res, 'Charge skipped', 'Unable to skip charge: ' + res.msg);
    };

    let unskipCharge = async (chargeID) => {
        let res = await api.unskipCharge(chargeID);
        return handleRes(res, 'Charge unskipped', 'Unable to unskip charge: ' + res.msg);
    };

    let resendGiftCode = async (code) => {
        let res = await api.resendGiftCode(code);
        return handleRes(res, 'Email resent', 'Unable to resend email: ' + res.msg);
    };

    let getSubscription = async () => {
        let res = await api.getSubscription(customerID);
        if (!res.status) {
            setNotification({ text: 'Failed to get subscription', level: 'error' });
            return;
        }
        let subscriptions = res.data.subscriptions;
        if (!subscriptions || !subscriptions.length) {
            setNotification({ text: 'Customer found, but has no subscription', level: 'error' });
            return;
        }

        if (subscriptions[0].createdAt !== null) {
            subscriptions[0].createdAt = moment(subscriptions[0].createdAt.date).format('MM/DD/YYYY');
        }

        let hasDonatedCharges = subscriptions[0].charges.some((charge) => {
            return charge.status === 'DONATED' || charge.status === 'FAILED_TO_DONATE';
        });

        if (hasDonatedCharges) setDonations(true);

        setSubscription(subscriptions[0]);

        getChangelog(subscriptions[0].id);

        getCreditsBalance(subscriptions[0].id);

        getGiftCodes(subscriptions[0].id);

        getPromoCodes(subscriptions[0].id);

        getMembershipSummary(customerID);
    };

    const getCognitoInformation = async () => {
        const res = await api.getCognitoStatus(customerID);
        const { data = { exists: false } } = res;
        const { exists } = data;
        setCognitoExists(exists);
    };

    const getMembershipSummary = async (custID) => {
        let res = await api.getMembershipSummary(custID);
        if (!res.status) {
            return;
        }

        setMembership(res.data.summary);
    };

    const generateTempPassword = async () => {
        const res = await api.createCognitoTempPassword(customerID);
        const { status, data = {} } = res;
        if (!status || !data || !data.password) {
            setNotification({ text: 'Error setting temporary password', level: 'error' });
            return;
        }

        if (res.msg === 'Success') {
            setCognitoTempPassword(data.password);
            setNotification({ text: 'Temporary password set successfully', level: 'success' });
        } else {
            setNotification({ text: 'Failed to set temporary password', level: 'error' });
        }
    };

    let getSubscriptionInformation = async () => {
        getSubscription();
        getOrders();
        getDrinksOrders();
        getAccountInfo();
        getNotificationPreferences();
        getCustomerCommunications();
        getCognitoInformation();
    };

    let getChangelog = async (subID) => {
        let res = await api.getChangelog(subID);
        if (!res.status) {
            setNotification({ text: 'Failed to get subscription changelog', level: 'error' });
            return;
        }
        setChangelog(
            res.data.rows.map((row) => {
                row.timestampEST = moment(row.timestampEST.date).format('MM/DD/YYYY h:mm a');
                return row;
            })
        );
    };

    let getAccountInfo = async () => {
        let res = await api.getAccountInfo(customerID);
        if (!res.status || !res.data || !res.data.resp) {
            setNotification({ text: 'Failed to get subscription', level: 'error' });
            return;
        }
        res.data.resp.marketOpenDate =
            res.data.resp.subStatus != 'ACTIVE'
                ? 'N/A'
                : moment(res.data.resp.marketOpenDate.date).format('MM/DD/YYYY h:mm a');
        res.data.resp.marketCloseDate =
            res.data.resp.subStatus != 'ACTIVE'
                ? 'N/A'
                : moment(res.data.resp.marketCloseDate.date).format('MM/DD/YYYY h:mm a');

        setAccountInfo(res.data.resp);
        setDowngradeReasons(res.data.resp.downgradeReasons);

        let tmpPrimary = [];
        let first = true;
        Object.keys(res.data.resp.downgradeReasons).forEach((key) => {
            tmpPrimary.push(key);
            if (first == true) {
                setSecondaryReasons(res.data.resp.downgradeReasons[key]);
                first = false;
            }
        });
        setPrimaryReasons(tmpPrimary);
    };

    let handlePrimaryReasonChange = async (value) => {
        setSecondaryReasons(downgradeReasons[value]);
    };

    let getOrders = async () => {
        let res = await api.getOrders(customerID);
        if (!res.data || !res.data.orders) {
            setNotification({ text: 'Failed to get order history', level: 'error' });
            return;
        }
        setOrders(res.data.orders.filter((o) => !o.drinksOnly));
    };

    let getDrinksOrders = async () => {
        let res = await api.getDrinksOrders(customerID);
        if (!res.status || !res.data || !res.data.drinksOrders) {
            setNotification({ text: 'Failed to get customer drinks orders history', level: 'error' });
            return;
        }
        setDrinksOrders(res.data.drinksOrders);
    };

    let getNotificationPreferences = async () => {
        let res = await api.getEmailNotificationPreferences(customerID);
        if (!res.status) {
            setNotification({ text: 'Failed to get email notification preferences', level: 'error' });
            return;
        }
        setPreferences(res.data.preferences);
    };

    let getCustomerCommunications = async () => {
        let res = await api.getCustomerCommunications(customerID);
        if (!res.status) {
            setNotification({ text: 'Failed to get customer communications', level: 'error' });
            return;
        }
        setCustomerComms(
            res.data.customerComms.map((row) => {
                row.sentOn = moment(row.sentOn).format('MM/DD/YYYY h:mm a');
                if (!!row.openedOn) {
                    row.openedOn = moment(row.openedOn).format('MM/DD/YYYY h:mm a');
                }
                return row;
            })
        );
    };

    let getCreditsBalance = async (subID) => {
        let res = await api.getCreditsBalance(subID);
        if (!res.status) {
            return;
        }
        if (res.data.allAvailableCredits) {
            res.data.allAvailableCredits.map((credit) => {
                credit.creditModel.amount =
                    credit.creditModel.amountSpent > 0
                        ? (credit.creditModel.amount - credit.creditModel.amountSpent).toFixed(2)
                        : credit.creditModel.amount.toFixed(2);
                credit.creditModel.amountChange =
                    credit.creditModel.amountChange != null ? credit.creditModel.amountChange.toFixed(2) : null;
                credit.creditModel.amountSpent = credit.creditModel.amountSpent.toFixed(2);
                credit.creditModel.expiresOn = moment(credit.creditModel.expiresOn.date).format('MM/DD/YYYY');
                return credit;
            });
        }

        setCreditsAvailable(res.data.allAvailableCredits);
        setNextBoxCredit(res.data.sumCreditBalance.toFixed(2));
    };

    let getGiftCodes = async (subID) => {
        let res = await api.getGiftCodes(subID);
        if (!res.status) {
            return;
        }

        if (res.data.rows) {
            res.data.rows.map((giftCode) => {
                giftCode.amount = giftCode.amount.toFixed(2);
                giftCode.purchasedOn = moment(giftCode.purchasedOn.date).format('MM/DD/YYYY');
                giftCode.expiresOn = moment(giftCode.expiresOn.date).format('MM/DD/YYYY');
                return giftCode;
            });
        }
        setGiftCodes(res.data.rows);
    };

    let getPromoCodes = async (subID) => {
        let res = await api.getAllPromoCodes(subID);
        if (!res.status) {
            return;
        }

        setPromoCodes(res.data.promos);
    };

    useEffect(() => {
        // get sub and account info
        getSubscriptionInformation();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {subscription?.status === 'CANCELLED' && (
                <Alert severity="error" variant="outlined">
                    <span style={{ fontWeight: '600' }}>This account has been cancelled</span>
                </Alert>
            )}

            <Grid container>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'left' }} className={classes.section}>
                        <span style={{ display: 'inline-block' }}>
                            <h2>Subscription Information</h2>
                        </span>
                        <span style={{ display: 'inline-block', fontSize: '20px', paddingLeft: '20px' }}>
                            {' '}
                            {subscription?.shippingAddr?.firstName} {subscription?.shippingAddr?.lastName}
                        </span>
                    </div>
                </Grid>
            </Grid>
            {!!subscription && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <EditAddress
                                classes={classes}
                                address={subscription.shippingAddr}
                                updateAddress={updateAddress}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <FormWithDisplay
                                formFields={[
                                    {
                                        name: 'expiresOn',
                                        inputProps: { readOnly: true },
                                        InputProps: { disableUnderline: true },
                                        label: 'Expires On',
                                        initialValue: `${
                                            subscription?.billing?.expMonth ? subscription?.billing?.expMonth : ''
                                        }/${subscription?.billing?.expYear ? subscription?.billing?.expYear : ''}`,
                                    },
                                    {
                                        name: 'zip',
                                        inputProps: { readOnly: true },
                                        InputProps: { disableUnderline: true },
                                        label: 'Billing Zip',
                                        initialValue: subscription?.billing?.zip ? subscription.billing.zip : '',
                                    },
                                    {
                                        name: 'card',
                                        inputProps: { readOnly: true },
                                        InputProps: { disableUnderline: true },
                                        label: 'Card',
                                        initialValue: subscription?.billing?.last4
                                            ? `* * * * * * * * * * * * ${subscription.billing.last4}`
                                            : '',
                                    },
                                    {
                                        name: 'type',
                                        inputProps: { readOnly: true },
                                        InputProps: { disableUnderline: true },
                                        label: 'Payment Method',
                                        initialValue: subscription?.billing?.type ? subscription.billing.type : '',
                                    },
                                ]}
                                title="Billing Information"
                                editable={false}
                            />
                            <Button
                                onClick={() => {
                                    if (window.confirm('Are you sure you want to remove this billing information?')) {
                                        removeBilling();
                                    }
                                }}
                                variant="outlined"
                            >
                                REMOVE BILLING?
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <EditEmail
                                classes={classes}
                                subscription={subscription}
                                updateEmail={updateEmail}
                                accountInfo={accountInfo}
                                updateTip={updateTip}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <CartExperience accountInfo={accountInfo} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <InputLabel className={classes.inputLabel}>Plan Information</InputLabel>
                            <div style={{ textAlign: 'left' }}>
                                <InputLabel className={classes.inputFormLabel} shrink={true}>
                                    Variant
                                </InputLabel>
                                <span>{subscription.variant === 'autoship' ? 'Autoship' : 'Flex Plan'}</span>
                            </div>
                            {subscription.variant === 'autoship' &&
                                (accountInfo?.planID === 1 || accountInfo?.planID === 2) && (
                                    <div style={{ textAlign: 'left' }}>
                                        <div>
                                            <InputLabel
                                                className={classes.inputFormLabel}
                                                style={{ paddingTop: '10px' }}
                                                shrink={true}
                                            >
                                                Plan Range
                                            </InputLabel>
                                            <span>{accountInfo.planID === 1 ? '$35 - $40' : '$40 - $45'}</span>
                                        </div>
                                    </div>
                                )}
                            {subscription.variant === 'autoship' &&
                                !!accountInfo?.planPreferences &&
                                accountInfo?.planPreferences?.hasOwnProperty('planSize') && (
                                    <div style={{ textAlign: 'left' }}>
                                        <div>
                                            <InputLabel
                                                className={classes.inputFormLabel}
                                                style={{ paddingTop: '10px' }}
                                                shrink={true}
                                            >
                                                Plan Range
                                            </InputLabel>
                                            <span>
                                                ${accountInfo.planPreferences.minBoxRange} - $
                                                {accountInfo.planPreferences.maxBoxRange}
                                            </span>
                                        </div>
                                        <div>
                                            <InputLabel
                                                className={classes.inputFormLabel}
                                                style={{ paddingTop: '10px' }}
                                                shrink={true}
                                            >
                                                Family Size
                                            </InputLabel>
                                            <span>{accountInfo.planPreferences.planSize}</span>
                                        </div>
                                        <div>
                                            <InputLabel
                                                className={classes.inputFormLabel}
                                                style={{ paddingTop: '10px' }}
                                                shrink={true}
                                            >
                                                Plans
                                            </InputLabel>
                                            <ul
                                                style={{
                                                    paddingTop: 0,
                                                    paddingLeft: '20px',
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {accountInfo.planPreferences.plans.map((plan) => {
                                                    let subPlanNames = [];
                                                    if (plan.subPlanIDs.length > 0) {
                                                        const v2Plan = v2Plans.find((item) => item.id === plan.id);
                                                        subPlanNames = plan.subPlanIDs.map((spID) => {
                                                            return v2Plan.subPlans.find((sp) => sp.id === spID)?.name;
                                                        });
                                                    }
                                                    return (
                                                        <li key={plan.id}>
                                                            {plan.name}{' '}
                                                            {subPlanNames.length > 0 && `(${subPlanNames.join(', ')})`}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            {subscription.variant === 'autoship' &&
                                accountInfo?.hasOwnProperty('planPreferences') &&
                                accountInfo.planPreferences?.hasOwnProperty('basePlan') && (
                                    <div style={{ textAlign: 'left' }}>
                                        <div>
                                            <InputLabel
                                                className={classes.inputFormLabel}
                                                style={{ paddingTop: '10px' }}
                                                shrink={true}
                                            >
                                                Plan Range
                                            </InputLabel>
                                            <span>
                                                ${accountInfo.planPreferences.minBoxRange} - $
                                                {accountInfo.planPreferences.maxBoxRange}
                                            </span>
                                        </div>
                                        <div>
                                            <InputLabel
                                                className={classes.inputFormLabel}
                                                style={{ paddingTop: '10px' }}
                                                shrink={true}
                                            >
                                                Base Plan
                                            </InputLabel>
                                            <span>{accountInfo.planPreferences.basePlan.name}</span>
                                        </div>
                                        <div>
                                            <InputLabel
                                                className={classes.inputFormLabel}
                                                style={{ paddingTop: '10px' }}
                                                shrink={true}
                                            >
                                                Base Plan Size
                                            </InputLabel>
                                            <span>{accountInfo.planPreferences.basePlan.size}</span>
                                        </div>
                                        {accountInfo.planPreferences.addOnPlans?.length > 0 && (
                                            <>
                                                <InputLabel
                                                    className={classes.inputFormLabel}
                                                    style={{ paddingTop: '10px' }}
                                                    shrink={true}
                                                >
                                                    Add On Plans
                                                </InputLabel>
                                                <ul
                                                    style={{
                                                        paddingTop: 0,
                                                        paddingLeft: '20px',
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    {accountInfo.planPreferences.addOnPlans.map((plan) => {
                                                        return <li key={plan.id}>{plan.name}</li>;
                                                    })}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                )}
                            <div style={{ textAlign: 'left', marginBottom: '20px' }}>
                                <InputLabel
                                    className={classes.inputFormLabel}
                                    style={{ paddingTop: '10px' }}
                                    shrink={true}
                                >
                                    Delivery Frequency
                                </InputLabel>
                                <span>{subscription.orderUnitInterval === 1 ? 'Weekly' : 'Every Other Week'}</span>
                            </div>
                            <Divider />
                            <UpdateDeliveryFrequency
                                handleRes={handleRes}
                                subscription={subscription}
                                classes={classes}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <InputLabel className={classes.inputLabel}>Convert Account</InputLabel>

                            {subscription.variant === 'autoship' ? (
                                <>
                                    <FormComponent
                                        inputElementStyle={{ padding: '0px' }}
                                        formControlStyle={{ padding: '0px' }}
                                        formFields={[
                                            {
                                                name: 'reason',
                                                label: 'Reason',
                                                inputElement: 'select',
                                                inputProps: {
                                                    opts: primaryReasons,
                                                    onChange: (event) => {
                                                        handlePrimaryReasonChange(event.target.value);
                                                    },
                                                },
                                            },
                                            {
                                                name: 'secondaryReason',
                                                label: 'Secondary Reason',
                                                inputElement: 'select',
                                                inputProps: { opts: secondaryReasons },
                                            },
                                        ]}
                                        button={{ text: 'Downgrade to Flex Plan' }}
                                        onSubmit={(formData) => {
                                            convertToMembership(formData.reason, formData.secondaryReason);
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        onClick={() => {
                                            convertToAutoship();
                                        }}
                                        variant="outlined"
                                    >
                                        Convert to autoship
                                    </Button>
                                </>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            {subscription.status === 'CANCELLED' ? (
                                <>
                                    <InputLabel className={classes.inputLabel}>Activate</InputLabel>
                                    <Button
                                        onClick={() => {
                                            activateSubscription();
                                        }}
                                        variant="outlined"
                                    >
                                        Activate Subscription
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <InputLabel className={classes.inputLabel}>Cancel</InputLabel>
                                    <Button
                                        onClick={() => {
                                            cancelSubscription();
                                        }}
                                        variant="outlined"
                                    >
                                        Cancel Subscription
                                    </Button>
                                </>
                            )}
                            <div style={{ marginTop: '50px' }}>
                                <InputLabel className={classes.inputLabel}>Cognito</InputLabel>
                                {!cognitoExists ? (
                                    <div>
                                        No Cognito Account.
                                        <br />
                                        Customer needs to go through
                                        <br />
                                        the password reset flow.
                                    </div>
                                ) : (
                                    <Button
                                        onClick={() => {
                                            generateTempPassword();
                                        }}
                                        variant="outlined"
                                    >
                                        Create Temp Password
                                    </Button>
                                )}
                                {cognitoTempPassword && (
                                    <p>
                                        <b>Temp Password:</b> {cognitoTempPassword}
                                    </p>
                                )}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <InputLabel className={classes.inputLabel}>
                                {subscription.status === 'PAUSED' ? 'Resume Subscription' : 'Pause Subscription'}
                            </InputLabel>
                            {subscription.status === 'PAUSED' ? (
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        resumeSubscription();
                                    }}
                                >
                                    Resume Subscription
                                </Button>
                            ) : (
                                <FormComponent
                                    inputElementStyle={{ padding: '0px' }}
                                    formControlStyle={{ padding: '0px' }}
                                    formFields={[
                                        {
                                            name: 'numMonths',
                                            label: 'Number of Months',
                                            inputElement: 'select',
                                            inputProps: {
                                                opts: [
                                                    { value: 1, text: '1 Month' },
                                                    { value: 2, text: '2 Months' },
                                                    { value: 3, text: '3 Months' },
                                                    { value: 4, text: '4 Months' },
                                                    { value: 5, text: '5 Months' },
                                                    { value: 6, text: '6 Months' },
                                                ],
                                            },
                                        },
                                    ]}
                                    button={{ text: 'Pause Subscription' }}
                                    onSubmit={(formData) => {
                                        pauseSubscription(formData.numMonths);
                                    }}
                                />
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <CreditsInformation
                                subscription={subscription}
                                classes={classes}
                                creditsAvailable={creditsAvailable}
                                nextBoxCredit={nextBoxCredit}
                                getCreditsBalance={getCreditsBalance}
                                removeCredit={removeCredit}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <ApplyCredit
                                setNotification={setNotification}
                                subscription={subscription}
                                classes={classes}
                                applyCredit={applyCredit}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <GiftCodesInformation
                                subscription={subscription}
                                classes={classes}
                                giftCodes={giftCodes}
                                getGiftCodes={getGiftCodes}
                                resendGiftCode={resendGiftCode}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <FormWithDisplay
                                editable={false}
                                title="Referrals"
                                formFields={[
                                    {
                                        name: 'vanityReferralCode',
                                        label: 'Code',
                                        initialValue: subscription?.vanityReferralCode
                                            ? subscription.vanityReferralCode
                                            : '',
                                    },
                                    {
                                        name: 'numReferralsMade',
                                        label: 'Number of Referrals',
                                        initialValue: subscription?.numReferralsMade
                                            ? subscription.numReferralsMade
                                            : 0,
                                    },
                                ]}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <InputLabel className={classes.inputLabel}>Preferred Delivery Day</InputLabel>
                            <Select
                                disabled={subscription.status === 'CANCELLED'}
                                className={classes.inputElement}
                                native
                                defaultValue={subscription.preferredDeliveryDay}
                                onChange={(event) => {
                                    updateSubscription({ prefDeliveryDay: event.target.value });
                                }}
                            >
                                {subscription.daysAvailable.map((item, i) => (
                                    <option disabled={item.full} key={i} value={item.deliveryDay.toLowerCase()}>
                                        {item.deliveryDay}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.card}>
                            <PromoCodeInformation
                                subscription={subscription}
                                classes={classes}
                                promoCodes={promoCodes}
                                getPromoCodes={getPromoCodes}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Notifications
                            classes={classes}
                            getNotificationPreferences={getNotificationPreferences}
                            preferences={preferences}
                            handleRes={handleRes}
                            customerID={customerID}
                        />
                    </Grid>

                    {donations && (
                        <Grid item xs={12} md={4}>
                            <Donations subscription={subscription} classes={classes} />
                        </Grid>
                    )}

                    <PlusMembership
                        customerID={customerID}
                        classes={classes}
                        membership={membership}
                        getMembershipSummary={getMembershipSummary}
                        setNotification={setNotification}
                    />

                    <Grid item xs={12}>
                        <div className={classes.card}>
                            <Orders
                                getSubscription={getSubscription}
                                setNotification={setNotification}
                                orders={orders}
                                getOrders={getOrders}
                                classes={classes}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.card}>
                            <DrinksOrders
                                customerID={customerID}
                                subscriptionID={subscription.id}
                                getSubscription={getSubscription}
                                setNotification={setNotification}
                                drinksOrders={drinksOrders}
                                getDrinksOrders={getDrinksOrders}
                                classes={classes}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.card}>
                            <Charges
                                subscription={subscription}
                                skipCharge={skipCharge}
                                unskipCharge={unskipCharge}
                                classes={classes}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.card}>
                            <InputLabel className={classes.inputLabel}>Changelog</InputLabel>

                            <DataTable
                                initialRowsPerPage={15}
                                data={changelog}
                                columns={[
                                    { Header: 'Timestamp EST', accessor: 'timestampEST' },
                                    { Header: 'Message', accessor: 'message' },
                                    { Header: 'User', accessor: 'user' },
                                ]}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.card}>
                            <InputLabel className={classes.inputLabel}>Communications To Customer</InputLabel>

                            <DataTable
                                initialRowsPerPage={15}
                                data={customerComms}
                                columns={[
                                    { Header: 'Subject Line', accessor: 'subjectLine' },
                                    { Header: 'Communication Type', accessor: 'communicationType' },
                                    { Header: 'Sent On', accessor: 'sentOn', sortType: 'datetimeString' },
                                    { Header: 'Opened On', accessor: 'openedOn', sortType: 'datetimeString' },
                                ]}
                                initialSortBy={[
                                    {
                                        id: 'sentOn',
                                        desc: true,
                                    },
                                ]}
                            />
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export { CustomerAccount };
