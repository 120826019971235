import React, { useState } from 'react';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import { Chip, Dialog, DialogContent } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { Select, Input } from '../shared';

/**
 *
 * Example filterableFields
 * [
 *     text: String, this is what displays in the "Add new filter" dropdown
 *     value: String, the filter key, should match the keys set in filters
 *     inputType: String, "text" or "select" or "date"
 *     options: array (if inputType is select, otherwise undefined)
 *              can be an array of strings or numbers,
 *              or of objects with keys "text" and "value"
 * ]
 */

const FiltersWithModal = ({ filterableFields, filters, setFilters }) => {
    const [filterModalOpen, setFilterModalOpen] = useState(false);

    if (!filterableFields || !filters) {
        return null;
    }

    return (
        <>
            <Dialog
                open={filterModalOpen}
                onClose={() => {
                    setFilterModalOpen(false);
                }}
                width="md"
                fullWidth={true}
            >
                <DialogContent>
                    <FilterModalContent
                        filterableFields={filterableFields}
                        filters={filters}
                        handleFilterChange={setFilters}
                    />
                </DialogContent>
            </Dialog>

            <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr' }}>
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setFilterModalOpen(true);
                    }}
                >
                    <span className="grotesk-bold">Filters</span>
                    &nbsp;&nbsp;
                    <TuneRoundedIcon className="partnerships-icon-30px" />
                </div>
                <div>
                    {Object.keys(filters).length > 0 && filterableFields.length > 0 && (
                        <div>
                            {Object.keys(filters).map((key) => {
                                const filterable = filterableFields.find((filter) => filter.value === key);
                                if (!filterable) {
                                    return null;
                                }

                                const selectedValue = filters[key];
                                let selectedValueDisplay =
                                    filterable?.options?.find((opt) => opt.value === selectedValue)?.text ||
                                    selectedValue;

                                let label = `${
                                    filterableFields.find((filter) => filter.value === key)?.text
                                }: ${selectedValueDisplay}`;

                                return (
                                    <Chip
                                        key={key}
                                        size="small"
                                        label={label}
                                        onDelete={async () => {
                                            let tmp = JSON.parse(JSON.stringify(filters));
                                            delete tmp[key];
                                            setFilters(tmp);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const FilterModalContent = ({ filterableFields = [], filters = {}, handleFilterChange }) => {
    const handleSaveFilter = (filterType, filterValue) => {
        handleFilterChange({ ...filters, [filterType]: filterValue });
    };

    const handleRemoveFilter = (key) => {
        let tmp = JSON.parse(JSON.stringify(filters));
        delete tmp[key];
        handleFilterChange(tmp);
    };

    return (
        <div className="filter-modal-container">
            {Object.keys(filters).length > 0 && (
                <div>
                    <div className="grotesk-regular small-header-text">Active Filters</div>
                    {Object.keys(filters).map((key) => {
                        const filterable = filterableFields.find((filter) => filter.value === key);
                        if (!filterable) {
                            return null;
                        }

                        const selectedValue = filters[key];
                        let selectedValueDisplay =
                            filterable?.options?.find((opt) => opt.value === selectedValue)?.text || selectedValue;

                        return (
                            <div key={key} className="filter-line-item">
                                <span className="grotesk-bold">{filterable.text}: </span>

                                <span className="grotesk-regular">{selectedValueDisplay}</span>

                                <ClearRoundedIcon
                                    className="lists-warning-icon"
                                    onClick={() => {
                                        handleRemoveFilter(key);
                                    }}
                                />
                            </div>
                        );
                    })}
                    <div className="curated-list-divider"></div>
                </div>
            )}
            <div className="grotesk-bold">Add New Filter</div>
            &nbsp;&nbsp;
            <FilterInput filterableFields={filterableFields} handleSaveFilter={handleSaveFilter} />
        </div>
    );
};

const FilterInput = ({ handleSaveFilter, filterableFields }) => {
    const [filterType, setFilterType] = useState('');
    const [filterValue, setFilterValue] = useState('');
    return (
        <div>
            <Select
                required={true}
                label="Filter"
                options={filterableFields}
                value={filterType}
                onChange={({ selectedItem }) => {
                    setFilterType(selectedItem.value);
                }}
            />
            {filterableFields.find((filter) => filter.value === filterType) && (
                <>
                    {filterableFields.find((filter) => filter.value === filterType).inputType === 'select' ? (
                        <Select
                            required={true}
                            label={filterableFields.find((filter) => filter.value === filterType).text}
                            options={filterableFields
                                .find((filter) => filter.value === filterType)
                                .options.map((item) => {
                                    if (item.hasOwnProperty('text') && item.hasOwnProperty('value')) {
                                        return item;
                                    } else {
                                        return { text: item, value: item };
                                    }
                                })}
                            value={filterValue}
                            onChange={({ selectedItem }) => {
                                setFilterValue(selectedItem.value);
                            }}
                        />
                    ) : (
                        <Input
                            required={true}
                            type={
                                filterableFields.find((filter) => filter.value === filterType).inputType === 'date'
                                    ? 'date'
                                    : undefined
                            }
                            label={filterableFields.find((filter) => filter.value === filterType).text}
                            value={filterValue}
                            onChange={(value) => {
                                setFilterValue(value);
                            }}
                        />
                    )}
                </>
            )}
            <button
                className="grotesk-bold fam-button fam-button-yellow"
                disabled={!filterType || !filterValue}
                onClick={() => {
                    handleSaveFilter(filterType, filterValue);
                    setFilterType('');
                    setFilterValue('');
                }}
            >
                Save Filter
            </button>
        </div>
    );
};

export { FiltersWithModal };
