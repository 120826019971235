import React, { useState, useEffect, useContext } from 'react';
import { Nav, PartnershipTabs } from '../../shared/index.js';
import { partnershipsApi } from '../../../api/partnerships.js';
import { UserContext } from '../../../contexts/UserContext.js';
import { ViewSubscriptions } from './components/viewSubscriptions.js';
import { UploadSubscriptions } from './components/uploadSubscriptions.js';
import { CreateSubscription } from './components/createSubscription.js';
import { SimulateSubscription } from './components/simulateSubscriptionOrders.js';
import { FailedSubscriptionOrders } from './components/failedSubscriptionOrdersTab.js';
import { SupplementalItems } from './components/supplementalItems.js';

const ManageSubscriptions = () => {
    const { famAccount, roles } = useContext(UserContext);
    const [allergenOptions, setAllergenOptions] = useState([]);
    const [adminEmailOptions, setAdminEmailOptions] = useState([]);
    const [dislikeOptions, setDislikeOptions] = useState([]);
    const [listOptions, setListOptions] = useState([]);

    useEffect(() => {
        const getLists = async () => {
            let res = await partnershipsApi.getSubscriptionOptions(famAccount.partnershipSource);
            if (!res.status) {
                return;
            }

            setListOptions(
                res.data?.lists?.map((item) => ({ text: item.name, value: item.id, facilities: item.facilities }))
            );

            setAllergenOptions(res.data?.allergens?.map((item) => ({ text: item, value: item })));

            setDislikeOptions(
                res.data?.taxonomy?.map((item) => ({ text: item.name, value: item.id, parentIDs: item.parentIDs }))
            );

            setAdminEmailOptions(res.data?.adminEmails?.map((item) => ({ text: item, value: item })));
        };

        getLists();
    }, []);

    const tabs = [
        {
            name: 'Subscriptions',
            component: (
                <ViewSubscriptions
                    listOptions={listOptions}
                    allergenOptions={allergenOptions}
                    adminEmailOptions={adminEmailOptions}
                    dislikeOptions={dislikeOptions}
                />
            ),
        },
        {
            name: 'Failed Orders',
            component: <FailedSubscriptionOrders listOptions={listOptions} />,
        },
        {
            name: 'Supplemental Items',
            component: <SupplementalItems taxonomyOptions={dislikeOptions} />,
        },
    ];

    if (!famAccount?.partnershipSource) {
        return (
            <div className="page-container">
                <Nav />
                <div className="content-container">
                    <div className="width-100">
                        <div className="fam-header-container">
                            <div className="fam-header-item-one">
                                <div className="grotesk-bold header-text">Please Select a Partner</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="page-container">
            <Nav />
            <div className="content-container">
                <div className="width-100">
                    <div className="fam-header-container">
                        <div className="fam-header-item-one">
                            <div className="grotesk-bold header-text">Manage Subscriptions</div>
                        </div>
                        <div className="fam-header-item-two">
                            <UploadSubscriptions />
                            <CreateSubscription
                                listOptions={listOptions}
                                allergenOptions={allergenOptions}
                                adminEmailOptions={adminEmailOptions}
                                dislikeOptions={dislikeOptions}
                            />
                            <SimulateSubscription
                                listOptions={listOptions}
                                allergenOptions={allergenOptions}
                                dislikeOptions={dislikeOptions}
                            />
                        </div>
                    </div>

                    <PartnershipTabs tabs={tabs} />
                </div>
            </div>
        </div>
    );
};

export { ManageSubscriptions };
