import React, { useState, useEffect, useContext, useRef } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { Input, Select, Combobox, MultiSelect, DropdownMultipleCombobox, FAMAlert } from '../../../shared';
import { partnershipsApi } from '../../../../api/partnerships.js';
import '../subscriptions.css';
import { UserContext } from '../../../../contexts/UserContext.js';

const SimulateSubscription = ({ listOptions, allergenOptions, dislikeOptions }) => {
    const { famAccount, roles } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const formEl = useRef(null);

    const [listID, setListID] = useState('');
    const [numOrders, setNumOrders] = useState(1);
    const [allergens, setAllergens] = useState([]);
    const [dislikes, setDislikes] = useState([]);
    const [packGroupID, setPackGroupID] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState('');

    let expandedListOptions = [];
    listOptions.forEach((opt) => {
        opt.facilities.forEach((fc) => {
            expandedListOptions.push({
                ...opt,
                text: `${opt.text} (${fc.name})`,
                facilityID: fc.id,
                facilityName: fc.name,
                packGroupID: fc.packGroupID,
            });
        });
    });

    const handleSubmit = async () => {
        setResults([]);
        setSubmitting(true);
        const data = {
            curatedListID: listID,
            numberOfOrders: numOrders,
            allergens: allergens,
            dislikedTaxonomyIDs: dislikes,
            packGroupID: packGroupID,
        };

        const res = await partnershipsApi.simulateSubscriptionOrders(famAccount.partnershipSource, data);
        if (!res.status) {
            setSubmitting(false);
            setError(res.msg);
            return;
        }

        setResults(res.data.data);
        setSubmitting(false);
    };

    return (
        <>
            <button
                onClick={() => {
                    setOpen(true);
                }}
                className="fam-button fam-button-yellow grotesk-bold"
            >
                Simulate Subscription Orders
            </button>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogContent>
                    <div>
                        <form ref={formEl}>
                            <FAMAlert severity="error" message={error} autohide={true} />
                            <div className="fam-create-sub-two-item">
                                <Combobox
                                    label="List"
                                    required={true}
                                    options={expandedListOptions}
                                    value={listID}
                                    onChange={({ selectedItem }) => {
                                        setPackGroupID(selectedItem.packGroupID);
                                        setListID(selectedItem.value);
                                    }}
                                />
                                <Input
                                    label="Number of Orders"
                                    required={true}
                                    value={numOrders}
                                    type="number"
                                    onChange={(value) => {
                                        setNumOrders(value);
                                    }}
                                />
                            </div>
                            <div className="fam-create-sub-two-item">
                                <MultiSelect
                                    label="Allergens"
                                    required={false}
                                    options={allergenOptions}
                                    value={allergens}
                                    onChange={({ selectedItems }) => {
                                        setAllergens(selectedItems.map((item) => item?.value));
                                    }}
                                />
                                <DropdownMultipleCombobox
                                    label="Item Dislikes"
                                    required={false}
                                    options={dislikeOptions.filter((item) => {
                                        let parentExcluded = false;
                                        item.parentIDs.forEach((id) => {
                                            if (dislikes.includes(id) && !dislikes.includes(item.value)) {
                                                parentExcluded = true;
                                            }
                                        });
                                        return !parentExcluded;
                                    })}
                                    value={dislikes}
                                    onChange={({ selectedItems, resetInputValue }) => {
                                        setDislikes(selectedItems.map((item) => item?.value));
                                        resetInputValue();
                                    }}
                                />
                            </div>
                        </form>
                        <div className="fam-create-sub-button-container">
                            <button
                                disabled={submitting}
                                type="submit"
                                className="fam-button fam-button-yellow grotesk-bold"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    {results.length > 0 && (
                        <div style={{ padding: '20px 0px' }}>
                            <div>
                                Results are based on the current week's product availability, in-stock rates, and
                                prices.
                            </div>
                            <div>
                                Inventory will change, and the exact items displayed here are not guaranteed at the time
                                orders are processed.
                            </div>
                        </div>
                    )}
                    {results.length > 0 &&
                        results.map((orderItems, index) => {
                            let totalPrice = 0;
                            orderItems.forEach((i) => {
                                totalPrice += i.price;
                            });
                            return (
                                <div>
                                    <strong>
                                        Order {index + 1}. Total Price: ${totalPrice.toFixed(2)}
                                    </strong>
                                    {orderItems.map((item) => {
                                        const textColor = !item.externalSku ? 'red' : 'inherit';
                                        return (
                                            <div style={{ color: textColor }}>
                                                Slot {item.slot} {!!item.externalSku && `, ${item.externalSku}`} -{' '}
                                                {item.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                </DialogContent>
            </Dialog>
        </>
    );
};

export { SimulateSubscription };
