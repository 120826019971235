import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent, SearchBar } from '../../../shared';
import {
    Modal,
    Button,
    NativeSelect,
    Grid,
    Dialog,
    DialogContent,
    CircularProgress,
    Tooltip,
    Box,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { addressesApi } from '../../../api/addresses';
import { logisticsApi } from '../../../api/logistics';
import { procurementApi as api } from '../../../api/procurement';
import { receivingApi } from '../../../api/receiving';
import { warehouseApi } from '../../../api/warehouse';
import { CreatePO } from './createPurchaseOrder';
import { UserContext } from '../../../contexts/UserContext';
import { catalogApi } from '../../../api/catalog';

const confirmMsg =
    'Are you sure you want to change the PO within 36 hrs of pickup? It will incur additional costs ranging from $350-900 per PO.';

const PO_PREVENT_VENDOR_SEND = 200_000;

// The table to show items
const ViewItemsTable = ({
    parentRow,
    setExpandedRow,
    receivingPOID = null,
    purchaseTypes,
    reasonCodes,
    showReasonCodeModal,
    internalTransfers = false,
    sourcingReasons,
    unpurchasedOutcomes,
    countries,
}) => {
    // Set PO ID and determine whether this is coming from the
    // receiving module or PO module
    let [poID, setPOID] = useState(null);
    let [fromReceiving, setFromReceiving] = useState(false);
    let [rows, setRows] = useState([]);
    let [columns, setColumns] = useState([]);
    let [addItemModalOpen, setAddItemModalOpen] = useState(false);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [itemMaster, setItemMaster] = useState([]);
    let [itemMasterOptions, setItemMasterOptions] = useState([]);
    let [externalSkuOptions, setExternalSkuOptions] = useState([]);
    let [loading, setLoading] = useState(false);
    let [purchaseOpts, setPurchaseOpts] = useState(purchaseTypes);
    let [srpEditable, setSrpEditable] = useState();
    let [rowID, setRowID] = useState(null);
    const [multiTruckPO, setMultiTruckPO] = useState(false);

    let getItemMaster = async () => {
        let itemMasterRes = await warehouseApi.getItemMaster(true, false);
        if (itemMasterRes.status === false) {
            return;
        } else if (!itemMasterRes.data || !itemMasterRes.data.rows) {
            setNotification({ text: 'Cant find any items', level: 'error' });
            return;
        } else {
            let items = itemMasterRes.data.rows.map((item) => {
                if (item.companySource !== 'MISFITS_MARKET') {
                    return {
                        text: `${item.companySource} ${item.thirdPartyItemID}: ${item.itemNameInternal}`,
                        value: item.sku,
                        externalSku: item.externalSku,
                    };
                }
                return {
                    text: `SKU ${item.sku}: ${item.itemNameInternal}`,
                    value: item.sku,
                    externalSku: item.externalSku,
                };
            });
            setItemMaster(itemMasterRes.data.rows);
            setItemMasterOptions(items);
        }
    };

    let getExternalSku = async () => {
        let result = await catalogApi.getItemInformation(true, 'itemMaster');

        if (result.status === false) return;

        setExternalSkuOptions(
            (result.data.items ?? []).map((item) => {
                const displayName = item.name ? item.name : 'NO NAME PROVIDED';
                return {
                    text: `SKU ${item.externalSku}: ${displayName}`,
                    value: item.externalSku,
                };
            })
        );
    };

    let configurePOID = () => {
        if (parentRow != null) {
            setPOID(parentRow.original.id);

            setColumns([
                { accessor: 'lotID', Header: 'Lot', hide: !internalTransfers },
                { accessor: 'sku', Header: 'Item Master SKU' },
                { accessor: 'externalSku', Header: 'External SKU' },
                { accessor: 'itemName', Header: 'Name' },
                { accessor: 'fobTruckID', Header: 'Truck ID', hide: !multiTruckPO },
                {
                    accessor: 'expectedExpirationDate',
                    hide: internalTransfers,
                    Header: 'Expected Expiration',
                    editable: true,
                    editProps: {
                        type: 'input',
                        inputType: 'date',
                        disableTextInput: true,
                        required: true,
                    },
                },
                {
                    accessor: 'expectedProductionDate',
                    hide: internalTransfers,
                    Header: 'Expected Production',
                    editable: true,
                    editProps: {
                        type: 'input',
                        inputType: 'date',
                        disableTextInput: true,
                        required: true,
                    },
                },
                {
                    accessor: 'qty',
                    Header: 'Case Quantity',
                    editable: true,
                    editProps: {
                        type: 'input',
                        inputType: 'number',
                        required: true,
                    },
                },
                { Header: 'Number of Pallets', accessor: 'numPallets', editable: true, hide: !internalTransfers },
                { accessor: 'purchasingCasePackSize', Header: 'Pack Size', hide: internalTransfers },
                { accessor: 'vendorSku', Header: 'Vendor SKU', editable: !internalTransfers },
                { accessor: 'csPallet', Header: 'Cases per pallet', editable: !internalTransfers },
                { accessor: 'totalUnits', Header: 'Total Units', editable: false },
                { accessor: 'unitPrice', Header: 'Case Price', editable: !internalTransfers },
                { accessor: 'totalCost', Header: 'Total Costs', editable: false },
                { accessor: 'week', Header: 'Week', editable: false },
                { accessor: 'year', Header: 'Year', editable: true },
                {
                    accessor: 'purchaseType',
                    Header: 'Purchase Type',
                    editable: !internalTransfers,
                    editProps: { type: 'select', options: purchaseOpts },
                },
                {
                    accessor: 'suggestedRetailPrice',
                    Header: 'SRP',
                    editable: srpEditable,
                    editProps: {
                        type: 'input',
                        inputType: 'number',
                        required: false,
                    },
                },
                {
                    accessor: 'unpurchasedOutcome',
                    Header: 'Where Would It Go?',
                    editable: true,
                    editProps: {
                        type: 'select',
                        options: unpurchasedOutcomes,
                    },
                },
                {
                    accessor: 'sourcingReasons',
                    Header: "Why I'm a Misfit",
                    editable: true,
                    editProps: {
                        type: 'autocomplete-multiple',
                        options: sourcingReasons,
                        multiple: true,
                    },
                },
                {
                    accessor: 'countryOfOriginId',
                    Header: 'Country of Origin',
                    editable: true,
                    editProps: { type: 'select', options: countries },
                },
                {
                    accessor: 'remove',
                    Header: 'Remove',
                    Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                        return (
                            <Button
                                id={`Remove_${row.id}`}
                                onClick={async () => {
                                    showReasonCodeModal(
                                        'purchaseOrderMap',
                                        row.original.id,
                                        { poID: poID, removeItem: true },
                                        getItemsInTable,
                                        parentRow.original.within36HoursOfPickup
                                    );
                                }}
                            >
                                Remove
                            </Button>
                        );
                    },
                },
            ]);
        } else {
            setPOID(receivingPOID);
            setFromReceiving(true);

            setColumns([
                { accessor: 'sku', Header: 'Item Master SKU' },
                { accessor: 'itemName', Header: 'Name' },
                { accessor: 'expectedExpirationDate', Header: 'Expected Expiration' },
                {
                    accessor: 'qty',
                    Header: 'Quantity',
                    editable: true,
                    editProps: {
                        type: 'input',
                        inputType: 'number',
                        required: true,
                    },
                },
                {
                    accessor: 'clone',
                    Header: 'Clone',
                    Cell: ({ cell: { value: initialValue }, row }) => {
                        return (
                            <Button
                                id={`Clone_${row.id}`}
                                onClick={async () => {
                                    let response = await api.cloneItemOnPO(poID, row.original.id);

                                    if (response.status === false) {
                                        setNotification({ text: "Can't clone item " + response.msg, level: 'error' });
                                        return;
                                    }

                                    getItemsInTable();
                                }}
                            >
                                Clone
                            </Button>
                        );
                    },
                },
            ]);
        }
    };

    useEffect(() => {
        if (parentRow === undefined || internalTransfers) {
            setSrpEditable(false);
        } else {
            setSrpEditable(parentRow.original.status !== 'CANCELLED');
        }

        if (parentRow?.original?.truckID === 'PARTIAL') {
            setMultiTruckPO(true);
        }
    }, []);

    useEffect(() => {
        if (!!parentRow?.original?.id) {
            if (!!rowID && parentRow.original.id !== rowID) {
                setExpandedRow(null);
            }
            setRowID(parentRow.original.id);
        }
    }, [parentRow?.original?.id]);

    useEffect(() => {
        configurePOID();
        getItemMaster();
        getExternalSku();
    }, [poID, fromReceiving]);

    let getItemsInTable = async () => {
        if (poID == null) {
            return;
        }

        if (!rows.length) {
            setLoading(true);
        }

        let response = await api.getPurchaseOrderDetails(poID);

        if (response.status === false) {
            setNotification({ text: 'No items found ' + response.msg, level: 'error' });
            setRows([]);
            setLoading(false);
            return;
        }

        let poDetails = response.data.output;

        // Need to add id which will populate in saveEdit
        setRows(
            poDetails.items.map((row) => {
                row.id = row.poMapID;
                row.unitPrice = parseFloat(row.unitPrice).toFixed(2);
                row.numPallets = Math.floor(row.qty / row.csPallet);
                if (row.purchaseType === null) {
                    row.purchaseType = '';
                }
                row.totalUnits = row.qty * row.baseUnitsPerCase;
                row.totalCost = parseFloat(row.qty * row.unitPrice).toFixed(2);
                return row;
            })
        );
        setLoading(false);
    };

    useEffect(() => {
        getItemsInTable();
    }, [poID]);

    return (
        <div>
            <Notifications options={notification} />
            {addItemModalOpen && (
                <>
                    {internalTransfers ? (
                        <AddLotsModal
                            parentRow={parentRow}
                            lotsAlreadyOnASN={rows}
                            poID={poID}
                            closeModal={() => {
                                setAddItemModalOpen(false);
                                getItemsInTable();
                            }}
                        />
                    ) : (
                        <AddItemModal
                            reasonCodes={reasonCodes}
                            parentRow={parentRow}
                            poID={poID}
                            itemMaster={itemMaster}
                            itemMasterOptions={itemMasterOptions}
                            purchaseTypes={purchaseTypes}
                            sourcingReasons={sourcingReasons}
                            unpurchasedOutcomes={unpurchasedOutcomes}
                            externalSkuOptions={externalSkuOptions}
                            countries={countries}
                            closeModal={() => {
                                setAddItemModalOpen(false);
                                getItemsInTable();
                            }}
                        />
                    )}
                </>
            )}
            <div>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <DataTable
                        columns={columns}
                        initialRowsPerPage={50}
                        data={rows}
                        editable={true}
                        saveEdit={async ({ id, field, value, row }) => {
                            let updates = { [field]: value, poID: poID };
                            if (field === 'qty' && !!showReasonCodeModal) {
                                return showReasonCodeModal(
                                    'purchaseOrderMap',
                                    id,
                                    updates,
                                    getItemsInTable,
                                    parentRow.original.within36HoursOfPickup
                                );
                            }
                            let response = await api.updatePurchaseOrderMap(poID, id, updates);
                            if (response.status === false) {
                                let message = 'Error modifying purchase order';
                                if (response) {
                                    message += ': ' + response.msg;
                                }
                                setNotification({ text: message, level: 'error' });
                            } else {
                                setNotification({ text: 'Purchase Order modified!', level: 'success' });
                            }
                            if (response.status === true && (field === 'sku' || field === 'numPallets')) {
                                getItemsInTable();
                            } else {
                                return response.status;
                            }
                        }}
                    />
                )}
            </div>
            {!fromReceiving && (
                <Button
                    onClick={() => {
                        setAddItemModalOpen(true);
                    }}
                >
                    Add Item
                </Button>
            )}
        </div>
    );
};

const AddLotsModal = ({ parentRow, poID, closeModal, lotsAlreadyOnASN }) => {
    const [error, setError] = useState('');
    const [lots, setLots] = useState([]);

    useEffect(() => {
        if (!!parentRow.original.originFacilityID) {
            getLotsAvailableToTransfer(parentRow.original.originFacilityID);
        }
    }, [parentRow.original.originFacilityID]);

    const getLotsAvailableToTransfer = async (facilityID) => {
        const response = await warehouseApi.getLotsAvailableForTransfer(facilityID);
        if (response.status === false) {
            setLots([]);
            return;
        }

        const data = response.data.rows
            .filter((row) => row.externalSku < 200000)
            .map((row) => {
                row.text = `Lot ${row.lotID} - Ext Sku ${row.externalSku} - ${row.itemNameInternal} - ${row.numAvailableCases} Cases - Exp ${row.expiresOn}`;
                row.value = row.lotID;
                return row;
            });

        setLots(data);
    };

    const itemFormFields = [
        {
            name: 'lotID',
            inputElement: 'autocomplete',
            label: 'Lot',
            gridValue: 12,
            inputProps: {
                required: true,
                opts: lots.filter((lot) => lot.companySource === parentRow.original.companySource),
            },
        },
        {
            name: 'numCases',
            inputElement: 'textField',
            label: 'Case Quantity',
            gridValue: 4,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'numPallets',
            inputElement: 'textField',
            label: 'Pallet Quantity',
            gridValue: 4,
            inputProps: { type: 'number', integer: true, required: true },
        },
        { name: 'week', inputElement: 'textField', label: 'Week number', gridValue: 4, inputProps: { required: true } },
    ];

    const addItemSubmit = async (formData) => {
        const lot = lots.find((item) => item.lotID == formData.lotID);
        if (!lot) {
            return false;
        }

        if (formData.numCases > lot.numAvailableCases) {
            setError(`Lot ${lot.lotID} only has ${lot.numAvailableCases} available cases for transfer`);
            return false;
        }

        if (lotsAlreadyOnASN.find((lotOnASN) => lotOnASN.lotID === lot.lotID)) {
            setError(`Lot ${lot.lotID} is already on this transfer ASN.`);
            return false;
        }

        if (lot.companySource !== parentRow.original.companySource) {
            setError(`Cannot add ${lot.companySource} item to ${parentRow.original.companySource} ASN`);
            return false;
        }

        const item = {
            lotID: lot.lotID,
            externalSku: lot.externalSku,
            itemMasterSku: lot.itemMasterSku,
            name: lot.itemNameInternal,
            numCases: formData.numCases,
            numPallets: formData.numPallets,
            expirationDate: lot.expiresOn,
            unitPrice: lot.unitPrice,
            suggestedRetailPrice: lot.suggestedRetailPrice,
            purchaseType: lot.purchaseType,
            vendorSku: lot.vendorSku,
            week: formData.week,
        };

        let response = await warehouseApi.addItemsToTransferASN(poID, [item]);
        if (response.status === false) {
            let message = 'Error adding item';
            if (response.msg) {
                message += ': ' + response.msg;
            }
            setError(message);
            return;
        }

        closeModal();
    };

    return (
        <Dialog open={true} onClose={closeModal}>
            <DialogContent>
                <FormComponent formFields={itemFormFields} onSubmit={addItemSubmit} />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </DialogContent>
        </Dialog>
    );
};

// The modal to add items to a purchase order
const AddItemModal = ({
    parentRow,
    poID,
    closeModal,
    itemMaster,
    itemMasterOptions,
    purchaseTypes,
    reasonCodes,
    sourcingReasons,
    unpurchasedOutcomes,
    externalSkuOptions,
    countries,
}) => {
    let [error, setError] = useState(null);
    let [hasPrintedExpDate, setHasPrintedExpDate] = useState(false);
    let [hasPrintedProdDate, setHasPrintedProdDate] = useState(false);
    let [purchaseOpts, setPurchaseOpts] = useState(purchaseTypes);
    const [schedDeliveryDate, setSchedDeliveryDate] = useState(parentRow.original.scheduledDeliveryDate);
    const [expProdDate, setExpProdDate] = useState();
    const [expExpireDate, setExpExpireDate] = useState();
    const [purchaseType, setPurchaseType] = useState();
    const [sourcingReason, setSourcingReason] = useState();
    const [unpurchasedOutcome, setUnpurchasedOutcome] = useState();
    const [currentItem, setCurrentItem] = useState();
    const [csPallet, setCsPallet] = useState();
    const [defaultValsLoaded, setDefaultValsLoaded] = useState(false);
    let schedDate = moment(schedDeliveryDate);

    let [addItemItemMasterOptions, setAddItemItemMasterOptions] = useState([]);
    let [disableExternalSkuAutoComplete, setDisableExternalSkuAutoComplete] = useState(false);

    useEffect(() => {
        setAddItemItemMasterOptions(itemMasterOptions);
    }, []);

    let handleCheckPrintedExpDate = async (event, value) => {
        if (!value) {
            setDisableExternalSkuAutoComplete(false);
            return;
        }

        let itemSku = value.value;
        let findItem = itemMaster.find((item) => item.sku === itemSku);
        setCurrentItem(findItem);

        if (findItem.expirationType == 'EXPIRATION_DATE') {
            setHasPrintedExpDate(true);
            if (
                schedDeliveryDate &&
                (purchaseType === 'EVERYDAY' || purchaseType === 'PRIVATE_BRAND' || purchaseType === 'SEASONAL')
            ) {
                setExpExpireDate(schedDate.add(findItem.vendorGuaranteedDaysUponDelivery, 'days').format('YYYY-MM-DD'));
            }
        } else {
            setHasPrintedExpDate(false);
        }

        if (findItem.expirationType == 'PRODUCTION_DATE') {
            setHasPrintedProdDate(true);
            setExpProdDate(
                schedDate
                    .add(findItem.vendorGuaranteedDaysUponDelivery, 'days')
                    .subtract(findItem.expireLifeDays, 'days')
                    .format('YYYY-MM-DD')
            );
        } else {
            setHasPrintedProdDate(false);
        }

        setDefaultValsLoaded(false);
        setCsPallet(findItem.csPallet);
    };

    useEffect(() => {
        if (
            hasPrintedExpDate &&
            (purchaseType === 'EVERYDAY' || purchaseType === 'PRIVATE_BRAND' || purchaseType === 'SEASONAL')
        ) {
            setExpExpireDate(schedDate.add(currentItem.vendorGuaranteedDaysUponDelivery, 'days').format('YYYY-MM-DD'));
        } else {
            setExpExpireDate();
        }
    }, [purchaseType]);

    let handleExternalSkuChange = async (event, value) => {
        if (!value) {
            setAddItemItemMasterOptions(itemMasterOptions);
            return;
        }

        setAddItemItemMasterOptions(
            itemMasterOptions.filter((itemMasterOption) => {
                return itemMasterOption.externalSku === value.value;
            })
        );
    };

    let handleItemFormChange = (formData) => {
        if (!formData.externalSku && formData.sku) {
            let foundItemMaster = itemMaster.find((item) => item.sku === formData.sku.value);

            formData.externalSku = externalSkuOptions.find((item) => {
                return item.value === foundItemMaster.externalSku;
            });

            setAddItemItemMasterOptions(
                itemMasterOptions.filter((itemMasterOption) => {
                    return itemMasterOption.externalSku === foundItemMaster.externalSku;
                })
            );
        }

        if (formData.externalSku && formData.sku) {
            setDisableExternalSkuAutoComplete(true);

            // Load some default values
            if (defaultValsLoaded == false) {
                formData.csPallet = csPallet;
                setDefaultValsLoaded(true);
            }
        }
    };

    let formFields = [
        {
            name: 'externalSku',
            inputElement: 'autocomplete',
            label: 'External SKU (filter)',
            gridValue: 8,
            inputProps: {
                required: false,
                disabled: disableExternalSkuAutoComplete,
                opts: externalSkuOptions,
                onChange: handleExternalSkuChange,
            },
        },
        {
            name: 'sku',
            inputElement: 'autocomplete',
            label: 'Item Master',
            gridValue: 8,
            inputProps: { required: true, opts: addItemItemMasterOptions, onChange: handleCheckPrintedExpDate },
        },
        {
            name: 'qty',
            inputElement: 'textField',
            label: 'Quantity (cases)',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'unitPrice',
            inputElement: 'textField',
            label: 'Case Price',
            gridValue: 2,
            inputProps: { required: true },
        },
        { name: 'vendorSku', inputElement: 'textField', label: 'Vendor SKU', gridValue: 4 },
        {
            name: 'csPallet',
            inputElement: 'textField',
            label: 'Cases per pallet',
            gridValue: 2,
            inputProps: { required: true },
        },
        { name: 'year', inputElement: 'textField', label: 'Year', gridValue: 2 },
        {
            name: 'purchaseType',
            inputElement: 'select',
            label: 'Type',
            gridValue: 4,
            inputProps: {
                opts: purchaseOpts,
                required: true,
                onChange: (e) => setPurchaseType(e.target.value),
            },
        },
        { name: 'suggestedRetailPrice', inputElement: 'textField', label: 'SRP', gridValue: 2 },
        {
            name: 'expectedExpirationDate',
            inputElement: 'date',
            disableTextInput: true,
            label: 'Expected Expiration Date',
            gridValue: 4,
            dependencies: ['sku'],
            inputProps: {
                required: hasPrintedExpDate ? true : false,
                disabled: hasPrintedExpDate ? false : true,
                style: hasPrintedExpDate ? {} : { background: 'lightgrey', opacity: '50%' },
                onChange: (e) => setExpExpireDate(e.target.value),
            },
            value: hasPrintedExpDate ? expExpireDate : undefined,
        },
        {
            name: 'expectedProductionDate',
            inputElement: 'date',
            disableTextInput: true,
            label: 'Expected Production Date',
            gridValue: 4,
            dependencies: ['sku'],
            inputProps: {
                required: hasPrintedProdDate ? true : false,
                disabled: hasPrintedProdDate ? false : true,
                style: hasPrintedProdDate ? {} : { background: 'lightgrey', opacity: '50%' },
                onChange: (e) => setExpProdDate(e.target.value),
            },
            value: hasPrintedProdDate ? expProdDate : undefined,
        },
        {
            name: 'reasonCode',
            label: 'Reason',
            gridValue: 4,
            inputElement: 'select',
            inputProps: { required: true, opts: reasonCodes.filter((item) => item !== 'Carrier Related') },
        },
        {
            name: 'sourcingReasons',
            inputElement: 'autocomplete',
            label: "Why I'm a Misfit",
            gridValue: 4,
            inputProps: {
                multiple: true,
                opts: sourcingReasons,
                required: false,
                onChange: (e) => setSourcingReason(e.target.value),
            },
        },
        {
            name: 'unpurchasedOutcome',
            inputElement: 'select',
            label: 'Where Would It Go',
            gridValue: 4,
            inputProps: {
                opts: unpurchasedOutcomes,
                required: false,
                onChange: (e) => setUnpurchasedOutcome(e.target.value),
            },
        },
        {
            name: 'countryOfOriginId',
            inputElement: 'select',
            label: 'Country of Origin',
            gridValue: 4,
            inputProps: {
                opts: countries,
                required: false,
            },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onFormDataChange={handleItemFormChange}
                    onSubmit={async (formData) => {
                        formData.expectedProductionDate = expProdDate;
                        formData.expectedExpirationDate = expExpireDate;

                        formData.sourcingReasons = formData.sourcingReasons.map((i) => i.value);

                        let items = {};
                        items['items'] = [formData];

                        let confirmed = true;
                        if (parentRow.original.within36HoursOfPickup) {
                            confirmed = window.confirm(confirmMsg);
                        }
                        if (confirmed) {
                            let response = await api.addItemToPurchaseOrder(poID, items);
                            if (response.status === false) {
                                let message = 'Error adding item';
                                if (response.msg) {
                                    message += ': ' + response.msg;
                                }
                                setError('Error updating ' + message);
                                return;
                            }

                            closeModal();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const EditDeliveryModal = ({ rowData, closeModal, getPurchaseOrders, reasonCodes }) => {
    let [error, setError] = useState(null);

    let date = moment(rowData.scheduledDeliveryDate).format('YYYY-MM-DD');
    let time = moment(rowData.scheduledDeliveryDate).format('HH:mm');

    let formFields = [
        {
            name: 'poID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'Purchase Order ID',
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'scheduledDeliveryDate',
            initialValue: date,
            inputElement: 'date',
            disableTextInput: true,
            label: 'Scheduled Delivery Date',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'scheduledTime',
            initialValue: time,
            inputElement: 'time',
            label: 'Delivery time',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'reasonCode',
            label: 'Reason',
            gridValue: 3,
            inputElement: 'select',
            inputProps: { required: true, opts: reasonCodes },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['scheduledDeliveryDate'] = formData.scheduledDeliveryDate;
                        item['deliverTime'] = formData.scheduledTime;
                        item['reasonCode'] = formData.reasonCode;

                        let response = await api.updatePurchaseOrder(formData.poID, item);
                        if (response.status === false) {
                            let message = 'Error modifying purchase order';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                        } else {
                            closeModal();
                            getPurchaseOrders();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const EditPickupDateModal = ({ rowData, reasonCodes, closeModal, getPurchaseOrders }) => {
    let [error, setError] = useState(null);

    const formFields = [
        {
            name: 'poID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'Purchase Order ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'pickupDate',
            inputElement: 'date',
            disableTextInput: false,
            label: 'Pick Up Date',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'reasonCode',
            label: 'Reason',
            gridValue: 4,
            inputElement: 'select',
            inputProps: { required: true, opts: reasonCodes },
        },
    ]

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '600px', margin: '10% auto', padding: '10px' }}>
                <FormComponent 
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['pickupDate'] = formData.pickupDate;
                        item['reasonCode'] = formData.reasonCode;

                        let response = await api.updatePurchaseOrder(formData.poID, item);
                        if (response.status === false) {
                            let message = 'Error modifying purchase order';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                        } else {
                            closeModal();
                            getPurchaseOrders();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    )
}

const EditSpecialInstructionsModal = ({ rowData, closeModal }) => {
    let [error, setError] = useState(null);

    let specialInstructions = rowData.specialInstructions;

    let formFields = [
        {
            name: 'poID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'Purchase Order ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'specialInstructions',
            initialValue: specialInstructions,
            inputElement: 'textField',
            label: 'Special Instructions',
            gridValue: 8,
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['specialInstructions'] = formData.specialInstructions;

                        let response = await api.updatePurchaseOrder(formData.poID, item);
                        if (response.status === false) {
                            let message = 'Error modifying purchase order';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                        } else {
                            closeModal();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const ReasonCodeModal = ({
    reasonCodes,
    reasonCodeOptions: { type, id, updates, onSuccess, within36HoursOfPickup },
    closeModal,
    getPurchaseOrders,
    internalTransfers = false,
}) => {
    let [error, setError] = useState(null);

    let opts = reasonCodes;
    if (updates.removeItem) {
        opts = reasonCodes.filter((item) => item !== 'Carrier Related');
    }

    let formFields = [
        {
            name: 'reasonCode',
            label: 'Reason',
            gridValue: 12,
            inputElement: 'select',
            inputProps: { required: true, opts: opts },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <h4>Select Reason {updates.cancelPO === true && `to Cancel ${internalTransfers ? 'PO' : 'ASN'}`}</h4>
                <FormComponent
                    formFields={formFields}
                    button={{ text: 'Confirm Reason' }}
                    onSubmit={async (formData) => {
                        let reasonCode = formData.reasonCode;
                        if (type === 'purchaseOrder') {
                            if (updates.cancelPO === true) {
                                let response = await api.cancelPurchaseOrder(id, reasonCode);
                                if (response.status === false) {
                                    setError('Failed to cancel PO ' + response.msg);
                                    return false;
                                } else {
                                    getPurchaseOrders();
                                    closeModal();
                                }
                            } else {
                                updates.reasonCode = reasonCode;
                                let confirmed = true;
                                if (
                                    (updates.hasOwnProperty('pickupDate') || updates.hasOwnProperty('deliveryType')) &&
                                    within36HoursOfPickup
                                ) {
                                    confirmed = window.confirm(confirmMsg);
                                }

                                if (confirmed) {
                                    let response = await api.updatePurchaseOrder(id, updates);
                                    if (response.status === false) {
                                        let message = 'Error modifying purchase order';
                                        if (response) {
                                            message += ': ' + response.msg;
                                        }
                                        setError(message);
                                        return false;
                                    } else {
                                        getPurchaseOrders();
                                        closeModal();
                                    }
                                }
                            }
                        } else if (type === 'purchaseOrderMap') {
                            let confirmed = true;
                            if (within36HoursOfPickup) {
                                confirmed = window.confirm(confirmMsg);
                            }
                            if (updates.removeItem === true) {
                                if (confirmed) {
                                    let response = await api.removeItemFromPO(updates.poID, id, reasonCode);

                                    if (response.status === false) {
                                        setError("Can't remove item " + response.msg);
                                        return false;
                                    } else {
                                        getPurchaseOrders();
                                        closeModal();
                                    }
                                }
                            } else if (updates.hasOwnProperty('qty')) {
                                // update purchase order item
                                let response = await api.updatePurchaseOrderMap(updates.poID, id, {
                                    qty: updates.qty,
                                    reasonCode,
                                });
                                if (response.status === false) {
                                    let message = 'Error modifying purchase order';
                                    if (response) {
                                        message += ': ' + response.msg;
                                    }
                                    setError(message);
                                    return false;
                                } else {
                                    getPurchaseOrders();
                                    closeModal();
                                }
                            }
                        }

                        if (onSuccess && onSuccess instanceof Function) {
                            onSuccess();
                        }
                        return true;
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

// The main PO view
let ViewPO = ({ internalTransfers = false }) => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [editDeliveryModalOpen, setEditDeliveryModalOpen] = useState(false);
    let [editSpecialInstructionsModalOpen, setEditSpecialInstructionsModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});
    let [vendors, setVendors] = useState([]);
    let [shippers, setShippers] = useState([]);
    let [crossDocks, setCrossDocks] = useState([]);
    let [facilities, setFacilities] = useState([]);
    let [buyers, setBuyers] = useState([]);
    let [loading, setLoading] = useState(false);
    let [purchaseTypes, setPurchaseTypes] = useState([]);
    let [poLookup, setPoLookup] = useState(null);
    let [searchObj, setSearchObj] = useState({});
    let [reasonCodes, setReasonCodes] = useState([]);
    let [reasonCodeModalOpen, setReasonCodeModalOpen] = useState(false);
    let [reasonCodeOptions, setReasonCodeOptions] = useState({});
    const [receivingASN, setReceivingASN] = useState(false);
    const [sourcingReasons, setSourcingReasons] = useState([]);
    const [unpurchasedOutcomes, setUnpurchasedOutcomes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [deptLeadCheck, setDeptLeadCheck] = useState(false);
    const [pickupDateModalOpen, setPickupDateModalOpen] = useState(false);

    const user = useContext(UserContext);

    // Load countries
    let getCountries = async () => {
        let response = await addressesApi.getCountries();
        if (!response.status) {
            setNotification({ text: 'Failed to get countries', level: 'error' });
            return;
        }

        let countriesAsOpts = response.data.rows.map((row) => {
            row.text = `${row.name} (${row.countryCode})`;
            row.value = row.id;
            return row;
        });

        setCountries(countriesAsOpts);
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (response.status === false) {
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        facilitiesAsOpts.unshift({ text: 'Please Select', value: 0 });

        setFacilities(facilitiesAsOpts);
    };

    let getBuyers = async () => {
        let response = await api.getBuyers();
        if (response.status === false) {
            setNotification({ text: 'Failed to get buyers: ' + response.msg, level: 'error' });
            setBuyers([]);
            return;
        }

        let buyerOpts = response.data.rows.map((row) => {
            return { text: row.firstName + ' ' + row.lastName, value: row.id };
        });
        setBuyers(buyerOpts);
    };

    let getItemTypes = async () => {
        let res = await api.getPurchaseOrderItemTypes();
        if (res.status === false) {
            return;
        }

        setPurchaseTypes(res.data.types);
    };

    let getReasonCodes = async () => {
        let res = await api.getPOUpdateReasonCodes();
        if (res.status === false) {
            return;
        }

        setReasonCodes(res.data.reasonCodes);
    };

    const getSourcingReasons = async () => {
        const resp = await api.getSourcingReasons();
        if (resp.status) {
            setSourcingReasons(resp.data.reasons.map((r) => r.name));
        }
    };

    const getUnpurchasedOutcomes = async () => {
        const resp = await api.getUnpurchasedOutcomes();
        if (resp.status) {
            setUnpurchasedOutcomes(resp.data.outcomes.map((o) => o.displayName));
        }
    };

    const processPORow = (row) => {
        let now = moment();
        row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY');
        row.pickupDate = moment(row?.pickupDate?.date).format('MM/DD/YYYY');
        row.scheduledDeliveryDate = moment(row.scheduledDeliveryDate.date).format('MM/DD/YYYY HH:mm:ss');
        if (row.crossDockDeliveryDate) {
            row.crossDockDeliveryDate = moment(row.crossDockDeliveryDate.date).format('YYYY-MM-DD');
        }
        row.buyersWithNames = row.buyers;
        row.buyers = row.buyers.map((item) => item.authUserID);
        let pickupDiff = moment.duration(moment(row.pickupDate).diff(now));
        row.within36HoursOfPickup =
            pickupDiff.asHours() <= 36 && row.status === 'PLACED' && row.deliveryType === 'PICK UP';
        return row;
    };

    const markPurchaseOrderAsSentToVendor = (row) => {
        setRows(
            rows.map((r) => {
                if (r.id === row.original.id) {
                    const dt = moment().format('MMM Do, YYYY [at] HH:mm:ss A');
                    r.sentToVendor = dt;
                }

                return r;
            })
        );
    };

    // Load purchase orders
    let getPurchaseOrders = async () => {
        if (!rows.length) {
            setLoading(true);
        }
        let facilityID = user.getFacilityID();
        if (searchObj.hasOwnProperty('facilityID')) {
            facilityID = searchObj.facilityID;
        }

        let externalSku = searchObj.hasOwnProperty('externalSku') ? searchObj.externalSku : null;
        let poID = searchObj.hasOwnProperty('poID') ? searchObj.poID : null;

        let response;
        if (internalTransfers) {
            response = await warehouseApi.getInternalTransfers(externalSku, poID);
        } else {
            const ALL_FACILITIES = 0;
            response = await api.getPurchaseOrders(ALL_FACILITIES, externalSku, poID);
        }

        if (response.status === false) {
            setNotification({ text: "Can't access POs: " + response.msg, level: 'error' });
            setLoading(false);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map(processPORow);
        }

        let data = response.data.rows.map((row) => ({
            ...row,
            canUserSelectBulkAction:
                response.data.deptLeadCheck ||
                (!response.data.deptLeadCheck && row.poTotalCost <= PO_PREVENT_VENDOR_SEND),
        }));

        // filter out duplicate PO ID records
        // that are on more than one fob truck
        data = data.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

        setRows(data);
        setDeptLeadCheck(response.data.deptLeadCheck);
        setLoading(false);
    };

    useEffect(() => {
        getPurchaseOrders();
    }, [searchObj]);

    useEffect(() => {
        getFacilities();
        getVendors();
        getShippers();
        getBuyers();
        getItemTypes();
        getReasonCodes();
        getSourcingReasons();
        getUnpurchasedOutcomes();
        getCountries();
        getCrossDocks();
    }, []);

    // Load vendors
    let getVendors = async () => {
        let response = await api.getVendors();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setVendors([]);
            return;
        }

        let vendorAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setVendors([pleaseSelect].concat(vendorAsOpts));
    };

    // Load shippers
    let getShippers = async () => {
        let response = await api.getShippers();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setShippers([]);
            return;
        }

        let shippersAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: '' };
        setShippers([pleaseSelect].concat(shippersAsOpts));
    };

    let getCrossDocks = async () => {
        let response = await logisticsApi.getInboundCrossDocks(true);
        if (response.status === false) {
            setNotification({ text: 'Failed to get cross docks', level: 'error' });
            setCrossDocks([]);
            return;
        }

        let crossDocksAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: '' };
        setCrossDocks([pleaseSelect].concat(crossDocksAsOpts));
    };

    let showDeliveryEditModal = (row) => {
        setSelectedRow(row);
        setEditDeliveryModalOpen(true);
    };

    let showSpecialInstructionsEditModal = (row) => {
        setSelectedRow(row);
        setEditSpecialInstructionsModalOpen(true);
    };

    let showReasonCodeModal = (type, id, updates, onSuccess, within36HoursOfPickup) => {
        setReasonCodeOptions({ type, id, updates, onSuccess, within36HoursOfPickup });
        setReasonCodeModalOpen(true);
    };

    let showEditPickupDateModal = row => {
        setSelectedRow(row);
        setPickupDateModalOpen(true);
    }

    const receiveImperfectInternalTransfer = async (poID) => {
        setReceivingASN(true);
        let res = await receivingApi.receiveTransferASNAtImperfectFacility(poID);
        if (!res.status) {
            setNotification({ text: res.msg, level: 'Error' });
        }
        setNotification({ text: 'ASN Received', level: 'success' });
        getPurchaseOrders();
        setReceivingASN(false);
    };

    // CHANGE HAS TO BE HERE
    let imperfectFacilityIds = [9, 10, 11, 12, 13];
    let columns = [
        {
            Header: 'Receive',
            hide: !(imperfectFacilityIds.includes(user.getFacilityID()) && internalTransfers),
            Cell: ({ row }) => {
                if (
                    imperfectFacilityIds.includes(row.original.destinationFacilityID) &&
                    (row.original.status === 'PLACED' || row.original.status === 'OPEN') &&
                    row.original.receivableAtIFWithoutQC &&
                    row.original.destinationFacilityID === user.getFacilityID()
                ) {
                    return (
                        <Button
                            disabled={receivingASN}
                            onClick={() => {
                                receiveImperfectInternalTransfer(row.original.id);
                            }}
                        >
                            Receive
                        </Button>
                    );
                } else {
                    return null;
                }
            },
        },
        { accessor: 'id', Header: internalTransfers ? 'ASN #' : 'PO #' },
        { accessor: 'createdAt', Header: 'Created On' },
        { accessor: 'status', Header: 'Status', filterType: 'multiSelect' },
        { accessor: 'truckID', Header: 'Truck ID' },
        {
            accessor: 'deliveryType',
            Header: 'Delivery Type',
            hide: internalTransfers,
            editable: true,
            editProps: {
                type: 'select',
                options: [
                    { value: '', text: 'Please Select' },
                    { value: 'PICK UP', text: 'PICK UP' },
                    { value: 'DELIVERED', text: 'DELIVERED' },
                ],
            },
        },
        {
            accessor: 'pickupNumber',
            Header: 'Pick Up #',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'text',
            },
        },
        {
            accessor: 'pickupDate',
            Header: 'Pick Up Date',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'date',
                required: false,
                disableTextInput: true,
            },
            customEdit: showEditPickupDateModal,
        },
        {
            accessor: 'scheduledDeliveryDate',
            Header: 'Scheduled Delivery',
            editable: true,
            customEdit: showDeliveryEditModal,
        },
        {
            accessor: 'vendorName',
            Header: 'Vendor',
            hide: internalTransfers,
            editable: true,
            editProps: {
                type: 'select',
                options: vendors,
            },
        },
        {
            accessor: 'shipperID',
            Header: 'Shipper',
            hide: internalTransfers,
            editable: true,
            editProps: {
                type: 'select',
                options: shippers,
                required: false,
            },
        },
        {
            accessor: 'crossDockID',
            Header: 'Cross Dock',
            editable: true,
            editProps: {
                type: 'select',
                options: crossDocks,
                required: false,
            },
        },
        {
            accessor: 'crossDockDeliveryDate',
            Header: 'Cross Dock Delivery Date',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'date',
                disableTextInput: true,
                required: false,
            },
        },
        {
            accessor: 'facilityName',
            Header: 'Facility',
            editable: true,
            hide: internalTransfers,
            editProps: {
                type: 'select',
                options: facilities,
            },
        },
        {
            accessor: 'originFacilityID',
            Header: 'Origin Facility',
            editable: false,
            hide: !internalTransfers,
            editProps: {
                type: 'select',
                options: facilities,
            },
        },
        {
            accessor: 'destinationFacilityID',
            Header: 'Destination Facility',
            editable: false,
            hide: !internalTransfers,
            editProps: {
                type: 'select',
                options: facilities,
            },
        },
        { accessor: 'poTotalCost', Header: 'Total Cost' },
        {
            accessor: 'buyers',
            Header: internalTransfers ? 'Procurement Stakeholders' : 'Buyers',
            editable: true,
            filterValues: (row) => row.original.buyersWithNames.map((item) => item.name),
            editProps: {
                type: 'autocomplete-multiple',
                options: buyers,
                multiple: true,
            },
        },
        {
            accessor: 'minTempReq',
            Header: 'Min Temperature',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                integer: true,
            },
        },
        {
            accessor: 'maxTempReq',
            Header: 'Max Temperature',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                integer: true,
            },
        },
        {
            accessor: 'specialInstructions',
            Header: 'Special Instructions',
            editable: true,
            customEdit: showSpecialInstructionsEditModal,
            width: '150px',
        },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row }) => {
                const [downloadOpt, setDownloadOpt] = useState('pdf');
                const downloadOptions = ['csv', 'pdf', 'ship-list'];
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <NativeSelect
                            id={`Download_Select_${row.id}`}
                            className="input-element-select"
                            value={downloadOpt}
                            label="Download"
                            onChange={(event) => {
                                setDownloadOpt(event.target.value);
                            }}
                        >
                            {downloadOptions.map((value, i) => {
                                return (
                                    <option key={i} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                            ;
                        </NativeSelect>
                        <Button
                            id={`Download_${row.id}`}
                            onClick={() => {
                                if (downloadOpt === 'csv') {
                                    api.downloadPOCSV(row.original.id, row.original.vendorName);
                                } else if (downloadOpt === 'pdf') {
                                    api.downloadPOPDF(
                                        row.original.id,
                                        row.original.vendorName,
                                        row.original.facilityName
                                    );
                                } else if (downloadOpt === 'ship-list') {
                                    api.downloadPOShipList(row.original.id);
                                } else {
                                    console.log('Unrecognized download option ' + downloadOpt);
                                }
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'cancel',
            Header: 'Cancel',
            Cell: ({ cell: { value: initialValue }, row }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Cancel_${row.id}`}
                            onClick={async () => {
                                showReasonCodeModal(
                                    'purchaseOrder',
                                    row.original.id,
                                    { cancelPO: true },
                                    null,
                                    row.original.within36HoursOfPickup
                                );
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'sendToVendor',
            Header: 'Send To Vendor',
            hide: internalTransfers,
            Cell: ({ cell: { value: initialValue }, row }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Send_${row.id}`}
                            disabled={!deptLeadCheck && row.original.poTotalCost >= PO_PREVENT_VENDOR_SEND}
                            onClick={async () => {
                                let response = await api.sendPurchaseOrderToVendor(row.original.id);
                                if (response.status === false) {
                                    setNotification({ text: 'Failed to send PO ' + response.msg, level: 'error' });
                                    return;
                                }
                                markPurchaseOrderAsSentToVendor(row);
                                setNotification({ text: 'Sent PO to Vendor', level: 'success' });
                            }}
                        >
                            SEND
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'sentToVendor',
            Header: 'Sent',
            Cell: ({ cell: { value: initialValue } }) => {
                const dt = moment(initialValue).format('MMM Do, YYYY [at] HH:mm:ss A');

                return (
                    <Box display="flex" flex="1" justifyContent="start" alignItems="center">
                        <Box>{initialValue ? 'Yes' : 'No'}</Box>

                        {initialValue && (
                            <Tooltip title={'Sent: ' + dt}>
                                <InfoOutlined style={{ cursor: 'pointer', marginLeft: '.675rem' }} />
                            </Tooltip>
                        )}
                    </Box>
                );
            },
        },
        { accessor: 'priority', Header: 'Priority', type: 'checkbox', editable: true, editProps: { type: 'checkbox' } },
        {
            accessor: 'estimatedFreightCost',
            Header: 'Freight Cost',
            editable: (row) => row.original.deliveryType == 'DELIVERED',
            editProps: { type: 'input', inputType: 'number' },
        },
        { accessor: 'produceOrGrocery', Header: 'Produce/Grocery' },
    ];

    let searchPOs = async ({ searchBy, searchTerm }) => {
        if (searchBy === 'poID') {
            // find this PO, if in other facility, allow easy switch
            let poID = searchTerm;
            setPoLookup(null);

            let res = await api.getPurchaseOrderDetails(poID);
            if (!res.status) {
                setNotification({ text: 'Failed to lookup po: ' + res.msg, level: 'error' });
                return;
            }

            let facilityID = res.data?.output?.facilityID;
            if (!facilityID) {
                setNotification({ text: `PO ${poID} not found`, level: 'error' });
                return;
            }
            let facilityName = null;
            if (facilityID) {
                facilityName = facilities.find((item) => item.value === facilityID)?.text;
            }
            // If the facilityID was not found in facilities, that means it's old/inactive
            if (!facilityName) {
                setNotification({ text: `PO ${poID} found in an inactive facility`, level: 'error' });
                return;
            }
            setPoLookup({ poID, facilityID, facilityName });
            if (user.getFacilityClusterIds().includes(facilityID)) {
                setSearchObj({ poID });
            }
        } else if (searchBy === 'externalSku') {
            setPoLookup({});
            setSearchObj({ externalSku: searchTerm });
        }
    };

    let searchByOptions = [{ value: 'externalSku', text: 'External Sku' }];
    if (!internalTransfers) {
        searchByOptions.unshift({ value: 'poID', text: 'PO Number' });
    }

    let bulkActions = [
        {
            name: 'Send Emails to Vendors',
            action: async (orderIDs) => {
                let promises = orderIDs.map((id) => api.sendPurchaseOrderToVendor(id));
                let res = await Promise.all(promises);
                if (!res.every((item) => item.status)) {
                    setNotification({ text: 'Issue sending the selected POs to vendors. ' + res.msg, level: 'error' });
                    return;
                }
                setNotification({ text: 'Sent PO(s) to Vendor(s)', level: 'success' });
            },
        },
        {
            name: 'Download pre-populated csv',
            action: async (orderIDs) => {
                let obj = {};
                obj.poIDs = orderIDs;
                let res = await api.downloadPrePopulatedCsv(obj);
                if (!res.status) {
                    setNotification({ text: 'Failed download csv file: ' + res.msg, level: 'error' });
                    return;
                }
            },
        },
    ];

    return (
        <div>
            <h1>{internalTransfers ? 'Transfer ASNS' : 'Purchase Orders'}</h1>
            <Notifications options={notification} />
            <div>
                {editDeliveryModalOpen && (
                    <EditDeliveryModal
                        rowData={selectedRow}
                        getPurchaseOrders={getPurchaseOrders}
                        closeModal={() => {
                            setEditDeliveryModalOpen(false);
                        }}
                        reasonCodes={reasonCodes}
                    />
                )}
                {reasonCodeModalOpen && (
                    <ReasonCodeModal
                        reasonCodes={reasonCodes}
                        reasonCodeOptions={reasonCodeOptions}
                        getPurchaseOrders={getPurchaseOrders}
                        internalTransfers={internalTransfers}
                        closeModal={() => {
                            setReasonCodeModalOpen(false);
                            setReasonCodeOptions({});
                        }}
                    />
                )}
                {pickupDateModalOpen && (
                    <EditPickupDateModal 
                        rowData={selectedRow}
                        reasonCodes={reasonCodes}
                        closeModal={() => {
                            setPickupDateModalOpen(false);
                            getPurchaseOrders();
                        }}
                    />
                )}
                {editSpecialInstructionsModalOpen && (
                    <EditSpecialInstructionsModal
                        rowData={selectedRow}
                        closeModal={() => {
                            setEditSpecialInstructionsModalOpen(false);
                            getPurchaseOrders();
                        }}
                    />
                )}
                <div>
                    <div style={{ margin: '0 auto' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <SearchBar
                                    searchByOptions={searchByOptions}
                                    onSubmit={searchPOs}
                                    onClearSearch={() => {
                                        setPoLookup({});
                                        setSearchObj({});
                                    }}
                                />
                            </Grid>
                            {poLookup && poLookup.poID && poLookup.facilityID && (
                                <>
                                    <Grid item xs={6}>
                                        <div>
                                            PO {poLookup.poID} found in facility {poLookup.facilityName}
                                        </div>
                                    </Grid>

                                    <Grid item xs={6}>
                                        {!user.getFacilityClusterIds().includes(poLookup.facilityID) && (
                                            <Button
                                                variant="outlined"
                                                onClick={async () => {
                                                    user.setFacilityIDAndName({
                                                        facilityID: poLookup.facilityID,
                                                        facilityName: poLookup.facilityName,
                                                    });
                                                    setSearchObj({
                                                        facilityID: poLookup.facilityID,
                                                        poID: poLookup.poID,
                                                    });
                                                }}
                                            >
                                                Change Facility
                                            </Button>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </div>
                </div>
                <div>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <DataTable
                            selectMultiple={true}
                            columns={columns}
                            data={rows}
                            expandable={true}
                            bulkActions={bulkActions}
                            tableContainerMaxHeight={800}
                            stickyHeader={true}
                            initialRowsPerPage={10}
                            ExpansionComponent={
                                <ViewItemsTable
                                    reasonCodes={reasonCodes}
                                    showReasonCodeModal={showReasonCodeModal}
                                    purchaseTypes={purchaseTypes}
                                    internalTransfers={internalTransfers}
                                    sourcingReasons={sourcingReasons}
                                    unpurchasedOutcomes={unpurchasedOutcomes}
                                    countries={countries}
                                />
                            }
                            editable={true}
                            saveEdit={async ({ id, field, value, row }) => {
                                let item = {};
                                item[field] = value;
                                if (field === 'vendorName') {
                                    item['vendorID'] = value;
                                } else if (field === 'shipperID') {
                                    item['inboundShipperID'] = value;
                                } else if (field === 'facilityName') {
                                    item['facilityID'] = value;
                                }

                                if (['pickupDate', 'shipperID', 'deliveryType'].includes(field)) {
                                    showReasonCodeModal('purchaseOrder', id, item, null, row.within36HoursOfPickup);
                                    return;
                                }

                                let response = await api.updatePurchaseOrder(id, item);
                                if (response.status === false) {
                                    let message = 'Error modifying purchase order';
                                    if (response) {
                                        message += ': ' + response.msg;
                                    }
                                    setNotification({ text: message, level: 'error' });
                                } else {
                                    setNotification({ text: 'Purchase Order modified!', level: 'success' });
                                    await getPurchaseOrders();
                                }

                                return response.status;
                            }}
                            duplicateRowSettings={{
                                enable: !internalTransfers,
                                CustomFormComponent: <CreatePO />,
                                fetchAdditionalData: async (row) => {
                                    let response = await api.getPurchaseOrderDetails(row.id);
                                    if (!response.status) {
                                        setNotification({
                                            text: 'Failed to get items in purchase order',
                                            level: 'error',
                                        });
                                        return [];
                                    }
                                    let poDetails = response.data.output.items.map((row) => {
                                        row.id = row.poMapID;
                                        row.unitPrice = parseFloat(row.unitPrice).toFixed(2);
                                        return row;
                                    });
                                    return poDetails;
                                },
                                onSuccess: () => {
                                    getPurchaseOrders();
                                    setNotification({ text: 'Purchase Order(s) Created', level: 'success' });
                                },
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export { ViewPO, ViewItemsTable };
