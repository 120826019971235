import React, { useState, useEffect, useContext } from 'react';
import { productionApi as api } from '../../../api/production.js';
import { DataTable, FormComponent, Notifications, TabPanel } from '../../../shared';
import { Dialog, DialogTitle, DialogContent, Tabs, Tab, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { UserContext } from '../../../contexts/UserContext';

let SellItemCell = ({ row, column }) => {
    let green = '#ecfdf0';
    let red = '#fbe9eb';
    let prevValue = row.original.prevValue;
    let newValue = row.original.amount;
    if (!!prevValue && prevValue !== newValue) {
        return (
            <div>
                <div style={{ backgroundColor: red }}>
                    <s>{prevValue}</s>
                </div>
                <div style={{ backgroundColor: green }}>{newValue}</div>
            </div>
        );
    }
    if (!prevValue) {
        return (
            <div>
                <div style={{ backgroundColor: green }}>{newValue}</div>
            </div>
        );
    }

    return null;
};

let UploadModal = ({ packGroup, closeModal, getData, setNotification }) => {
    let [uploadResults, setUploadResults] = useState(null);
    let [confirming, setConfirming] = useState(false);
    let [toolbarActions, setToolbarActions] = useState([]);
    let [errorMessage, setErrorMessage] = useState('');

    let formFields = [
        { name: 'file', label: 'Sellable Item File', gridValue: 12, inputElement: 'file', accept: ['.csv'] },
    ];
    let columns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'Sellable Item ID', accessor: 'sellableItemID' },
        { Header: 'External sku', accessor: 'externalSku' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Amount', id: 'amountLeft', Cell: SellItemCell },
        {
            Header: 'Buffer?',
            accessor: 'bufferOption',
            Cell: ({ row }) => {
                const bufferOption = row.original.bufferOption;
                return bufferOption === true ? 'Yes' : 'No';
            },
        },
        {
            Header: 'Algorithm?',
            accessor: 'algorithmBufferOption',
            Cell: ({ row }) => {
                const algorithmBufferOption = row.original.algorithmBufferOption;
                return algorithmBufferOption === true ? 'Yes' : 'No';
            },
        },
        { Header: 'Buffer Override', accessor: 'bufferOverride' },
        { Header: 'Tier', accessor: 'tier' },
        { Header: 'Errors', accessor: 'errors' },
    ];

    let confirmUpload = async (key) => {
        setConfirming(true);
        if (!key) {
            setNotification({ text: 'No key provided for sellable item inventory upload', level: 'error' });
            return;
        }

        let res = await api.setSellableItemInventoryAmounts(key);
        setConfirming(false);
        if (!res.status) {
            setNotification({ text: 'Failed to update sellable item amounts', level: 'error' });
            return;
        }

        setNotification({ text: 'Sellable items updated!', level: 'success' });
        setUploadResults(null);
        getData({});
        closeModal();
    };

    let uploadSellableItemInventory = async (formData) => {
        setErrorMessage('');
        let res = await api.uploadSellableItemTotalInventoryCSV({ file: formData.file, packGroupID: packGroup.id });
        if (!res.status) {
            setNotification({
                text: 'Failed to get results from sellable item inventory upload. ' + res.msg,
                level: 'error',
            });
            return;
        }

        let data = res.data;
        let results = res.data.results.map((row) => {
            row.errors = row.errors.join('. ');
            return row;
        });
        data.results = results;

        let actions = [
            {
                name: 'Clear File',
                action: () => {
                    setToolbarActions([]);
                    setUploadResults(null);
                },
            },
        ];

        if (!data.error && data.results.length > 0) {
            actions.push({
                name: 'Confirm Changes',
                action: () => {
                    let key = data.key;
                    confirmUpload(key);
                },
                disabled: confirming,
            });
        }

        if (!!data.error) {
            setErrorMessage('There are errors in the uploaded file. Please review and upload corrected csv.');
        }
        if (!data.results.length) {
            setErrorMessage('No changes found in file');
        }

        setUploadResults(data);
        setToolbarActions(actions);
    };

    return (
        <Dialog open={true} onClose={closeModal} fullWidth={true} maxWidth="lg">
            <DialogTitle>Edit Sellable Items - Pack Group {packGroup.name}</DialogTitle>
            <DialogContent style={{ paddingTop: '0px' }}>
                {!uploadResults && (
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData) => {
                            await uploadSellableItemInventory(formData);
                        }}
                    />
                )}
                {!!uploadResults && (
                    <>
                        {!!errorMessage ? (
                            <Alert severity="warning">{errorMessage}</Alert>
                        ) : (
                            <Alert severity="success">Please review and confirm changes.</Alert>
                        )}
                        <DataTable
                            columns={columns}
                            toolbarActions={toolbarActions}
                            data={uploadResults.results}
                            rowStyle={(row) => {
                                if (row.errors.length) {
                                    return { color: 'red', border: '3px solid red' };
                                }
                                return {};
                            }}
                        />
                    </>
                )}
                <br />
                <br />
            </DialogContent>
        </Dialog>
    );
};

let SellableItemInventory = function () {
    let [year, setYear] = useState('');
    let [week, setWeek] = useState('');
    let [selectedTab, setSelectedTab] = useState('');
    let [packGroups, setPackGroups] = useState([]);
    let [upcomingWeek, setUpcomingWeek] = useState(false);
    let [uploadModalOpen, setUploadModalOpen] = useState(false);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [loading, setLoading] = useState(false);

    let user = useContext(UserContext);

    let getData = async ({ newWeek, newYear, packGroupID }) => {
        setLoading(true);
        let existingPackGroups = packGroups;
        let packGroupsRes = await api.getPackGroups({
            facilityID: user.getFacilityID(),
            week: newWeek || week,
            year: newYear || year,
        });

        setWeek(packGroupsRes.data.week);
        setYear(packGroupsRes.data.year);
        setUpcomingWeek(packGroupsRes.data.upcomingWeek);

        let packGroupsArr = [];
        for (let group of packGroupsRes.data.packGroups) {
            if (packGroupID && packGroupID !== group.id) {
                packGroupsArr.push(existingPackGroups.find((gr) => gr.id === group.id));
                continue;
            }

            let sellableItems = await api.getSellableItemsTotalInventory(group.id);
            if (sellableItems.status === false) {
                group.sellableItems = [];
            } else {
                group.sellableItems = sellableItems.data.sellableItems;
            }
            packGroupsArr.push(group);
        }

        setPackGroups(packGroupsArr);
        if (!selectedTab) {
            setSelectedTab(packGroupsArr[0] ? '0' : '');
        }
        setLoading(false);
    };

    useEffect(() => {
        getData({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let formFields = [
        {
            name: 'week',
            initialValue: week,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { required: true, type: 'number', max: '53' },
        },
        {
            name: 'year',
            initialValue: year,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { required: true, type: 'number', maxLength: '4' },
        },
    ];

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <div>
            <Notifications options={notification} />
            {uploadModalOpen === true && (
                <UploadModal
                    packGroup={packGroups[selectedTab]}
                    closeModal={() => {
                        setUploadModalOpen(false);
                    }}
                    getData={getData}
                    setNotification={setNotification}
                />
            )}
            <h2>
                Setup Sellable Items - Week {week}, {year}
            </h2>
            {week && year && (
                <FormComponent
                    formFields={formFields}
                    button={{ gridValue: 2, text: 'Change Week/Year' }}
                    onSubmit={async (formData) => {
                        getData({ newWeek: formData.week, newYear: formData.year });
                    }}
                />
            )}
            <br />
            <br />
            {loading ? (
                <CircularProgress />
            ) : (
                <div>
                    {packGroups.length > 0 && selectedTab ? (
                        <div>
                            <Tabs
                                onChange={handleChange}
                                value={selectedTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                {packGroups.map((group, index) => (
                                    <Tab key={group.id} label={`Pack Group ${group.name}`} value={`${index}`} />
                                ))}
                            </Tabs>
                            {packGroups.map((group, index) => {
                                let sellItems = group.sellableItems;
                                return (
                                    <TabPanel selectedTab={selectedTab} key={group.id} index={`${index}`}>
                                        <div>
                                            {sellItems.length > 0 ? (
                                                <SellableItemsTable
                                                    packGroup={group}
                                                    week={week}
                                                    year={year}
                                                    getData={getData}
                                                    setUploadModalOpen={setUploadModalOpen}
                                                    sellableItemsData={sellItems}
                                                    upcomingWeek={upcomingWeek}
                                                    setNotification={setNotification}
                                                />
                                            ) : (
                                                <div>No sellable items found for this pack group.</div>
                                            )}
                                        </div>
                                    </TabPanel>
                                );
                            })}
                        </div>
                    ) : (
                        <div>No sellable item data found for this week.</div>
                    )}
                </div>
            )}
        </div>
    );
};

let SellableItemsTable = function ({
    sellableItemsData,
    setUploadModalOpen,
    setNotification,
    week,
    year,
    getData,
    packGroup,
}) {
    let [tableData, setTableData] = useState([]);

    let toolbarActions = [];
    toolbarActions = [
        {
            name: 'Download CSV',
            action: async () => {
                await api.downloadSellableItemTotalInventoryCSV(packGroup.id);
            },
        },
        {
            name: 'Upload CSV',
            action: () => {
                setUploadModalOpen(true);
            },
        },
    ];

    useEffect(() => {
        setTableData(sellableItemsData);
    }, [sellableItemsData]);

    let columns = [
        { accessor: 'id', Header: 'Sellable Item ID' },
        { accessor: 'externalSku', Header: 'External Sku' },
        { accessor: 'name', Header: 'Name' },
        {
            accessor: 'amountLeft',
            Header: 'Total Inventory',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                integer: true,
                min: '14',
                required: true,
            },
        },
        {
            accessor: 'inventoryBuffer',
            Header: 'Buffer',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                step: 0.01,
                min: 1,
                required: true,
            },
        },
    ];

    let onSaveEdit = async ({ id, field, value, row }) => {
        let res = await api.updateSellableItem(row.id, field, value);
        if (res.status !== true) {
            let msg = `Error updating sellable item ${res.msg ? `. ${res.msg}` : ''}`;
            setNotification({ text: msg, level: 'error' });
        }
        return res.status;
    };

    return (
        <div>
            <DataTable
                key={`${week}${year}`}
                columns={columns}
                data={tableData}
                editable={true}
                toolbarActions={toolbarActions}
                saveEdit={onSaveEdit}
            />
        </div>
    );
};

export { SellableItemInventory };
