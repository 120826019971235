import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { inboundTruckApi as api } from '../../../api/inboundTrucks';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Modal,
    CircularProgress,
} from '@material-ui/core';
import { DefaultCell } from '../../../shared/components/dataTable/components/tableCells';
import moment from 'moment';
import { UserContext } from '../../../contexts/UserContext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const ScheduledTruckTableCell = ({ time, trucks, door, openTruckDetailsModal }) => {
    let trucksAtTime = trucks.filter((truck) => {
        return truck.scheduledDeliveryDetails.startingHour === time && truck.dockDoorKey == door;
    });

    if (!trucksAtTime.length) {
        return null;
    }

    const getBackgroundColor = (truck) => {
        if (truck.status === 'CANCELLED') {
            return '#da9199';
        }
        if (truck.isTruckOnTime == 'No') {
            return '#ffc766';
        }
        if (truck.oosSkus.length > 0) {
            // Trucks with priority POs should be yellow/orange color
            return '#ffe208';
        }

        if (truck.isFBM == 'Yes') {
            return '#19a7ce';
        }

        if (truck.packagingPoIDs.length && !truck.poIDs.length) {
            // Trucks that only have packaging POs should be brown/tan color
            return '#dabfae';
        } else if (!truck.packagingPoIDs.length && truck.poIDs.length) {
            return '#b5f0b3';
        }

        //Mixed packaging and food
        return '#c3b9e6';
    };

    return (
        <div style={{ width: '100%', padding: '5px' }}>
            {trucksAtTime.map((truck) => {
                return (
                    <div
                        onClick={() => openTruckDetailsModal(truck)}
                        key={truck.id}
                        style={{
                            cursor: 'pointer',
                            width: `${100 / trucksAtTime.length}%`,
                            display: 'inline-block',
                        }}
                    >
                        <div
                            style={{
                                margin: '2px',
                                backgroundColor: getBackgroundColor(truck),
                                borderRadius: '4px',
                                padding: '5px',
                                overflow: 'wrap',
                            }}
                        >
                            {truck.status === 'CANCELLED' ? (
                                <div style={{ fontWeight: 'bold', textAlign: 'center' }}>CANCELLED</div>
                            ) : (
                                <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{truck.deliveryType}</div>
                            )}
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Truck ID:</span> {truck.id}
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}># POs:</span>{' '}
                                {truck.poIDs.length + truck.packagingPoIDs.length}
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}># Lines:</span> {truck.numItems}
                            </div>
                            {truck.poIDs?.length > 0 && (
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>POs:</span> {truck.poIDs.join(', ')}
                                </div>
                            )}
                            {truck.packagingPoIDs?.length > 0 && (
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Packaging POs:</span>{' '}
                                    {truck.packagingPoIDs?.join(', ')}
                                </div>
                            )}
                            {truck.numPallets > 0 && (
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Pallets:</span> {truck.numPallets}
                                </div>
                            )}
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Carrier:</span> {truck.inboundCarrierName}
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>FBM:</span> {truck.isFBM}
                            </div>
                            {truck.oosSkus.length > 0 && (
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>OOS Skus</span>
                                    {truck.oosSkus.map((item) => {
                                        return (
                                            <div key={item.sku}>
                                                Slot: {item.slot}, Sku: {item.sku} - {item.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {truck.notes && (
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Notes:</span> {truck.notes}
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const TruckDetailsModal = ({ selectedTruck, onClose, open, setNotification, getTrucks }) => {
    const { user } = useContext(UserContext);
    let [editDeliveryModalOpen, setEditDeliveryModalOpen] = useState(false);
    let [flag, setFlag] = useState('');
    let [selectedRow, setSelectedRow] = useState({});
    let [truck, setTruck] = useState(selectedTruck);

    useEffect(() => {
        setTruck(selectedTruck);
    }, [selectedTruck]);

    let showTimeEditModal = (row, field) => {
        setSelectedRow(row);
        setFlag(field);
        setEditDeliveryModalOpen(true);
    };

    let setEditedTruck = (row) => {
        row.scheduledDelivery = !!row.scheduledDeliveryTime
            ? moment(row.scheduledDeliveryTime.date).format('MM/DD/YYYY hh:mm a')
            : null;
        row.checkIn = !!row.checkInTime ? moment(row.checkInTime.date).format('MM/DD/YYYY hh:mm a') : null;
        row.checkOut = !!row.checkOutTime ? moment(row.checkOutTime.date).format('MM/DD/YYYY hh:mm a') : null;
        row.dockIn = !!row.dockInTime ? moment(row.dockInTime.date).format('MM/DD/YYYY hh:mm a') : null;
        row.unloadStart = !!row.unloadStartTime ? moment(row.unloadStartTime.date).format('MM/DD/YYYY hh:mm a') : null;
        row.unloadEnd = !!row.unloadEndTime ? moment(row.unloadEndTime.date).format('MM/DD/YYYY hh:mm a') : null;

        row.scheduledDeliveryDetails = {
            date: !!row.scheduledDeliveryTime ? moment(row.scheduledDeliveryTime.date).format('MM/DD/YYYY') : null,
            startingHour: !!row.scheduledDeliveryTime
                ? moment(row.scheduledDeliveryTime.date).startOf('hour').format('hh:mm a')
                : null,
            time: !!row.scheduledDeliveryTime ? moment(row.scheduledDeliveryTime.date).format('hh:mm a') : null,
        };

        row.multiStopTruck = row.multiStopTruck ? 'yes' : 'no';
        row.scheduled = row.unscheduled ? 'no' : 'yes';

        const twoHoursInMs = 2 * (60 * 60 * 1000);
        const scheduledDeliveryTimeAsDate =
            !!row.scheduledDeliveryTime && row.scheduledDeliveryTime.date
                ? new Date(row.scheduledDeliveryTime.date)
                : new Date();
        const timeDifference = new Date() - scheduledDeliveryTimeAsDate;
        row.isTruckOnTime = !!row.checkInTime || timeDifference < twoHoursInMs || timeDifference < 0 ? 'Yes' : 'No';

        row.isFBM = row.fbmPartner != null && row.fbmPartner.trim() != '' ? 'Yes' : 'No';
        setTruck(row);
    };

    const EditDateModal = ({ rowData, closeModal, flag }) => {
        let [error, setError] = useState(null);
        let [date, setDate] = useState(null);
        let [time, setTime] = useState(null);
        let [loading, setLoading] = useState(false);

        let formFields = [
            {
                name: 'truckID',
                initialValue: rowData.id,
                inputElement: 'textField',
                label: 'Truck ID',
                gridValue: 4,
                inputProps: { readOnly: true },
            },
            {
                name: 'date',
                initialValue: date,
                inputElement: 'date',
                label: 'Date',
                gridValue: 4,
                inputProps: { required: true },
            },
            {
                name: 'time',
                initialValue: time,
                inputElement: 'time',
                label: 'Time',
                gridValue: 4,
                inputProps: { required: true },
            },
            {
                name: 'bolCompliant',
                inputElement: 'checkbox',
                label: 'BOL Compliant',
                gridValue: 4,
                display: flag === 'checkIn'
            }
        ];

        useEffect(() => {
            setDate(moment(rowData[flag]).format('YYYY-MM-DD'));
            setTime(moment(rowData[flag]).format('HH:mm'));
        }, []);

        return (
            <Modal open={true} onClose={async () => await closeModal()}>
                <div
                    style={{
                        outline: 0,
                        backgroundColor: 'white',
                        width: '700px',
                        margin: '10% auto',
                        padding: '10px',
                    }}
                >
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <FormComponent
                            formFields={formFields}
                            onSubmit={async (formData) => {
                                setLoading(true);
                                let item = {};

                                item[`${flag}Date`] = formData.date;
                                item[`${flag}Time`] = formData.time;

                                if(!!formData.bolCompliant) {
                                    item['bolCompliant'] = formData.bolCompliant;
                                }

                                let response = await api.editTruckFacilityStopDetails(rowData.facilityStopID, item);
                                if (response.status === false) {
                                    let message = 'Error modifying truck';
                                    if (response) {
                                        message += ': ' + response.msg;
                                    }
                                    setError('Error updating ' + message);
                                } else {
                                    setEditedTruck(response.data.editedTruck);
                                    closeModal();
                                }
                                setLoading(false);
                            }}
                        />
                    )}
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>
            </Modal>
        );
    };

    let columns = [
        {
            accessor: 'dockDoor',
            Header: 'Dock Door',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        { accessor: 'multiStopTruck', Header: 'Multi Stop Truck', editable: false },
        { accessor: 'scheduled', Header: 'Scheduled?', editable: false },
        { accessor: 'deliveryType', Header: 'DeliveryType' },
        { accessor: 'referenceNumber', Header: 'Ship Reference #' },
        { accessor: 'scheduledDelivery', Header: 'Scheduled Delivery', editable: false },
        { accessor: 'inboundCarrierName', Header: 'Carrier', editable: false },
        { accessor: 'type', Header: 'Type', editable: false },
        { accessor: 'minTemperature', Header: 'Min Temperature', editable: false },
        { accessor: 'maxTemperature', Header: 'Max Temperature', editable: false },
        { accessor: 'numPallets', Header: 'Num Pallets' },
        {
            accessor: 'checkIn',
            Header: 'Check In Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'checkIn'),
        },
        {
            accessor: 'dockIn',
            Header: 'Dock In Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'dockIn'),
        },
        {
            accessor: 'unloadStart',
            Header: 'Unload Start Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'unloadStart'),
        },
        {
            accessor: 'unloadEnd',
            Header: 'Unload End Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'unloadEnd'),
        },
        {
            accessor: 'checkOut',
            Header: 'Check Out Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'checkOut'),
        },
        { 
            accessor: 'bolCompliant', 
            Header: 'BOL Compliant', 
            type: 'checkbox',
        },
        { accessor: 'notes', Header: 'Notes', editable: false },
        { accessor: 'equipmentType', Header: 'EquipmentType' },
        { accessor: 'status', Header: 'Truck Status' },
        { accessor: 'fbmPartner', Header: 'FBM' },
    ];
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={() => {
                onClose();
            }}
        >
            {editDeliveryModalOpen && (
                <EditDateModal
                    rowData={selectedRow}
                    flag={flag}
                    closeModal={async () => {
                        setEditDeliveryModalOpen(false);
                    }}
                />
            )}
            <DialogTitle>
                <Grid container>
                    <Grid item xs={10} style={{ textAlign: 'center' }}>
                        Inbound Truck {truck.id} {truck.priorityPoIDs?.length > 0 && ` - PRIORITY`}
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            onClick={async () => {
                                try {
                                    await api.downloadTruckShipList(truck.id, user.facilityID);
                                } catch (e) {
                                    setNotification({ text: e.toString(), level: 'error' });
                                }
                            }}
                        >
                            Ship List
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {columns.map((col) => {
                                return (
                                    <TableRow key={col.accessor}>
                                        <TableCell variant="head">{col.Header}</TableCell>
                                        <TableCell>
                                            <DefaultCell
                                                cell={{ value: truck[col.accessor] }}
                                                row={{ original: truck }}
                                                column={{ ...col, id: col.accessor }}
                                                saveEdit={async ({ id, field, value }) => {
                                                    let item = { [field]: value };
                                                    const facilityStopFields = ['dockDoor', 'notes'];
                                                    let response;
                                                    if (facilityStopFields.includes(field)) {
                                                        response = await api.editTruckFacilityStopDetails(
                                                            truck.facilityStopID,
                                                            item
                                                        );
                                                    } else {
                                                        response = await api.editTruckDetails(id, item);
                                                    }
                                                    if (response.status === false) {
                                                        let message = 'Error modifying truck';
                                                        if (response.msg) {
                                                            message += ': ' + response.msg;
                                                        }
                                                        setNotification({ text: message, level: 'error' });
                                                    } else {
                                                        setNotification({ text: 'Truck modified!', level: 'success' });
                                                        await getTrucks();
                                                        await onClose();
                                                    }
                                                }}
                                                editable={col.editable === true}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
};

const DailyTruckSchedule = ({ trucks, dockDoorKeys, date, loaded, getTrucks, setNotification }) => {
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState({});

    let timeslots = [];
    new Array(24).fill().forEach((acc, index) => {
        timeslots.push(moment({ hour: index }).format('hh:mm a'));
    });

    if (!loaded) {
        return null;
    }

    if (!trucks.length) {
        return <div>No trucks found for {date}</div>;
    }

    const openTruckDetailsModal = (truck) => {
        setSelectedTruck(truck);
        setDetailModalOpen(true);
    };

    return (
        <>
            <TruckDetailsModal
                setNotification={setNotification}
                getTrucks={getTrucks}
                selectedTruck={selectedTruck}
                open={detailModalOpen && selectedTruck && selectedTruck.hasOwnProperty('id')}
                onClose={async () => {
                    setSelectedTruck({});
                    await getTrucks();
                    setDetailModalOpen(false);
                }}
            />
            <TableContainer className="tscroll" component={Paper}>
                <Table
                    style={{
                        tableLayout: 'fixed',
                        width: '100%',
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{
                                    width: '170px',
                                    textAlign: 'center',
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: '#ddd',
                                    border: '1px solid black',
                                    borderCollapse: 'collapse',
                                }}
                            >
                                <h4>Appointment Time</h4>
                            </TableCell>
                            {dockDoorKeys.map((door) => {
                                return (
                                    <TableCell
                                        key={door}
                                        style={{
                                            width: '700px',
                                            textAlign: 'center',
                                            border: '1px solid black',
                                            borderCollapse: 'collapse',
                                        }}
                                    >
                                        <h3>{door === 'unassigned' ? 'Door Not Assigned' : `Door ${door}`}</h3>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timeslots.map((time, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell
                                        style={{
                                            position: 'sticky',
                                            left: 0,
                                            backgroundColor: '#ddd',
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item sm={6}>
                                                {time}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {timeslots[index + 1] ? timeslots[index + 1] : timeslots[0]}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    {dockDoorKeys.map((door) => {
                                        return (
                                            <TableCell
                                                style={{
                                                    borderLeft: '1px solid black',
                                                    borderRight: '1px solid black',
                                                    borderCollapse: 'collapse',
                                                }}
                                                key={door}
                                            >
                                                <ScheduledTruckTableCell
                                                    time={time}
                                                    trucks={trucks}
                                                    door={door}
                                                    openTruckDetailsModal={openTruckDetailsModal}
                                                />
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <h3>Color Key</h3>
            <>
                <div style={{ width: '15%', display: 'inline-block' }}>
                    <div
                        style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid black',
                            backgroundColor: '#ffe208',
                            display: 'inline-block',
                        }}
                    ></div>
                    <span style={{ fontSize: '13px' }}> OOS Item</span>
                </div>
                <div style={{ width: '10%', display: 'inline-block' }}>
                    <div
                        style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid black',
                            backgroundColor: '#19a7ce',
                            display: 'inline-block',
                        }}
                    ></div>
                    <span style={{ fontSize: '13px' }}> FBM</span>
                </div>
                <div style={{ width: '10%', display: 'inline-block' }}>
                    <div
                        style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid black',
                            backgroundColor: '#b5f0b3',
                            display: 'inline-block',
                        }}
                    ></div>
                    <span style={{ fontSize: '13px' }}> Food</span>
                </div>
                <div style={{ width: '10%', display: 'inline-block' }}>
                    <div
                        style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid black',
                            backgroundColor: '#dabfae',
                            display: 'inline-block',
                        }}
                    ></div>
                    <span style={{ fontSize: '13px' }}> Packaging</span>
                </div>
                <div style={{ width: '10%', display: 'inline-block' }}>
                    <div
                        style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid black',
                            backgroundColor: '#da9199',
                            display: 'inline-block',
                        }}
                    ></div>
                    <span style={{ fontSize: '13px' }}> Cancelled</span>
                </div>
                <div style={{ width: '25%', display: 'inline-block' }}>
                    <div
                        style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid black',
                            backgroundColor: '#c3b9e6',
                            display: 'inline-block',
                        }}
                    ></div>
                    <span style={{ fontSize: '13px' }}> Mixed packaging and food</span>
                </div>
                <div style={{ width: '20%', display: 'inline-block' }}>
                    <div
                        style={{
                            height: '25px',
                            width: '25px',
                            border: '1px solid black',
                            backgroundColor: '#ffc766',
                            display: 'inline-block',
                        }}
                    ></div>
                    <span style={{ fontSize: '13px' }}> Truck is late</span>
                </div>
            </>
        </>
    );
};

let InboundTrucksSchedule = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    const [date, setDate] = useState(moment().format('MM/DD/YYYY'));
    const [trucks, setTrucks] = useState([]);
    const [scheduledTrucks, setScheduledTrucks] = useState({ dockDoorKeys: [], trucksOnDate: [] });
    const [selectDateModalOpen, setSelectDateModalOpen] = useState(false);
    const [trucksLoaded, setTrucksLoaded] = useState(false);
    const [scheduledTrucksOnDate, setScheduledTrucksOnDate] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const user = useContext(UserContext);

    let getOptionsForInboundTrucks = async () => {
        let res = await api.getInboundOptions();
        if (!res.status) {
            return;
        }
        setCarriers(res.data.carriers.map((item) => ({ text: item.name, value: item.id })));
    };

    let decrementDate = () => {
        setDate(moment(date).subtract(1, 'days').format('MM/DD/YYYY'));
    };

    let incrementDate = () => {
        setDate(moment(date).add(1, 'days').format('MM/DD/YYYY'));
    };

    // Load
    let searchInboundTrucks = async ({ searchBy, searchTerm }) => {
        let response = await api.getTrucks(user.getFacilityID(), {});

        if (response.status === false) {
            setNotification({ text: 'No trucks found ' + response.msg, level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.scheduledDelivery = !!row.scheduledDeliveryTime
                    ? moment(row.scheduledDeliveryTime.date).format('MM/DD/YYYY hh:mm a')
                    : null;
                row.checkIn = !!row.checkInTime ? moment(row.checkInTime.date).format('MM/DD/YYYY hh:mm a') : null;
                row.checkOut = !!row.checkOutTime ? moment(row.checkOutTime.date).format('MM/DD/YYYY hh:mm a') : null;
                row.dockIn = !!row.dockInTime ? moment(row.dockInTime.date).format('MM/DD/YYYY hh:mm a') : null;
                row.unloadStart = !!row.unloadStartTime
                    ? moment(row.unloadStartTime.date).format('MM/DD/YYYY hh:mm a')
                    : null;
                row.unloadEnd = !!row.unloadEndTime
                    ? moment(row.unloadEndTime.date).format('MM/DD/YYYY hh:mm a')
                    : null;

                row.scheduledDeliveryDetails = {
                    date: !!row.scheduledDeliveryTime
                        ? moment(row.scheduledDeliveryTime.date).format('MM/DD/YYYY')
                        : null,
                    startingHour: !!row.scheduledDeliveryTime
                        ? moment(row.scheduledDeliveryTime.date).startOf('hour').format('hh:mm a')
                        : null,
                    time: !!row.scheduledDeliveryTime ? moment(row.scheduledDeliveryTime.date).format('hh:mm a') : null,
                };

                row.multiStopTruck = row.multiStopTruck ? 'yes' : 'no';
                row.scheduled = row.unscheduled ? 'no' : 'yes';

                const twoHoursInMs = 2 * (60 * 60 * 1000);
                const scheduledDeliveryTimeAsDate =
                    !!row.scheduledDeliveryTime && row.scheduledDeliveryTime.date
                        ? new Date(row.scheduledDeliveryTime.date)
                        : new Date();
                const timeDifference = new Date() - scheduledDeliveryTimeAsDate;
                row.isTruckOnTime =
                    !!row.checkInTime || timeDifference < twoHoursInMs || timeDifference < 0 ? 'Yes' : 'No';

                row.isFBM = row.fbmPartner != null && row.fbmPartner.trim() != '' ? 'Yes' : 'No';

                return row;
            });
        }

        setTrucks(response.data.rows);
        setTrucksLoaded(true);
    };

    useEffect(() => {
        searchInboundTrucks({});
        getOptionsForInboundTrucks();
    }, []);

    useEffect(() => {
        let trucksOnDate = trucks.filter((truck) => truck.scheduledDeliveryDetails.date === date);

        let dockDoorMap = {};
        trucksOnDate.forEach((truck) => {
            if (!truck.dockDoor) {
                truck.dockDoorKey = 'unassigned';
            } else {
                truck.dockDoorKey = truck.dockDoor;
            }
            if (!dockDoorMap.hasOwnProperty(truck.dockDoorKey)) {
                dockDoorMap[truck.dockDoorKey] = [];
            }
            dockDoorMap[truck.dockDoorKey].push(truck);
        });

        let dockDoorKeys = Object.keys(dockDoorMap).sort((a, b) => {
            if (a === 'unassigned') {
                return -1;
            } else {
                return a - b;
            }
        });

        setScheduledTrucks({ dockDoorKeys, trucksOnDate });
        setScheduledTrucksOnDate(trucksOnDate);
    }, [trucks, date]);

    return (
        <div>
            <Notifications options={notification} />
            <Dialog
                open={selectDateModalOpen}
                maxWidth="md"
                onClose={() => {
                    setSelectDateModalOpen(false);
                }}
            >
                <DialogContent>
                    <FormComponent
                        formFields={[
                            {
                                name: 'date',
                                label: 'Date',
                                inputElement: 'date',
                                gridValue: 10,
                                initialValue: moment(date).format('YYYY-MM-DD'),
                            },
                        ]}
                        onSubmit={async (formData) => {
                            setDate(moment(formData.date).format('MM/DD/YYYY'));
                            setSelectDateModalOpen(false);
                        }}
                    />
                </DialogContent>
            </Dialog>
            <h2>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        Inbound Trucks Schedule
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <IconButton onClick={decrementDate}>
                            <ChevronLeftIcon />
                        </IconButton>
                        {moment(date).format('dddd MM/DD/YYYY')}
                        <IconButton onClick={incrementDate}>
                            <ChevronRightIcon />
                        </IconButton>

                        <div style={{ display: 'inline-block', paddingLeft: '100px' }}>
                            <Button
                                onClick={() => {
                                    setSelectDateModalOpen(true);
                                }}
                            >
                                Select Date
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            onClick={() => {
                                searchInboundTrucks({});
                            }}
                        >
                            Refresh Data
                        </Button>
                    </Grid>
                </Grid>
            </h2>
            <DailyTruckSchedule
                setNotification={setNotification}
                getTrucks={async () => {
                    await searchInboundTrucks({});
                }}
                dockDoorKeys={scheduledTrucks.dockDoorKeys}
                trucks={scheduledTrucksOnDate}
                date={date}
                loaded={trucksLoaded}
            />
        </div>
    );
};

export { InboundTrucksSchedule };
