import React, { useState, useEffect, useContext } from 'react';
import { DataTable, FormComponent, Notifications } from '../../../shared';
import { inboundTruckApi as api } from '../../../api/inboundTrucks';
import { procurementApi as procureApi } from '../../../api/procurement';
import { logisticsApi } from '../../../api/logistics';
import { warehouseApi } from '../../../api/warehouse';
import { packagingApi } from '../../../api/packaging';
import { fbmApi } from '../../../api/fbm';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import { Autocomplete } from '@material-ui/lab';

let CreateInboundTruck = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [freightTypeOpts, setFreightTypeOpts] = useState([]);
    let [carriers, setCarriers] = useState([]);
    let [deliveryTypeOpts, setDeliveryTypeOpts] = useState([]);
    let [facilities, setFacilities] = useState([]);
    let [inboundTruckFormData, setInboundTruckFormData] = useState({});
    let [inboundTruckStopsFormData, setInboundTruckStopsFormData] = useState({});
    let [resetInboundTrucksForm, setResetInboundTrucksForm] = useState(null);
    let [reportValidityInboundTrucksForm, setReportValidityInboundTrucksForm] = useState(null);
    let [loading, setLoading] = useState(false);
    let [partnerOpts, setPartnerOpts] = useState([]);
    let [fbmAccounts, setFbmAccounts] = useState([]);
    const [poType, setPoType] = useState(null);
    const [changePoTypeModalOpen, setChangePoTypeModalOpen] = useState(false);
    const [crossDocks, setCrossDocks] = useState([]);
    const [facilitiesCrossDocksOpts, setFacilitiesCrossDocksOpts] = useState([]);

    const user = useContext(UserContext);

    let formFields = [
        {
            name: 'deliveryType',
            label: 'Delivery Type',
            inputElement: 'select',
            gridValue: 2,
            inputProps: {
                required: true,
                opts: deliveryTypeOpts,
            },
        },
        {
            name: 'transfer',
            label: 'Transfer Truck',
            inputElement: 'checkbox',
            gridValue: 2,
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
        {
            name: 'carrierID',
            inputElement: 'select',
            label: 'Carrier',
            gridValue: 2,
            inputProps: { required: true, opts: carriers },
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
        { name: 'unscheduled', inputElement: 'checkbox', label: 'Unscheduled', gridValue: 2 },
        // for DELIVERED type delivery type, fbm field
        {
            name: 'fbmPartner',
            inputElement: 'select',
            label: 'Partnership',
            gridValue: 2,
            inputProps: { required: false, opts: partnerOpts },
            display: (formData) => formData.deliveryType === 'DELIVERED',
        },

        // cost fields - don't show if delivery type is DELIVERED
        {
            name: 'freightCost',
            inputElement: 'textField',
            label: 'Rate',
            gridValue: 3,
            inputProps: { required: true, type: 'number' },
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
        {
            name: 'freightType',
            inputElement: 'select',
            label: 'Freight Type',
            gridValue: 3,
            inputProps: { required: true, opts: freightTypeOpts },
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
        {
            name: 'shipCost',
            inputElement: 'textField',
            label: 'Additional Shipping Cost',
            gridValue: 3,
            inputProps: { required: true, type: 'number' },
            display: (formData) => !!formData.additionalCostType,
        },
        {
            name: 'referenceNumber',
            inputElement: 'textField',
            label: 'Release #',
            gridValue: 3,
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
        {
            name: 'shipDate',
            label: 'Ship Date',
            inputElement: 'date',
            gridValue: 3,
            inputProps: {
                required: true,
            },
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
        {
            name: 'shippingClass',
            label: 'Shipping Class',
            inputElement: 'select',
            gridValue: 3,
            inputProps: {
                opts: [
                    { text: 'Grocery', value: 'grocery' },
                    { text: 'Produce', value: 'produce' },
                ],
                required: true,
            },
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
        {
            name: 'poType',
            label: 'PO Type',
            inputElement: 'select',
            gridValue: 3,
            inputProps: {
                opts: [
                    { text: 'Full POs', value: 'full' },
                    { text: 'Partial POs', value: 'partial' },
                ],
                required: true,
                onChange: (e) => changePoType(e.target.value),
            },
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
        {
            name: 'crossDockID',
            label: 'Origin Cross Dock',
            inputElement: 'select',
            gridValue: 3,
            inputProps: {
                opts: crossDocks,
                required: false,
            },
            display: (formData) => formData.deliveryType === 'PICK UP',
        },
    ];

    const changePoType = (value) => {
        if (Object.keys(inboundTruckStopsFormData).length > 0) {
            setChangePoTypeModalOpen(true);
        }
        setPoType(value);
    };

    const getCrossDocks = async () => {
        const response = await logisticsApi.getInboundCrossDocks(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get cross docks', level: 'error' });
            setCrossDocks([]);
            return;
        }

        let crossDocksAsOpts = response.data.rows.map((item) => ({ text: item.name, value: item.id }));
        crossDocksAsOpts.unshift({ text: '', value: '' });
        setCrossDocks(crossDocksAsOpts);
    };

    let getFacilities = async () => {
        let res = await warehouseApi.getFacilities(true);
        if (!res.data) {
            setNotification({ text: 'Failed to get facilities. ' + res.msg, level: 'error' });
            return false;
        }

        setFacilities(res.data.rows.map((item) => ({ text: item.name, value: item.id })));
    };

    let getOptionsForInboundTrucks = async () => {
        let res = await api.getInboundOptions();
        if (!res.status) {
            return;
        }

        setCarriers(res.data.carriers.map((item) => ({ text: item.name, value: item.id })));
        setFreightTypeOpts(res.data.freightTypes);
        setDeliveryTypeOpts(res.data.deliveryTypes);
    };

    let getPartnerships = async () => {
        let res = await fbmApi.getFBMAccounts();
        if (!res.status) {
            return;
        }
        let opts = res.data.accounts.map((account) => ({ text: account.companyName, value: account.fbmAccountName }));
        opts.unshift({ text: 'None', value: '' });
        setPartnerOpts(opts);
        setFbmAccounts(res.data.accounts);
    };

    useEffect(() => {
        getOptionsForInboundTrucks();
        getFacilities();
        getPartnerships();
        getCrossDocks();
    }, []);

    useEffect(() => {
        let facilitiesCrossDocks = facilities?.concat(crossDocks?.map(item => ({ text: item.text, value: `XD-${item.value}` })));
        facilitiesCrossDocks = facilitiesCrossDocks.filter(item => item.text !== '');

        setFacilitiesCrossDocksOpts(facilitiesCrossDocks);
    },[facilities, crossDocks]);

    let createTruck = async () => {
        if (!inboundTruckFormData.validity) {
            if (reportValidityInboundTrucksForm instanceof Function) {
                reportValidityInboundTrucksForm();
            }
            return;
        }

        if (!Object.keys(inboundTruckStopsFormData).length) {
            setNotification({ text: 'Please add at least one stop for truck', level: 'error' });
            return;
        }

        let err = false;
        Object.keys(inboundTruckStopsFormData).forEach((key) => {
            if (!inboundTruckStopsFormData[key].validity) {
                setNotification({ text: 'Please complete all required fields for facility stops', level: 'error' });
                err = true;
            }
            if (!inboundTruckStopsFormData[key].poIDs.length && !inboundTruckStopsFormData[key].packagingPoIDs.length) {
                setNotification({ text: 'At least one PO is required for each stop', level: 'error' });
                err = true;
            }

            if (poType === 'partial') {
                let poItemTracker = 0;

                //map over PO IDs to make sure each has at least one item associated
                inboundTruckStopsFormData[key].poIDs.forEach((poID) => {
                    let matchFound = false;
                    inboundTruckStopsFormData[key].partialPoItems.forEach((itemObj) => {
                        if (itemObj.poID === poID.value && !matchFound) {
                            poItemTracker += 1;
                            matchFound = true;
                        }
                    });
                });

                if (poItemTracker !== inboundTruckStopsFormData[key].poIDs.length) {
                    setNotification({
                        text: 'At least one item is required per PO for each stop when creating a partial PO truck',
                        level: 'error',
                    });
                    err = true;
                }
            }
        });
        if (err) {
            return;
        }

        let submitObj = JSON.parse(JSON.stringify(inboundTruckFormData));
        submitObj.stops = [];
        Object.keys(inboundTruckStopsFormData).forEach((key) => {
            let formData = JSON.parse(JSON.stringify(inboundTruckStopsFormData[key]));

            if (!formData.poIDs.length && !formData.packagingPoIDs.length) {
                setNotification({ text: 'Must select one PO for each stop', level: 'error' });
                return;
            }

            formData.poIDs = formData.poIDs.map((item) => item.value);
            formData.packagingPoIDs = formData.packagingPoIDs.map((item) => item.value);
            formData.partialPoItems = formData.partialPoItems.map((item) => item.poMapID);
            submitObj.stops.push(formData);
        });

        let response = await api.createTruck(submitObj);
        if (response.status === true) {
            setNotification({ text: 'Truck created!', level: 'success' });
            setInboundTruckStopsFormData({});
            if (resetInboundTrucksForm instanceof Function) {
                resetInboundTrucksForm();
            }
        } else {
            let message = 'Error creating truck';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
    };

    let getFacilitiesOpts = () => {
        let filteredOpts = facilitiesCrossDocksOpts.filter((item) => !Object.keys(inboundTruckStopsFormData).includes(item.text));
        if (inboundTruckFormData.fbmPartner != '' && inboundTruckFormData.deliveryType == 'DELIVERED') {
            let fbmAccount = fbmAccounts.find((account) => account.fbmAccountName == inboundTruckFormData.fbmPartner);
            filteredOpts = filteredOpts.filter((item) => fbmAccount.facilityIDs.includes(item.value));
        }
        return filteredOpts;
    };

    return (
        <div>
            {changePoTypeModalOpen && (
                <ConfirmChangePoTypeModal
                    open={changePoTypeModalOpen}
                    setOpen={setChangePoTypeModalOpen}
                    setInboundTruckStopsFormData={setInboundTruckStopsFormData}
                />
            )}
            <h1>Create Inbound Truck</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    hideSubmit={true}
                    onFormDataChange={(formData, validity, resetForm, reportValidity) => {
                        if (resetInboundTrucksForm == null) {
                            setResetInboundTrucksForm(() => () => resetForm());
                            if (reportValidity instanceof Function) {
                                setReportValidityInboundTrucksForm(() => () => reportValidity());
                            }
                        }
                        let tmp = JSON.parse(JSON.stringify(formData));
                        tmp.validity = validity;
                        setInboundTruckFormData(tmp);
                    }}
                />
                {Object.keys(inboundTruckStopsFormData).map((facilityName) => {
                    return (
                        <AddStopToTruck
                            deliveryType={inboundTruckFormData.deliveryType}
                            transfer={inboundTruckFormData.transfer}
                            inboundTruckStopsFormData={inboundTruckStopsFormData}
                            setInboundTruckStopsFormData={setInboundTruckStopsFormData}
                            facilityName={facilityName}
                            facilityID={inboundTruckStopsFormData[facilityName].facilityID}
                            setNotification={setNotification}
                            poType={poType}
                            removeStop={() => {
                                let tmp = JSON.parse(JSON.stringify(inboundTruckStopsFormData));
                                delete tmp[facilityName];
                                setInboundTruckStopsFormData(tmp);
                            }}
                            fbmPartnershipName={
                                inboundTruckFormData.deliveryType == 'DELIVERED' ? inboundTruckFormData.fbmPartner : ''
                            }
                            facilities={facilities}
                        />
                    );
                })}
                &nbsp;&nbsp;
                {
                    <>
                        <FormComponent
                            formFields={[
                                {
                                    label: 'Add Stop To Truck',
                                    name: 'facilityID',
                                    inputElement: 'select',
                                    gridValue: 10,
                                    inputProps: {
                                        opts: getFacilitiesOpts(),
                                        required: true,
                                    },
                                },
                            ]}
                            button={{
                                gridValue: 2,
                                text: 'Add stop for facility',
                            }}
                            onSubmit={(formData, resetForm) => {
                                let tmp = JSON.parse(JSON.stringify(inboundTruckStopsFormData));
                                let facilityName = facilitiesCrossDocksOpts.find((item) => item.value == formData.facilityID)?.text;
                                if (!facilityName) {
                                    return;
                                }
                                tmp[facilityName] = { facilityID: formData.facilityID };
                                setInboundTruckStopsFormData(tmp);
                                resetForm();
                            }}
                        />
                    </>
                }
                <div style={{ paddingTop: '100px' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            onClick={async () => {
                                setLoading(true);
                                await createTruck();
                                setLoading(false);
                            }}
                        >
                            Create Truck
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

const ConfirmChangePoTypeModal = ({ open, setOpen, setInboundTruckStopsFormData }) => {
    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth={true}
            onClose={() => {
                setOpen(false);
            }}
        >
            <DialogTitle>Changing the PO Type will remove all facility stops. Proceed?</DialogTitle>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        setInboundTruckStopsFormData({});
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AddStopToTruck = ({
    facilityID,
    facilityName,
    inboundTruckStopsFormData,
    setInboundTruckStopsFormData,
    setNotification,
    poType,
    deliveryType,
    removeStop,
    transfer,
    fbmPartnershipName,
    facilities
}) => {
    let [pos, setPos] = useState([]);
    let [packagingPos, setPackagingPos] = useState([]);
    const [partialPoItems, setPartialPoItems] = useState([]);
    const [selectedPartialPoSkus, setSelectedPartialPoSkus] = useState([]);
    const [isCrossDockStop, setIsCrossDockStop] = useState(facilityID.startsWith('XD'));
    const [destinationFacilityID, setDestinationFacilityID] = useState(null);

    let stopFormFields = [
        {
            name: 'scheduledDeliveryDate',
            inputElement: 'date',
            label: 'Scheduled Delivery Date',
            gridValue: 4,
            required: deliveryType === 'DELIVERED',
        },
        {
            name: 'scheduledDeliveryTime',
            inputElement: 'time',
            label: 'Scheduled Delivery Time',
            gridValue: 4,
            required: deliveryType === 'DELIVERED',
        },
        {
            name: 'dockDoor',
            inputElement: 'textField',
            label: 'Dock Door',
            gridValue: 4,
            inputProps: { type: 'number', integer: true },
            display: !isCrossDockStop
        },
        {
            name: 'crossDockFacilityID',
            inputElement: 'select',
            label: 'Destination FC',
            gridValue: 4,
            display: isCrossDockStop,
            inputProps: {
                required: true,
                opts: facilities,
                onChange: e => setDestinationFacilityID(e.target.value)
            }
        },
        { name: 'notes', inputElement: 'textField', label: 'Notes', inputProps: { multiline: true }, gridValue: 12 },
        {
            name: 'poIDs',
            label: transfer || fbmPartnershipName != '' ? 'ASNs' : 'POs',
            inputElement: 'autocomplete',
            inputProps: {
                opts: pos
                    .filter((item) => {
                        if (fbmPartnershipName != '') {
                            return true;
                        }

                        //For partial just use server side filter
                        if (poType === 'partial') return true;

                        /**
                         * If the truck is DELIVERED, allow PO's that are delivered and do not have a cross dock
                         * If the truck is PICK UP, allow PO's that are pick up, AND PO's that are delivered with a cross dock
                         */
                        if (deliveryType === 'DELIVERED') {
                            return item.deliveryType === deliveryType && !item.crossDockID;
                        } else if (deliveryType === 'PICK UP') {
                            return item.deliveryType === deliveryType || item.crossDockID > 0;
                        }
                        return false;
                    })
                    .map((item) => {
                        if (transfer || fbmPartnershipName != '') {
                            return { value: item.id, text: `ASN ${item.id}` };
                        } else {
                            return { value: item.id, text: `PO ${item.id} - ${item.vendorName}` };
                        }
                    }),
                multiple: true,
                onChange: async (e, val, formData, reason, detail) => {
                    if (reason === 'select-option') {
                        // let res = await api.getPOItemsAvailableForPartialPOTrucks(detail.option.value);
                        let res = await procureApi.getPurchaseOrderDetails(detail.option.value);
                        if (!res.data) {
                            setNotification({ level: 'error', text: 'Failed to get PO details' });
                            return;
                        }

                        let poItems = res.data.output.items;

                        let mappedPoItems = poItems.map(item => ({
                            ...item,
                            poID: res.data.output.poID
                        }));

                        setPartialPoItems((items) => [...items, ...mappedPoItems]);
                    } else if (reason === 'remove-option') {
                        let poID = detail.option.value;

                        setSelectedPartialPoSkus((items) => items.filter((item) => item.poID !== poID));
                        setPartialPoItems((items) => items.filter((item) => item.poID !== poID));
                    }
                },
            },
        },
        {
            name: 'packagingPoIDs',
            label: fbmPartnershipName != '' ? ' Packaging ASNs' : 'Packaging POs',
            inputElement: 'autocomplete',
            display: !transfer && poType !== 'partial',
            inputProps: {
                opts: packagingPos.map((item) => ({ value: item.id, text: `PO ${item.id} - ${item.vendorName}` })),
                multiple: true,
            },
        },
        {
            name: 'partialPoItems',
            label: 'PO Items',
            display: !transfer && poType === 'partial',
            inputElement: ({ formData, setFormField }) => (
                <Autocomplete
                    multiple
                    options={partialPoItems}
                    value={selectedPartialPoSkus}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                        `PO ${option.poID} - ${option.externalSku}: ${option.itemName}; Case Qty: ${option.qty}; Cases/Pallet: ${option.csPallet}`
                    }
                    getOptionSelected={(option, value) => option.poMapID === value.poMapID}
                    renderOption={(option, { selected }) => {
                        return (
                            <li>
                                <Checkbox checked={selected} style={{ marginRight: 4 }} color="primary" />
                                {`PO ${option.poID} - ${option.externalSku}: ${option.itemName}; Case Qty: ${option.qty}; Cases/Pallet: ${option.csPallet}`}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Select PO Items" />}
                    onChange={(e, val, reason, detail) => {
                        let option;
                        let partialSkuOption;

                        if (!!detail) {
                            option = detail.option;
                            partialSkuOption = {
                                poID: option.poID,
                                externalSku: option.externalSku,
                                itemName: option.itemName,
                                qty: option.qty,
                                csPallet: option.csPallet,
                                poMapID: option.poMapID,
                            };
                        }

                        if (reason === 'select-option') {
                            setSelectedPartialPoSkus((items) => [...items, partialSkuOption]);
                        } else if (reason === 'remove-option') {
                            setSelectedPartialPoSkus((items) =>
                                items.filter((item) => item.poMapID !== partialSkuOption.poMapID)
                            );
                        } else if (reason === 'clear') {
                            setSelectedPartialPoSkus([]);
                        }

                        setFormField(selectedPartialPoSkus);
                    }}
                />
            ),
        },
    ];

    let getPos = async (facilityID) => {
        let pos;
        if (transfer) {
            pos = await warehouseApi.getInternalTransfers();
        } else if (fbmPartnershipName != '') {
            pos = await fbmApi.getASNs(fbmPartnershipName);
        } else {
            pos = await procureApi.getAllPOs(facilityID);
        }
        if (!pos.status) {
            setNotification({ level: 'error', text: 'Failed to get POs' });
            return;
        }

        let filteredRows = pos.data.rows.filter((item) => {
            if (transfer && (item.destinationFacilityID != facilityID || item.status !== 'PLACED')) {
                return false;
            }
            if (fbmPartnershipName != '' && (item.facilityID != facilityID || item.status != 'PLACED')) {
                return false;
            }
            if (!!item.truckID && item.truckID !== 'N/A') {
                return false;
            }
            return true;
        });
        setPos(filteredRows);
    };

    let getPackagingPos = async (facilityID) => {
        let ppos;
        if (fbmPartnershipName != '') {
            ppos = await fbmApi.getPackagingASNs(fbmPartnershipName);
        } else {
            ppos = await packagingApi.getPurchaseOrders('PLACED', facilityID);
        }
        if (!ppos.status) {
            setNotification({ level: 'error', text: 'Failed to get packaging POs' });
            return;
        }
        let filteredRows = ppos.data.rows.filter((item) => {
            if (fbmPartnershipName != '' && (item.facilityID != facilityID || item.status != 'PLACED')) {
                return false;
            }
            if (!item.truckID) {
                return true;
            }
            return false;
        });
        setPackagingPos(filteredRows);
    };

    useEffect(() => {
        if(!isCrossDockStop) {
            getPackagingPos(facilityID);
            getPos(facilityID);
        } else if(isCrossDockStop && !!destinationFacilityID) {
            getPackagingPos(destinationFacilityID);
            getPos(destinationFacilityID);
        }
    }, [fbmPartnershipName, destinationFacilityID]);

    return (
        <div style={{ padding: '10px', border: '1px solid gray', margin: '10px' }}>
            <h2>
                Inbound Truck {isCrossDockStop ? 'Cross Dock' : 'Facility'} Stop: {facilityName}
                <Button style={{ float: 'right' }} variant="outlined" color="secondary" onClick={removeStop}>
                    Remove Stop
                </Button>
            </h2>
            <FormComponent
                formFields={stopFormFields}
                hideSubmit={true}
                onFormDataChange={(formData, validity) => {
                    formData.partialPoItems = selectedPartialPoSkus;
                    let tmp = JSON.parse(JSON.stringify(inboundTruckStopsFormData));
                    tmp[facilityName] = formData;
                    tmp[facilityName].validity = validity;
                    tmp[facilityName].facilityID = facilityID;
                    setInboundTruckStopsFormData(tmp);
                }}
            />
        </div>
    );
};

export { CreateInboundTruck };
