import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let warehouseApi = {
    adjustPallet: async (id, item) => {
        let url = `api/warehouse/v2/pallets/` + id + `/adjust`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updatePalletStatus: async (id, status) => {
        const url = `api/warehouse/v2/pallets/${id}/status`;
        return await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                status,
            }),
        });
    },
    updateCaseStatus: async (id, status) => {
        const url = `api/warehouse/v1/cases/${id}/status`;
        return await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                status,
            }),
        });
    },
    adjustFullCasesInLocation: async (palletID, locationID, numFullCases) => {
        let url = `api/warehouse/v1/cases/full/${palletID}/location/${locationID}/adjust`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ numFullCases, fromDesktop: true }),
        });
        return response;
    },
    createLocation: async (item) => {
        let url = `api/warehouse/v1/locations`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createLocationV2: async (item) => {
        let url = `api/warehouse/v2/locations/create`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    getInternalTransfers: async (externalSku = null, poID = null) => {
        let url = `api/warehouse/v1/internalTransfer`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getInternalTransferInventory: async (facilityID) => {
        let url = `api/warehouse/v1/internalTransfer/pendingInventory?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getInventoryHistory: async (params) => {
        const queryParams = '?' + new URLSearchParams(params).toString();
        let url = `api/inventory/v1/ims/history${queryParams}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemMaster: async (activeFlag = true, mmOnly = true) => {
        let url = `api/warehouse/v1/itemMaster?active=` + activeFlag;
        if (mmOnly === false) {
            url += `&mmOnly=false`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemMasterDetails: async (itemMasterSKU) => {
        let url = `api/warehouse/v1/itemMaster?intSku=` + itemMasterSKU;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    createInternalTransferASN: async (sendParams) => {
        let url = `api/warehouse/v1/internalTransfer/asn`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    importLoadsheet: async (packGroupID, data) => {
        let url = `api/warehouse/v1/loadsheet/${packGroupID}`;
        let formData = new FormData();
        formData.append('file', data.file);

        return await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
    },
    confirmLoadsheetImport: async (key) => {
        const url = 'api/warehouse/v1/loadsheets/confirm';

        return await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
    },
    downloadLoadsheet: async (week, year, facilityID) => {
        let url = `api/warehouse/v1/loadsheet/download?facilityID=${facilityID}&week=${week}&year=${year}`;
        let filename = `loadsheet_results.csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadLoadsheet] error', e.toString());
        }
    },
    addItemsToTransferASN: async (poID, items) => {
        let url = `api/warehouse/v1/internalTransfer/asn/${poID}/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ items }),
        });
        return response;
    },
    getLocationsInZone: async (facilityID, zone, zoneType, prefix) => {
        let url = `api/warehouse/v2/locations/zone?facilityID=${facilityID}&zone=${zone}&zoneType=${zoneType}&prefix=${prefix}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getNewAndExistingLocations: async (item) => {
        let url = `api/warehouse/v2/locations/create/check`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    confirmLocationCreation: async (key) => {
        let url = 'api/warehouse/v2/locations/confirm';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    consolidatePallet: async (id, sendParams) => {
        let url = `api/warehouse/v1/pallets/${id}/consolidate`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    consumePalletToSampleProgram: async (palletID, item) => {
        let url = `api/warehouse/v1/pallets/` + palletID + `/consumeToSample`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    consumeToStaging: async (palletID, sendParams) => {
        let url = `api/warehouse/v1/pallets/${palletID}/consumeToStaging`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    consumeToVoid: async () => {
        let url = `api/warehouse/v1/consumeToVoid`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    convertCaseToIL2: async (caseID, sendParams) => {
        let url = `api/warehouse/v1/cases/${caseID}/ilt`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    convertFullCaseToPartial: async (caseID, numUnits) => {
        let url = `api/warehouse/v1/cases/full/${caseID}/convertToPartial`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ numUnits }),
        });
        return response;
    },
    convertPalletsToIL2: async (sendParams) => {
        let url = `api/warehouse/v1/pallets/ilt`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    convertPalletsToIL2V2: async (sendParams) => {
        let url = `api/warehouse/v2/pallets/ilt`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    transferPallets: async (sendParams) => {
        let url = `api/warehouse/v1/pallets/transfer`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    cycleCountCase: async (id, sendParams) => {
        let url = `api/warehouse/v1/cases/${id}/cycleCount`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    donateCase: async (caseID, item) => {
        let url = `api/warehouse/v1/cases/` + caseID + `/donate`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    donatePallet: async (palletID, item) => {
        let url = `api/warehouse/v1/pallets/` + palletID + `/donate`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    downloadCaseTags: async ({ caseID, qty = 1, palletID }) => {
        let url = `api/warehouse/v1/cases/tag?`;
        if (!!caseID) {
            url += `caseID=${caseID}&qty=${qty}`;
        } else if (!!palletID) {
            url += `palletID=${palletID}`;
        }
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                return blob;
            }
        } catch (e) {
            console.log('[downloadCaseTags] error', e.toString());
        }
    },
    downloadLotTag: async (lotID) => {
        let filename = 'Lot' + lotID + '.pdf';
        let url = `api/warehouse/v1/lots/${lotID}/tag`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadLotTag] error', e.toString());
        }
    },
    downloadPalletTag: async (id, name, qty = 1) => {
        let filename = 'pallet_' + name + '.pdf';
        let url = `api/warehouse/v1/pallets/${id}/tag?qty=${qty}`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                return blob;
            }
        } catch (e) {
            console.log('[downloadPalletTag] error', e.toString());
        }
    },
    bulkAdjustNumUnits: async (sendParams) => {
        let url = `api/warehouse/v1/cases/adjust/bulk`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    bulkDownloadLocationLabels: async (ids) => {
        let filename = 'location_labels.pdf';
        let url = `api/warehouse/v2/locations/labels`;
        try {
            let res = await mmFetch(url, {
                method: 'PUT',
                body: JSON.stringify({ ids }),
            });
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadLocationLabel] error', e.toString());
        }
    },
    downloadScannableRackLabels: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `scannableRackLabels_${packGroupYear}/${packGroupWeek}${packGroupName}.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getScannableRackLabels`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadScannableRackLabels] error', e.toString());
        }
    },
    downloadScannableRackLabelsAdd: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `scannableRackLabels_${packGroupYear}/${packGroupWeek}${packGroupName}_add.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getScannableRackLabelsAdd`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadScannableRackLabelsAdd] error', e.toString());
        }
    },
    downloadScannableRackLabelsRemove: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `scannableRackLabels_${packGroupYear}/${packGroupWeek}${packGroupName}_remove.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getScannableRackLabelsRemove`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadScannableRackLabelsRemove] error', e.toString());
        }
    },
    downloadScannableRackLabelsSlotsChanging: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `scannableRackLabels_${packGroupYear}/${packGroupWeek}${packGroupName}_slots_changing.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getScannableRackLabelsSlotsChanging`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadScannableRackLabelsSlotsChanging] error', e.toString());
        }
    },
    downloadScannableRackLabelsItemsChangingSlots: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `scannableRackLabels_${packGroupYear}/${packGroupWeek}${packGroupName}_items_changing_slots.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getScannableRackLabelsItemsChangingSlots`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadScannableRackLabelsItemsChanginsSlots] error', e.toString());
        }
    },
    downloadSupermarketLabels: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `supermarketLabels_${packGroupYear}/${packGroupWeek}${packGroupName}.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getSupermarketLabels`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadSupermarketLabels] error', e.toString());
        }
    },
    downloadSupermarketLabelsAdd: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `supermarketLabels_${packGroupYear}/${packGroupWeek}${packGroupName}_add.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getSupermarketLabelsAdd`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadSupermarketLabelsAdd] error', e.toString());
        }
    },
    downloadSupermarketLabelsRemove: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `supermarketLabels_${packGroupYear}/${packGroupWeek}${packGroupName}_remove.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getSupermarketLabelsRemove`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadSupermarketLabelsRemove] error', e.toString());
        }
    },
    downloadSupermarketLabelsSlotsChanging: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `supermarketLabels_${packGroupYear}/${packGroupWeek}${packGroupName}_slots_changing.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getSupermarketLabelsSlotsChanging`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadSupermarketLabelsSlotsChanging] error', e.toString());
        }
    },
    downloadSupermarketLabelsItemsChangingSlots: async (packGroupID, packGroupYear, packGroupWeek, packGroupName) => {
        let filename = `supermarketLabels_${packGroupYear}/${packGroupWeek}${packGroupName}_items_changing_slots.pdf`;
        let url = `api/warehouse/v1/packGroups/${packGroupID}/getSupermarketLabelsItemsChangingSlots`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadSupermarketLabelsItemsChanginsSlots] error', e.toString());
        }
    },
    dumpCase: async (caseID, item) => {
        let url = `api/warehouse/v1/cases/` + caseID + `/dump`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    dumpPallet: async (palletID, item) => {
        let url = `api/warehouse/v1/pallets/` + palletID + `/dump`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    getActiveLocations: async (facilityID) => {
        let url = `api/warehouse/v1/locations?active=true&facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getAdjustReasons: async () => {
        let url = `api/warehouse/v1/cases/adjust/reasons`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getFacilities: async (active = false) => {
        let url = `api/warehouse/v1/facilities`;
        if (active) {
            url += '?active=true';
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCases: async (facilityID, palletID = null, lotID = null) => {
        let url = `api/warehouse/v1/cases?facilityID=${facilityID}`;
        if (!!palletID) {
            url += `&palletID=${palletID}`;
        }
        if (!!lotID) {
            url += `&lotID=${lotID}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCubeLevels: async () => {
        let url = `api/warehouse/v1/cubeLevels`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLocations: async (facilityID, activeOnly = false) => {
        let url = `api/warehouse/v1/locations?facilityID=` + facilityID;
        if (activeOnly == true) {
            url += '&active=true';
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLocationTempZones: async () => {
        let url = `api/warehouse/v1/locations/tempZones`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLocationTypesV2: async (facilityID) => {
        let url = `api/warehouse/v2/locations/types?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLotEvents: async (lotID) => {
        let url = `api/warehouse/v1/lots/` + lotID + '/events';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLotLocations: async (lotID) => {
        let url = `api/warehouse/v1/lots/` + lotID + '/locations';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLots: async (activeFlag, facilityID = 0) => {
        let url = `api/warehouse/v1/lots?active=` + activeFlag;
        if (facilityID > 0) {
            url += `&facilityID=` + facilityID;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLotsByIL2: async (itemLevelTwoID, facilityID = 0) => {
        let url =
            `api/warehouse/v1/lots/search?active=true&itemLevelTwoID=` + itemLevelTwoID + `&facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLotsAvailableForTransfer: async (facilityID) => {
        let url = `api/warehouse/v1/internalTransfer/lots?facility=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackaging: async () => {
        let url = `api/warehouse/v1/packaging`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackagingLiners: async () => {
        let url = `api/warehouse/v1/packaging/liners`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackLines: async (facilityID) => {
        let url = `api/warehouse/v1/packLineSlotMap?facility=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackLineSlotMapByLine: async (lineID) => {
        let url = `api/warehouse/v1/packLineSlotMap?line=${lineID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLoadsheetByWeekYear: async (week, year, facilityID) => {
        let url = `api/warehouse/v1/loadsheet?week=${week}&year=${year}&facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateLoadsheet: async (formData) => {
        let url = `api/warehouse/v1/loadsheet`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(formData),
        });
        return response;
    },
    deleteLoadSheet: async (id) => {
        let url = `api/warehouse/v1/loadsheet/${id}`;
        let response = await mmFetchWithResponse(url, { method: 'DELETE' });
        return response;
    },
    getPackLinesInFacilityWithLotLoc: async (lotLocID) => {
        let url = `api/warehouse/v1/packLines?lotLocID=` + lotLocID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackLineSlots: async (packLineID) => {
        let url = `api/warehouse/v1/packLines/` + packLineID + `/slots`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackLineZoneConfig: async (packLineID) => {
        let url = `api/warehouse/v1/packLines/` + packLineID + `/zoneConfig`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPalletsInLot: async (lotID) => {
        let url = `api/warehouse/v1/pallets?lotID=${lotID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getReasonCodes: async () => {
        let url = `api/warehouse/v1/pallets/reasonCodes`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    hydratePackLineSlotMap: async (packLineID, packGroupID) => {
        let url = `api/warehouse/v1/packLineSlotMap/hydrate`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ packLineID, packGroupID }),
        });
        return response;
    },
    moveFullCase: async (palletID, sendParams) => {
        let url = `api/warehouse/v1/cases/full/move/` + palletID;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    movePartialCase: async (caseID, sendParams) => {
        let url = `api/warehouse/v1/cases/partial/move/` + caseID;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    markPackLineSlotOOS: async (packLineID, slot, reason) => {
        let url = `api/warehouse/v1/packLineSlotMap`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                packLineID: packLineID,
                slot: slot,
                status: 'OUT_OF_STOCK',
                reason: reason,
            }),
        });
        return response;
    },
    movePallet: async (id, sendParams) => {
        let url = `api/warehouse/v1/pallets/` + id + `/move`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    returnCaseToVendor: async (caseID, item) => {
        let url = `api/warehouse/v1/cases/` + caseID + `/returnToVendor`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    returnPalletToVendor: async (palletID, item) => {
        let url = `api/warehouse/v1/pallets/` + palletID + `/returnToVendor`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    reverseConsume: async (palletID, sendParams) => {
        let url = `api/warehouse/v1/pallets/${palletID}/reverseConsume`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    reverseDumpDonate: async (palletID) => {
        let url = `api/warehouse/v1/pallets/${palletID}/reverseDumpDonate`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    searchInventory: async (searchObj) => {
        let url = `api/warehouse/v1/locations/inventory/search`;
        let str = [];
        for (let key in searchObj) {
            if (searchObj.hasOwnProperty(key)) {
                str.push(encodeURIComponent(key) + '=' + encodeURIComponent(searchObj[key]));
            }
        }
        url += `?${str.join('&')}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    splitPallet: async (id, sendParams) => {
        let url = `api/warehouse/v1/pallets/${id}/split`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    splitPalletV2: async (id) => {
        let url = `api/warehouse/v2/pallets/${id}/split`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    toggleConsumeToVoid: async (flag) => {
        let url = `api/warehouse/v1/consumeToVoid`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({
                switch: flag,
            }),
        });
        return response;
    },
    updateCubeRanges: async (sendParams) => {
        let url = `api/warehouse/v1/cubeLevels`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updateLocations: async (item) => {
        let url = `api/warehouse/v2/locations/update`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updateLot: async (lotID, item) => {
        let url = `api/warehouse/v1/lots/` + lotID + `/update`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updatePackLine: async (packLineID, sendParams) => {
        let url = `api/warehouse/v1/packLines/${packLineID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updatePackLineSlotStatus: async (packLineID, slot, status) => {
        let url = `api/warehouse/v1/packLineSlotMap`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                packLineID,
                slot,
                status,
            }),
        });
        return response;
    },
    uploadLoadsheet: async (packGroupID) => {
        let url = `api/warehouse/v1/loadsheet/${packGroupID}/upload`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    getPackSyncRuns: async (facilityID) => {
        let url = 'api/warehouse/v1/pack-sync-runs';
        url += `?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackSyncRunsData: async (packSyncRunID) => {
        let url = 'api/warehouse/v1/pack-sync-runs';
        url += `/${packSyncRunID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    initiatePackSync: async (facilityID) => {
        let url = 'api/warehouse/v1/initiate-pack-sync';
        url += `?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    confirmPackSync: async (packSyncRunID) => {
        let url = 'api/warehouse/v1/confirm-pack-sync';
        url += `/${packSyncRunID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    cancelPendingPackSync: async (packSyncRunID) => {
        let url = 'api/warehouse/v1/cancel-pending-pack-sync';
        url += `/${packSyncRunID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    createAssociatedPackLine: async (id, associatedID) => {
        let body = {};
        body['associatedID'] = associatedID;
        let url = `api/warehouse/v1/packLines/create-associated-packline/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(body),
        });
        return response;
    },
    deleteAssociatedPackLine: async (packLineID, associatedID) => {
        let url = `api/warehouse/v1/packLines/delete-associated-packline/${packLineID}/${associatedID}`;
        let response = await mmFetchWithResponse(url, { method: 'DELETE' });
        return response;
    },
};

export { warehouseApi };
