import React, { useState, useEffect, useRef, useContext } from 'react';
import { LinearProgress, Dialog, DialogContent } from '@material-ui/core';
import { Input, PartnershipsPagination, FAMAlert, FiltersWithModal } from '../../../shared/index.js';
import { partnershipsApi } from '../../../../api/partnerships.js';
import { UserContext } from '../../../../contexts/UserContext.js';
import { SubscriptionForm } from './subscriptionForm.js';
import { SubscriptionInfoCard } from './subscriptionInfoCard.js';

const ViewSubscriptions = ({ listOptions, allergenOptions, adminEmailOptions, dislikeOptions }) => {
    const { famAccount, roles } = useContext(UserContext);

    const canEditSubs = roles.map((role) => role.name).includes('EDIT_FAM_SUBSCRIPTIONS');
    const [subscriptions, setSubscriptions] = useState([]);
    const [count, setCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [newPage, setNewPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editingSub, setEditingSub] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const searchTimeoutRef = useRef(null);
    const [filters, setFilters] = useState({ status: 'ACTIVE' });
    const [filterableFields, setFilterableFields] = useState([]);

    // Cleanup the timeout on component unmount
    useEffect(() => {
        return () => clearTimeout(searchTimeoutRef.current);
    }, [searchTimeoutRef]);

    const handleListSearch = async (search) => {
        setSearchTerm(search);
        clearTimeout(searchTimeoutRef.current);

        searchTimeoutRef.current = setTimeout(() => {
            getSubscriptions({ newSearchTerm: search });
        }, 500);
    };

    const loadNewDataAndSetState = async (attribute) => {
        const res = await getSubscriptions({});
        if (!res) {
            return;
        }

        if (attribute === 'newPage') {
            setPage(newPage);
        }
    };

    const handleChangePage = (newPage) => {
        setNewPage(newPage);
    };

    useEffect(() => {
        loadNewDataAndSetState('newPage');
    }, [newPage]);

    const exportSubscriptionsToCSV = async () => {
        let name = searchTerm;
        let filterBy = filters;

        partnershipsApi.getSubscriptionsForPartner(famAccount.partnershipSource, 0, name, '', filterBy, true);
    };

    const getSubscriptions = async ({ newSearchTerm, newFilters }) => {
        setLoading(true);

        let pageNum = newPage;
        let name = searchTerm;
        let filterBy = filters;

        if (newSearchTerm !== undefined) {
            name = newSearchTerm;
        }
        if (!!newFilters) {
            filterBy = newFilters;
        }

        const res = await partnershipsApi.getSubscriptionsForPartner(
            famAccount.partnershipSource,
            pageNum,
            name,
            '',
            filterBy,
            false
        );

        if (!res.status || !res.data) {
            setLoading(false);
            return false;
        }

        setSubscriptions(res.data.subs);
        setCount(res.data.count);
        setFilterableFields(res.data.filters);

        setLoading(false);
        return true;
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        getSubscriptions({ newFilters });
    };

    const refreshSingleSubscription = async (subID) => {
        const res = await partnershipsApi.getSubscriptionsForPartner(famAccount.partnershipSource, 0, 0, subID);
        if (!res.status || !res.data?.subs?.length) {
            return;
        }

        if (res.data.subs.length > 1) {
            return;
        }
        const sub = res.data.subs[0];

        const tmp = subscriptions.map((s) => {
            if (s.id === sub.id) {
                return sub;
            }
            return s;
        });

        setSubscriptions(tmp);
    };

    return (
        <div>
            <FAMAlert severity="success" message={successMessage} alignTop={true} />
            <FAMAlert severity="error" message={errorMessage} alignTop={true} />

            <Dialog
                open={Object.keys(editingSub).length > 0}
                onClose={() => {
                    setEditingSub({});
                }}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogContent>
                    <SubscriptionForm
                        initialSub={editingSub}
                        onSuccess={(msg) => {
                            setSuccessMessage(msg);
                            setEditingSub({});
                            getSubscriptions({});
                        }}
                        listOptions={listOptions}
                        allergenOptions={allergenOptions}
                        adminEmailOptions={adminEmailOptions}
                        dislikeOptions={dislikeOptions}
                    />
                </DialogContent>
            </Dialog>

            <div>
                <div>
                    <div className="fam-create-sub-two-item fam-align-center">
                        <div>
                            <Input label="Name or Member ID" value={searchTerm} onChange={handleListSearch} />
                        </div>
                        <FiltersWithModal
                            filters={filters}
                            setFilters={handleFilterChange}
                            filterableFields={filterableFields}
                        />
                    </div>
                    &nbsp;&nbsp;
                    {<div className="fam-loading-container">{loading && <LinearProgress color="secondary" />}</div>}
                    <div className="fam-header-container">
                        <div className="fam-header-item-one">
                            <button
                                onClick={() => {
                                    exportSubscriptionsToCSV();
                                }}
                                className="fam-button fam-button-yellow grotesk-bold"
                            >
                                Export
                            </button>
                        </div>
                        <div className="fam-header-item-two">
                            <PartnershipsPagination
                                count={count}
                                rowsPerPage={20}
                                page={page}
                                onChangePage={handleChangePage}
                            />
                        </div>
                    </div>
                    {subscriptions.map((sub) => {
                        return (
                            <SubscriptionInfoCard
                                canEditSubs={canEditSubs}
                                listOptions={listOptions}
                                setErrorMessage={setErrorMessage}
                                setSuccessMessage={setSuccessMessage}
                                source={famAccount.partnershipSource}
                                getData={() => {
                                    refreshSingleSubscription(sub.id);
                                }}
                                sub={sub}
                                key={sub.id}
                                openEditSubModal={(sub) => {
                                    /**
                                     * Format sub to match the form
                                     */
                                    let tmp = JSON.parse(JSON.stringify(sub));
                                    tmp.dislikes = sub.dislikes.map((item) => item.id);
                                    [1, 2, 3, 4].forEach((num) => {
                                        const listKey = `listID${num}`;
                                        const boxesKey = `numBoxes${num}`;
                                        if (tmp[listKey] === null) {
                                            tmp[listKey] = '';
                                        }
                                        if (tmp[boxesKey] === null) {
                                            tmp[boxesKey] = '';
                                        }
                                    });
                                    setEditingSub(tmp);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export { ViewSubscriptions };
