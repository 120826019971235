import React, { useState, useEffect, useContext } from 'react';
import '../createList.css';
import { DropdownMultipleCombobox, FAMAlert } from '../../../shared';
import { catalogApi } from '../../../../api/catalog';
import { Dialog, DialogContent } from '@material-ui/core';
import { partnershipsApi } from '../../../../api/partnerships';
import { DataTable } from '../../../../shared';
import { UserContext } from '../../../../contexts/UserContext';

const ExcludedSkuList = ({ listID, excludedSkus = [], getList, listName }) => {
    const [expanded, setExpanded] = useState(false);
    if (!excludedSkus.length) {
        return null;
    }

    return (
        <div>
            <div>
                <span className="grotesk-bold">{excludedSkus.length} items are excluded from this list</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button
                    type="button"
                    onClick={() => {
                        setExpanded(true);
                    }}
                    className="fam-button fam-button-yellow grotesk-bold"
                >
                    Manage
                </button>
            </div>
            <Dialog
                open={expanded}
                onClose={() => {
                    setExpanded(false);
                }}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogContent>
                    <ExcludedSkuModalContent
                        listID={listID}
                        excludedSkus={excludedSkus}
                        getList={getList}
                        listName={listName}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

const ExcludedSkuModalContent = ({ excludedSkus, listID, getList, listName }) => {
    const [selectedSkus, setSelectedSkus] = useState('');
    const [catalog, setCatalog] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { famAccount } = useContext(UserContext);
    const [removingItem, setRemovingItem] = useState(false);

    const excludedExternalSkus = excludedSkus.map((item) => item.externalSku);

    useEffect(() => {
        const getCatalog = async () => {
            const res = await catalogApi.getRestrictedItemInformation(true, 'itemMaster');
            if (res.status) {
                setCatalog(
                    res.data.items
                        .filter((item) => !!item.name && !excludedExternalSkus.includes(item.externalSku))
                        .map((item) => {
                            return {
                                text: `${item.brand} ${item.name}`,
                                value: item.externalSku,
                            };
                        })
                );
            }
        };

        getCatalog();
    }, []);

    useEffect(() => {
        setRemovingItem(false);
    }, [excludedSkus]);

    const handleSubmitExcludedSkus = async () => {
        const promises = selectedSkus.map((extSku) =>
            partnershipsApi.addToSkuExclusionList(listID, extSku, famAccount.partnershipSource)
        );
        const res = await Promise.all(promises);
        let countRemovals = 0;
        res.forEach((item) => {
            if (item.status) {
                countRemovals += item.data.countRemovals;
            }
        });
        if (res.every((item) => item.status)) {
            getList();
            setSuccessMessage(
                `${promises.length} items have been added to exclusion list. ${countRemovals} slots removed from current lists`
            );
            setSelectedSkus([]);
        } else {
            setErrorMessage('Failed to add items to exclusion list');
        }
    };

    const handleRemoveFromExclusionList = async (externalSku) => {
        if (window.confirm('Are you sure you want to allow this item back on the list?')) {
            setRemovingItem(true);
            const res = await partnershipsApi.removeFromSkuExclusionList(
                listID,
                externalSku,
                famAccount.partnershipSource
            );
            if (res.status) {
                await getList();
                setSuccessMessage('Item will be allowed on this list in future weeks');
            }
        }
    };

    const skuCols = [
        {
            Header: 'Remove',
            Cell: ({ row }) => {
                return (
                    <button
                        style={{ margin: '10px' }}
                        className="fam-button fam-button-red grotesk-bold"
                        onClick={() => {
                            handleRemoveFromExclusionList(row.original.externalSku);
                        }}
                        disabled={removingItem}
                    >
                        Remove
                    </button>
                );
            },
        },
        {
            Header: 'Item',
            accessor: 'name',
        },
    ];

    return (
        <div>
            <FAMAlert severity="success" message={successMessage} />
            <FAMAlert severity="error" message={errorMessage} />
            <DataTable
                title={`The following items will never be added to ${listName}`}
                data={excludedSkus.map((item) => {
                    item.id = item.externalSku;
                    return item;
                })}
                columns={skuCols}
            />
            &nbsp;&nbsp;
            <div>Select additional items to never allow on this list:</div>
            <div>
                <DropdownMultipleCombobox
                    label="Item"
                    value={selectedSkus}
                    onChange={({ selectedItems }) => {
                        setSelectedSkus(selectedItems.map((item) => item.value));
                    }}
                    required={false}
                    options={catalog}
                />
            </div>
            &nbsp;&nbsp;
            <div style={{ padding: '10px 0px' }}>
                <button
                    type="button"
                    onClick={handleSubmitExcludedSkus}
                    className="fam-button fam-button-yellow grotesk-bold"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export { ExcludedSkuList };
