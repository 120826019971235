import { mmFetchWithResponse } from './mmFetchWithResponse';

let catalogApi = {
    addItemToBOM: async (bomID, sendParams) => {
        let url = `api/warehouse/v1/bom/${bomID}/ingredient`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    addItemToBundle: async (bundleID, sendParams) => {
        let url = `api/inventory/v1/bundles/${bundleID}/item`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    addPropSixtyFiveIngredient: async (extSku, ingredientID) => {
        let url = 'api/inventory/v1/propSixtyFive/' + extSku + '/ingredients';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ ingredientID: ingredientID }),
        });
        return response;
    },
    createBundle: async (sendParams) => {
        let url = `api/inventory/v1/bundles`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    createExternalSkuAisleMapping: async (sendParams) => {
        let url = `api/inventory/v1/aisles/map`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    createSponsoredBrandMapping: async (type, id, brands) => {
        let url = `api/marketing/v1/shopperMarketing/vendorSponsored`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ type, id, brands }),
        });
        return response;
    },
    removeSponsoredBrandMapping: async (id) => {
        let url = `api/marketing/v1/shopperMarketing/vendorSponsored/${id}`;
        let response = await mmFetchWithResponse(url, { method: 'DELETE' });
        return response;
    },
    confirmItemUpload: async (key) => {
        let url = 'api/inventory/v1/itemInformationBulk';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    editExternalSkuAisleMapping: async (id, sendParams) => {
        let url = `api/inventory/v1/aisles/map/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    deleteExternalSkuAisleMapping: async (id) => {
        let url = `api/inventory/v1/aisles/map/${id}`;
        let response = await mmFetchWithResponse(url, { method: 'DELETE' });
        return response;
    },
    createItemLevelTwo: async (item) => {
        let url = `api/warehouse/v1/itemLevelTwo`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createItemMaster: async (item) => {
        let url = `api/warehouse/v1/itemMaster`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createTaxonomy: async (sendParams) => {
        let formData = new FormData();
        Object.keys(sendParams).forEach((key) => {
            formData.append(key, sendParams[key]);
        });

        let url = `api/inventory/v1/taxonomy`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    editTaxonomy: async (id, field, value) => {
        let formData = new FormData();
        formData.append(field, value);

        let url = `api/inventory/v1/taxonomy/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    updateBomIngredient: async (bomID, itemID, sendParams) => {
        let url = `api/warehouse/v1/bom/${bomID}/ingredient/${itemID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    removeItemFromBOM: async (bomID, itemID) => {
        let url = `api/warehouse/v1/bom/${bomID}/ingredient/${itemID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    removeItemFromBundle: async (bundleID, externalSku) => {
        let url = `api/inventory/v1/bundles/${bundleID}/item/${externalSku}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    getBomForItemLevelTwo: async (ilt) => {
        let url = `api/warehouse/v1/itemLevelTwo/${ilt}/bom`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getBundles: async () => {
        let url = `api/inventory/v1/bundles`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemLevelTwo: async () => {
        let url = `api/warehouse/v1/itemLevelTwo`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemLevelTwoForLot: async (lotID) => {
        let url = `api/warehouse/v1/itemLevelTwo?lotID=` + lotID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemLevelTwoTypes: async () => {
        let url = `api/warehouse/v1/itemLevelTwo/types`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getBrands: async () => {
        let url = `api/warehouse/v1/itemInformation/brands`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemInformationSegments: async () => {
        let url = 'api/warehouse/v1/itemInformation/segments';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemInformationTiers: async () => {
        let url = 'api/warehouse/v1/itemInformation/tiers';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getRestrictedItemInformation: async (active = false, itemType = '') => {
        let url = 'api/warehouse/v1/itemInformation?restricted=true';
        if (active) {
            url += '&active=true';
        }
        if (itemType !== '') {
            url += `&itemType=${itemType}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemTempZones: async () => {
        let url = `api/warehouse/v1/itemInformation/tempZones`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getItemInformationTags: async () => {
        let url = 'api/warehouse/v1/itemInformationTags';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPropSixtyFiveIngredientsBySKU: async (extSku) => {
        let url = 'api/inventory/v1/propSixtyFive/' + extSku + '/ingredients';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPropSixtyFiveIngredients: async () => {
        let url = 'api/inventory/v1/propSixtyFiveIngredients';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    deleteTaxonomy: async (taxonomyID) => {
        let url = `api/inventory/v1/taxonomy/${taxonomyID}`;
        let response = await mmFetchWithResponse(url, { method: 'DELETE' });
        return response;
    },
    getTaxonomyByLevel: async (level, type) => {
        let url = `api/inventory/v1/taxonomy?level=${level}&type=${type}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTaxonomyByParentID: async (parentID, type) => {
        let url = `api/inventory/v1/taxonomy?parentID=${parentID}&type=${type}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTaxonomyDetailsByID: async (taxonomyID) => {
        let url = `api/inventory/v1/taxonomy/${taxonomyID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    createItemInformationTag: async (name) => {
        let url = 'api/warehouse/v1/itemInformationTags';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ name }),
        });
        return response;
    },
    createItemInformationTagMappings: async (tags, sku) => {
        let url = `api/warehouse/v1/itemInformationTags/map`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ tags, sku }),
        });
        return response;
    },
    removeItemInformationTagMapping: async (tagID, externalSku) => {
        let url = `api/warehouse/v1/itemInformationTag/${tagID}/remove/${externalSku}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    removePropSixtyFiveIngredient: async (extSku, ingredientID) => {
        let url = `api/inventory/v1/propSixtyFive/${extSku}/ingredients/${ingredientID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    getItemInformation: async (active = false, itemType = '') => {
        let url = 'api/warehouse/v1/itemInformation';
        if (active) {
            url += '?active=true';
        }
        if (itemType != '') {
            url += `&type=${itemType}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateBundle: async (data) => {
        let formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        let url = `api/inventory/v1/bundles/${data.id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            processData: false,
            headers: {},
            body: formData,
        });
        return response;
    },
    updateDefaultPriceByExternalSkuFacilityID: async ({ externalSku, facilityID, defaultPrice }) => {
        let url = `api/warehouse/v1/itemInformation/priceOverride`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ externalSku, facilityID, defaultPrice }),
        });
        return response;
    },
    updateItemInformation: async (formData) => {
        let url = 'api/warehouse/v1/itemInformation';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            processData: false,
            headers: {},
            body: formData,
        });
        return response;
    },
    updatePropSixtyFive: async (extSku, item) => {
        let url = 'api/inventory/v1/propSixtyFive/' + extSku;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updateItemMaster: async (id, item) => {
        let url = 'api/warehouse/v1/itemMaster/' + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updateItemLevelTwo: async (id, item) => {
        let url = 'api/warehouse/v1/itemLevelTwo/' + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    uploadAisleTaxonomy: async (data) => {
        let formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        let url = `api/warehouse/v1/itemInformation/aisles`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadItemInformation: async (data) => {
        let formData = new FormData();
        formData.append('itemFile', data.file);

        let url = 'api/inventory/v1/itemInformationBulk';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    bulkUpdateItemInfoTaxonomy: async (sendParams) => {
        let url = `api/inventory/v1/itemInformationBulk/taxonomy`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
    },
    setAisleTaxonomy: async (key) => {
        let url = `api/warehouse/v1/itemInformation/aisles`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    createItemMasterReplenishmentFacility: async (facilitiesId, itemMasterId) => {
        let url = `api/warehouse/v1/itemMasterReplenishmentFacility`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ facilitiesId, itemMasterId }),
        });
        return response;
    },
    removeItemMasterReplenishmentFacility: async (id) => {
        let url = `api/warehouse/v1/itemMasterReplenishmentFacility/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
};

export { catalogApi };
