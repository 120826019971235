import React, { useState, useEffect, useRef, useContext } from 'react';
import { Notifications, DataTable } from '../../../shared';
import {
    Button,
    TextField,
    Grid,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    FormControlLabel,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import { opsVisibilityApi as api } from '../../../api/opsVisibility';
import { UserContext } from '../../../contexts/UserContext';
import { ShipmentStatusDetailView } from './shipmentStatusDetailView';
import { logisticsApi } from '../../../api/logistics';
import Description from '@material-ui/icons/Description';
import moment from 'moment';

const startOfWeekDate = moment().startOf('week').format('YYYY-MM-DD');
const endOfWeekDate = moment().endOf('week').format('YYYY-MM-DD');

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const LinkToDetailView = ({ initialValue, row, showDetailViewModal }) => {
    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Button
                style={{ color: 'blue', textDecoration: 'underline' }}
                onClick={async () => {
                    showDetailViewModal(row);
                }}
            >
                <Description />
            </Button>
        </div>
    );
};

let FilterOptions = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    orderSource,
    setOrderSource,
    deliveryType,
    setDeliveryType,
}) => {
    const [orderSources, setOrderSources] = useState([]);
    const deliveryTypes = ['dbi', 'carrier'];

    let handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    let handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const getOrderSources = async () => {
        let res = await api.getOrderSources();
        if (!res.status) {
            return;
        }

        setOrderSources(res.data.rows);
    };

    let handleOrderSourceChange = (event) => {
        const {
            target: { value },
        } = event;
        setOrderSource(typeof value === 'string' ? value.split(',') : value);
    };

    useEffect(() => {
        getOrderSources();
    }, []);

    return (
        <div>
            <Grid item xs={12}>
                <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    value={startDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleStartDateChange}
                    style={{ marginRight: '50px' }}
                />

                <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    value={endDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleEndDateChange}
                    style={{ marginRight: '50px' }}
                />
            </Grid>
            <Grid item xs={12} style={{ padding: '20px' }}>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                    <InputLabel shrink={true}>Order Source</InputLabel>
                    <Select
                        multiple
                        onChange={handleOrderSourceChange}
                        value={orderSource}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {orderSources.map((item) => (
                            <MenuItem key={item} value={item}>
                                <Checkbox checked={orderSource.indexOf(item) > -1} />
                                <ListItemText primary={item} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} style={{ padding: '10px' }}>
                <InputLabel style={{ padding: '10px' }}>Delivery Type</InputLabel>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="delivery_type_dbi"
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setDeliveryType(event.target.value);
                                } else {
                                    setDeliveryType(null);
                                }
                            }}
                            checked={deliveryType === 'dbi'}
                            color="primary"
                            disabled={deliveryType === 'carrier'}
                            value="dbi"
                        />
                    }
                    label="DBI"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="delivery_type_carrier"
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setDeliveryType(event.target.value);
                                } else {
                                    setDeliveryType(null);
                                }
                            }}
                            checked={deliveryType === 'carrier'}
                            color="primary"
                            disabled={deliveryType === 'dbi'}
                            value="carrier"
                        />
                    }
                    label="Carrier"
                />
            </Grid>
        </div>
    );
};

let ShipmentStatus = () => {
    let [flowControlRows, setFlowControlRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [showDetailViewModalOpen, setShowDetailViewModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});
    let [orderSource, setOrderSource] = useState([]);
    let [deliveryType, setDeliveryType] = useState(null);
    let [startDate, setStartDate] = useState(startOfWeekDate);
    let [endDate, setEndDate] = useState(endOfWeekDate);
    let [loading, setLoading] = useState(false);
    let [filtersSelected, setFiltersSelected] = useState(false);

    const user = useContext(UserContext);

    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);

    useInterval(async () => {
        if (autoRefreshEnabled) getFlowControl();
    }, 15000);

    let showDetailViewModal = (row) => {
        setSelectedRow(row);
        setShowDetailViewModalOpen(true);
    };

    // Load flow control table
    let getFlowControl = async () => {
        let response = await api.getShipmentStatusFlowControl(user.getFacilityID());
        if (response.status === false) {
            setAutoRefreshEnabled(false);
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setFlowControlRows([]);
            return;
        }

        if (!response.data.rows || !Array.isArray(response.data.rows)) {
            console.error('getShipmentStatusFlowControl response rows is not an array', response.data);
            setFlowControlRows([]);
            return;
        }

        setFlowControlRows(
            response.data.rows.map((row) => {
                if (row.packDayOffsetFrom) {
                    row.lineHaul = `${row.lineHaul} - offset from ${row.packDayOffsetFrom}`;
                }
                return row;
            })
        );
    };

    // Load flow control table with filters
    let getFlowControlWithFilters = async () => {
        setAutoRefreshEnabled(false);
        setLoading(true);
        setFlowControlRows([]);

        let sendParams = {};
        sendParams.orderSource = orderSource;
        sendParams.deliveryType = deliveryType !== null ? [deliveryType] : [];

        let response = await api.getShipmentStatusFlowControlWithFilters(
            user.getFacilityID(),
            startDate,
            endDate,
            sendParams
        );
        if (response.status === false) {
            setLoading(false);
            setAutoRefreshEnabled(false);
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setFlowControlRows([]);
            return;
        }

        if (!response.data.rows || !Array.isArray(response.data.rows)) {
            setLoading(false);
            console.error('getShipmentStatusFlowControl response rows is not an array', response.data);
            setFlowControlRows([]);
            return;
        }

        setFlowControlRows(
            response.data.rows.map((row) => {
                if (row.packDayOffsetFrom) {
                    row.lineHaul = `${row.lineHaul} - offset from ${row.packDayOffsetFrom}`;
                }
                return row;
            })
        );
        setLoading(false);
    };

    let handleAutoRefresh = () => {
        setAutoRefreshEnabled(true);
        setOrderSource([]);
        setDeliveryType(null);
        setStartDate(startOfWeekDate);
        setEndDate(endOfWeekDate);
        setLoading(false);
        getFlowControl();
        setFiltersSelected(false);
    };
    useEffect(() => {
        getFlowControl();
    }, []);

    useEffect(() => {
        if (
            startDate !== startOfWeekDate ||
            endDate !== endOfWeekDate ||
            orderSource.length > 0 ||
            deliveryType !== null
        ) {
            setFiltersSelected(true);
        }

        if (
            startDate === startOfWeekDate &&
            endDate === endOfWeekDate &&
            orderSource.length === 0 &&
            deliveryType === null
        ) {
            setFiltersSelected(false);
        }
    }, [orderSource, deliveryType, startDate, endDate]);

    let flowControlColumns = [
        {
            accessor: '',
            Header: 'View Details',
            Cell: ({ cell: { value: initialValue }, row }) => {
                return (
                    <LinkToDetailView initialValue={initialValue} row={row} showDetailViewModal={showDetailViewModal} />
                );
            },
        },
        { accessor: 'lineHaul', Header: 'Line Haul' },
        { accessor: 'marketCode', Header: 'Market Code' },
        { accessor: 'expectedPackDate', Header: 'Expected Pack Date' },
        { accessor: 'expectedPullTime', Header: 'Expected Ship Time' },
        { accessor: 'containers.PRE_PURCHASE.length', Header: 'Pre Purchase' },
        { accessor: 'containers.LABEL_PURCHASED.length', Header: 'Label Purchased' },
        { accessor: 'containers.LABEL_PRINTED.length', Header: 'Label Printed' },
        { accessor: 'containers.CANCELLED.length', Header: 'Cancelled' },
        { accessor: 'containers.PACKED.length', Header: 'Packed' },
        { accessor: 'containers.ON_SHIPPING_PALLET.length', Header: 'On Shipping Pallet' },
        { accessor: 'containers.ON_TRUCK.length', Header: 'On Truck' },
        { accessor: 'containers.IN_TRANSIT.length', Header: 'In Transit' },
        { accessor: 'containers.DELIVERED.length', Header: 'Delivered' },
        { accessor: 'containers.ISSUE.length', Header: 'Issue' },
        { accessor: 'containers.total.length', Header: 'Total' },
    ];

    return (
        <div>
            <h1>Shipment Status Report</h1>

            {loading === true && <h3>⚠️ Please wait while the data is loading... ⚠️ </h3>}

            {autoRefreshEnabled == true && <p>Note: This page refreshes automatically every 15 seconds.</p>}

            {autoRefreshEnabled != true && (
                <h4>
                    ⚠️ Auto refresh as been disabled, please refresh your page manually. ⚠️
                    <br />
                    ⚠️ If you have applied filters, please clear filters to refresh the page. ⚠️
                </h4>
            )}

            <Notifications options={notification} />
            {}
            <div>
                <h3>Flow Control</h3>
                <FilterOptions
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    orderSource={orderSource}
                    setOrderSource={setOrderSource}
                    deliveryType={deliveryType}
                    setDeliveryType={setDeliveryType}
                />
                <Button onClick={getFlowControlWithFilters} color="secondary" disabled={!filtersSelected}>
                    Apply Filters
                </Button>
                <Button onClick={handleAutoRefresh} color="secondary">
                    Clear Filters
                </Button>
                {showDetailViewModalOpen && (
                    <ShipmentStatusDetailView
                        rowData={selectedRow}
                        closeModal={() => {
                            setShowDetailViewModalOpen(false);
                        }}
                    />
                )}
                <DataTable columns={flowControlColumns} data={flowControlRows} />
            </div>
        </div>
    );
};

export { ShipmentStatus };
