import React from 'react';

const Prop65Warnings = ({ item }) => {
    return (
        <div>
            {item.prop65WarningCategoryFood && !item.prop65Ingredients.length && (
                <div>
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> California Residents, click{' '}
                        <a className="gray-text" href="/california-proposition-65">
                            here
                        </a>{' '}
                        to learn more about Proposition 65.
                    </p>
                </div>
            )}

            {item.prop65WarningCategoryNonFood && !item.prop65Ingredients && (
                <div>
                    <img
                        className="warning"
                        src="https://static.misfitsmarket.com/images/MM_icon-warning-sm.png"
                        alt="Warning"
                    />
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> This product contains one or more chemicals known to the
                        State of California to cause cancer and birth defects or other reproductive harm. For more
                        information go to{' '}
                        <a
                            className="gray-text"
                            href="https://www.p65warnings.ca.gov/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.P65warnings.ca.gov
                        </a>
                    </p>
                </div>
            )}

            {item.prop65WarningCategoryFood && item.prop65Ingredients.length > 0 && (
                <div>
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> This product contains chemicals including{' '}
                        {item.prop65Ingredients.join(', ')}, which {item.prop65Ingredients.length > 1 ? 'are' : 'is'}{' '}
                        known to the State of California to cause cancer and birth defects or other reproductive harm.
                        For more information go to{' '}
                        <a
                            className="gray-text"
                            href="https://www.p65warnings.ca.gov/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.P65warnings.ca.gov
                        </a>
                    </p>
                </div>
            )}

            {item.prop65WarningCategoryCanned && (
                <div>
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> Many food and beverage cans have linings containing
                        bisphenol A (BPA), a chemical known to the State of California to cause cancer and birth defects
                        or other reproductive harm. Jar lids and bottle caps may also contain BPA. You can be exposed to
                        BPA when you consume foods or beverages packaged in these containers. For more information go to{' '}
                        <a
                            className="gray-text"
                            href="https://www.p65warnings.ca.gov/BPA"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.p65warnings.ca.gov/BPA
                        </a>
                    </p>
                </div>
            )}

            {item.prop65WarningCategoryAlcohol && (
                <div>
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> Drinking distilled spirits, beer, coolers, wine, and
                        other alcoholic beverages may increase cancer risk and, if consumed during pregnancy, can cause
                        birth defects. For more information go to{' '}
                        <a
                            className="gray-text"
                            href="https://www.P65warnings.ca.gov/alcohol"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.P65warnings.ca.gov/alcohol
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
};

const ItemDetails = ({ item }) => {
    return (
        <div className="partnerships-add-item-container">
            <div className="add-item-item-one">
                {!!item.photoUrlSmall && (
                    <img
                        alt={item.name}
                        style={{ width: '50px', height: '50px' }}
                        src={`https://static.misfitsmarket.com/${item.photoUrlSmall}`}
                    />
                )}
            </div>
            <div className="add-item-item-two">
                <div className="grotesk-bold">{item.name}</div>
                {item.brand && (
                    <div>
                        <span className="grotesk-bold">Brand:</span> {item.brand}
                    </div>
                )}
                {item.ingredients && (
                    <div>
                        <span className="grotesk-bold">Ingredients:</span> {item.ingredients}
                    </div>
                )}
                {item.description && (
                    <div>
                        <span className="grotesk-bold">Description:</span> {item.description}
                    </div>
                )}
                {item.allergens && (
                    <div>
                        <span className="grotesk-bold">Allergens:</span> {item.allergens}
                    </div>
                )}
                {item.crossAllergens && (
                    <div>
                        <span className="grotesk-bold">Cross Allergens:</span> {item.crossAllergens}
                    </div>
                )}
                {(item.photoUrlSmall || item.photoUrlNutritionalInfo) && (
                    <div>
                        <span className="grotesk-bold">Photos: </span>
                        {item.photoUrlSmall && (
                            <a target="_blank" href={`https://static.misfitsmarket.com/${item.photoUrlSmall}`}>
                                Product Photo
                            </a>
                        )}
                        &nbsp;&nbsp;
                        {item.photoUrlNutritionalInfo && (
                            <a
                                target="_blank"
                                href={`https://static.misfitsmarket.com/${item.photoUrlNutritionalInfo}`}
                            >
                                Nutritional Info
                            </a>
                        )}
                    </div>
                )}
                <Prop65Warnings item={item} />
            </div>
        </div>
    );
};

export { ItemDetails };
